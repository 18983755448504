import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractPhotoPageModel from 'garaje/models/abstract/abstract-photo-page';
import adapter from 'garaje/utils/decorators/adapter';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';
import PropagableChangesTracker from 'garaje/utils/decorators/propagable-changes-tracker';

import type FlowModel from './flow';

// This is the list of attributes with its humanize version that, when changed,
// can be propagable to other locations
const OVERWRITABLE_SETTINGS = {
  enabled: 'visitor photos',
  useExistingPhoto: 'use existing photos',
};

@PropagableChangesTracker
@OnboardingChangesTracker({ taskName: 'loadFlowsTask', attributes: ['enabled'] })
@adapter('photo-page')
class PhotoPageModel extends AbstractPhotoPageModel {
  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;

  static OVERWRITABLE_PARENT = 'flow';
  static OVERWRITABLE_SETTINGS = OVERWRITABLE_SETTINGS;
}

export default PhotoPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'photo-page': PhotoPageModel;
  }
}
