/* eslint-disable ember/use-ember-data-rfc-395-imports */
import type DS from 'ember-data';
import { type ModelRegistry } from 'ember-data/model';
import ApplicationAdapter from 'garaje/adapters/application';

export default class ScimPrimaryLocationMappingAdapter extends ApplicationAdapter {
  urlForFindAll<K extends keyof ModelRegistry>(modelName: K, snapshot: DS.SnapshotRecordArray<K>): string {
    const url = super.urlForFindAll(modelName, snapshot);
    return `${url}?include=location&page[limit]=200`;
  }
}
