import Component from '@glimmer/component';
import { alias, bool } from 'macro-decorators';
import { action, get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { all, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { GROUP_OPERATION_OPTIONS, APP, VisitorsEventNames } from 'garaje/utils/enums';

/**
 * @param {Boolean}               isLocationAdmin
 * @param {Model<Flow>}           flow Required. Flow to attach rules to
 * @param {Array}                 signInFieldActionRuleGroups
 * @param {Array}                 signInFields
 * @param {Boolean}               isProtect
 * @param {Boolean}               isGlobalChild
 * @param {Function}              onClose Required. Pass through of onClose function
 */

export default class SignInFieldsRulesModal extends Component {
  @service store;
  @service flashMessages;
  @service featureFlags;
  @service coho;
  /**
   * @type {object.Rule} the in-flight rule (creating/editing)
   */
  @tracked currentRule;

  /**
   * @type {boolean} isEditing flag is a template helper to prevent flickering
   */
  @tracked isEditing = false;

  /**
   * @type {object.Rule} the selected rule to confirm deletion
   */
  @tracked ruleToDelete;

  @alias('args.flow.signInFieldActionRuleGroups') rules;
  @bool('ruleToDelete') isDeleting;

  @dropTask
  *saveRuleTask(e) {
    e?.preventDefault();

    try {
      if (this.featureFlags.isEnabled('ignoreRules')) {
        // whenever saving rules, they always start as enabled. aka not ignored
        this.currentRule.ignore = false;
      }

      const signInFieldActionsContacts = yield this.currentRule.signInFieldActionsContacts;
      const signInFieldActions = yield this.currentRule.signInFieldActions;

      if (this.args.isLocationAdmin && this.args.isProtect) {
        return signInFieldActionsContacts
          .filter((contact) => get(contact, 'hasDirtyAttributes') || get(contact, 'isDeleted'))
          .map((contact) => {
            return contact.save();
          });
      }

      yield this.currentRule.save();

      try {
        yield all([
          ...signInFieldActions
            .filter((action) => get(action, 'hasDirtyAttributes') || get(action, 'isDeleted'))
            .map((action) => {
              return action.save();
            }),
          ...(this.currentRule.hasAlertAction
            ? signInFieldActionsContacts
                .filter((contact) => get(contact, 'hasDirtyAttributes') || get(contact, 'isDeleted'))
                .map((contact) => {
                  return contact.save();
                })
            : []),
        ]);
      } catch (e) {
        if (
          (e.message?.indexOf('Attempted to handle event') > 0 &&
            e.message?.indexOf('while in state root.empty') > 0) ||
          e.message?.indexOf('as there is no such record in the cache') > 0
        ) {
          // eslint-disable-next-line no-console
          console.debug('Error saving signInFieldActions for rules: ', e);
        } else {
          throw e;
        }
      }

      if (!this.currentRule.hasAlertAction) {
        // `envoy-web` unwinds and deletes the relationship but we need to update the local store to match
        signInFieldActionsContacts.forEach((contact) => {
          signInFieldActionsContacts.removeObject(contact);
          contact.unloadRecord();
        });
      }

      this.flashMessages.showAndHideFlash('success', 'Rule saved', undefined, undefined, true);
      this.resetRule();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e), undefined, undefined, true);
    }
  }

  @dropTask
  *deleteRuleTask() {
    try {
      yield this.ruleToDelete.destroyRecord();

      this.flashMessages.showAndHideFlash('success', 'Rule deleted', undefined, undefined, true);

      this.resetRule();
    } catch (error) {
      this.flashMessages.showAndHideFlash(
        'error',
        'Rule could not be deleted. Please try again.',
        undefined,
        undefined,
        true,
      );
    }
  }

  @action
  handleAddRule() {
    this.currentRule = this.rules.createRecord({
      groupOperator: GROUP_OPERATION_OPTIONS.AND,
      flow: this.args.flow,
    });

    this.coho.sendEvent(VisitorsEventNames.SignInFieldRuleAdded, { product: APP.VISITORS });
  }

  @action
  handleDeleteRule(rule) {
    this.ruleToDelete = rule;
  }

  @action
  handleEditRule(rule) {
    this.isEditing = true;
    this.currentRule = rule;
  }

  @action
  resetRule(fromCancel) {
    if (fromCancel) {
      const { signInFieldActions, signInFieldActionsContacts, hasDirtyAttributes } = this.currentRule;
      signInFieldActionsContacts.forEach((contact) => contact.hasDirtyAttributes && contact.rollbackAttributes());
      signInFieldActions.forEach((action) => action.hasDirtyAttributes && action.rollbackAttributes());
      hasDirtyAttributes && this.currentRule.rollbackAttributes();
    }

    this.ruleToDelete = null;
    this.currentRule = null;
    this.isEditing = false;
  }
}
