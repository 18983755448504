import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty, isBlank, isPresent, isNone } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { endOfDay, formatISO, isFuture, startOfDay, startOfMonth, startOfYear, subDays, subYears } from 'date-fns';
import { format, formatInTimeZone } from 'date-fns-tz';
import type AbilitiesService from 'ember-can/services/abilities';
import type { TaskInstance } from 'ember-concurrency';
import { timeout, task, dropTask, restartableTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import config from 'garaje/config/environment';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type EmployeeModel from 'garaje/models/employee';
import type EntryModel from 'garaje/models/entry';
import type EventReportModel from 'garaje/models/event-report';
import type FlowModel from 'garaje/models/flow';
import type { Field } from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type SubscriptionModel from 'garaje/models/subscription';
import type TenantModel from 'garaje/models/tenant';
import type AjaxService from 'garaje/services/ajax';
import type AsyncExportManagerService from 'garaje/services/async-export-manager';
import type { EntryExportParams } from 'garaje/services/async-export-manager';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LoggerService from 'garaje/services/logger';
import type MessageBusService from 'garaje/services/message-bus';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { modifyDateInTimeZone, parseYyyyMmDdInTimeZone, DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import { DEFAULT_FLOW_NAME, FlowType, NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { RecordArray } from 'garaje/utils/type-utils';
import { Permission } from 'garaje/utils/ui-permissions';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import type { CollectionResponse } from 'jsonapi/response';
import { alias, and, equal, notEmpty, or, reads } from 'macro-decorators';
import moment from 'moment-timezone';
import { resolve, all, defer } from 'rsvp';
import { TrackedObject } from 'tracked-built-ins';

import type { VisitorsEntriesRouteModel } from './route';

const MIN_SEARCH_LENGTH = 3;
const EVENT_REPORT_POLLING_TIMEOUT = zft(10000);
const NUMBER_OF_POLLING_TRIES = zft(3);
const PRIMARY_HEADER = [
  { name: 'Name', componentName: 'custom-column', sort: 'full_name' },
  { name: 'Entry status', componentName: 'custom-column' },
  { name: 'Purpose of visit', componentName: 'custom-column', sort: 'flow_name' },
  { name: 'Employee Registration', componentName: 'employee-registration-column' },
  { name: 'Group Name', componentName: 'custom-column', sort: 'bulk_invites.group_name' },
  { name: 'ID scan', componentName: 'id-scanning-column' },
  { name: 'Notification', componentName: 'host-notification-column' },
  { name: 'Connect Notification', componentName: 'connect-notification-column' },
  { name: 'Shared Notes', componentName: 'shared-notes-column' },
  { name: 'Arrived At', componentName: 'arrived-at-column' },
  { name: 'NDA', componentName: 'plugin-activity-column' },
  { name: 'CRM', componentName: 'plugin-activity-column' },
  { name: 'Wi-Fi', componentName: 'plugin-activity-column' },
  { name: 'Security', componentName: 'plugin-activity-column' },
  { name: 'ID Checked', componentName: 'id-checked-column', sort: 'id_check_status' },
  { name: 'Blacklist', componentName: 'blacklist-column', sort: 'approval_checks.status' },
  { name: 'Pre-registration', componentName: 'pre-registration-column' },
  { name: 'Private Notes', componentName: 'custom-column' },
  { name: 'Desk', componentName: 'custom-column' },
];

const SIGN_IN_AND_SIGN_OUT_HEADER = [
  { name: 'Signed In', componentName: 'signed-in-column', sort: 'signed-in-at' },
  { name: 'Signed Out', componentName: 'signed-out-column', sort: 'sign_out_time' },
];

// Use this value in place of the `name` attribute for employee registration flowsl.
// This is needed because the real API returns the GLOBAL employee registration flow in the
// list of flows for a LOCATION, and the GLOBAL flow can be renamed (but the location flow can't),
// but the LOCATION flow name is used when performing the filtering.
const EMPLOYEE_SCREENING_FLOW_NAME_OVERRIDE = 'Employee registration';

interface FilterOption {
  name: string;
  filter: string;
  employee?: EmployeeModel;
}

type EntryPage = PaginatedRecordArray<EntryModel> & { meta: { 'has-entries': boolean } };
type CalenderEntriesCollection = CollectionResponse<{ entries: number; date: string }>;

interface EntryQueryParams {
  location: string;
  visitor_type: string | string[];
  'start-date'?: string;
  'end-date'?: string;
  query?: string;
  status?: string;
  employee?: string;
}

interface SortableField extends Field {
  sort?: string;
}

export default class VisitorsEntriesController extends Controller {
  declare model: VisitorsEntriesRouteModel;
  // this gets set by a nested route.
  declare limit: number;

  @service declare abilities: AbilitiesService;
  @service declare ajax: AjaxService;
  @service declare asyncExportManager: AsyncExportManagerService;
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentLocation: CurrentLocationService; // Needed for `printer` CP
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare logger: LoggerService;
  @service declare messageBus: MessageBusService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare store: StoreService;

  minSearchLength = MIN_SEARCH_LENGTH;

  queryParams = [
    'startDate',
    'endDate',
    'date',
    'query',
    'filter',
    'refresh',
    'sort',
    { selectedFlows: { as: 'visitor_types' } },
    { selectedFlow: { as: 'visitor_type' } }, // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  ];

  @tracked refresh = ''; // Flag to force a model refresh
  @tracked date: string | null = '';
  @tracked query = '';
  @tracked inputQuery: string | null = null;
  @tracked selectedFlow = ''; // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  @tracked selectedFlows = '';
  @tracked entriesCount = 0;
  @tracked sort = '-signed-in-at';
  @tracked filter = 'all';
  @tracked showExportModal = false;
  @tracked startDate: Date | null = null;
  @tracked endDate: Date | null = null;
  @tracked mobileCalendarVisible = false;
  @tracked page = 1;
  @tracked totalLoadedEntries!: number;
  @tracked hasEntries?: boolean;
  @tracked selectedEntries: EntryModel[] = [];
  @tracked eventReports: EventReportModel[] = [];
  @tracked datesWithEntries = [];
  @tracked bosses: EmployeeModel[] = [];
  @tracked connectedTenants?: RecordArray<TenantModel> | null = null;

  @tracked showCalendar = false;
  @tracked selectedDateRange = '';
  @tracked calendarEntries: string[] | null = null;
  @tracked dashboardFieldsByLocationId: Record<string, Field[]> = new TrackedObject();

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
    this.calendarEntries = [];
  }

  @alias('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @alias('currentLocation.preRegistrationEnabled') preRegistrationEnabled!: boolean;
  @alias('currentLocation.printer.enabled') canPrintBadges!: PrinterModel['enabled'];
  @alias('state.vrSubscription.canAccessBlocklist') canAccessBlocklist!: SubscriptionModel['canAccessBlocklist'];
  @equal('hasEntries', false) noHistoricVisitors!: boolean;
  @notEmpty('query') showEntryFullDate!: boolean;
  @notEmpty('query') showClearButton!: boolean;
  @and('page', 'loadEntries.isIdle', 'mutateQuery.isIdle') showCount!: boolean;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;
  @reads('currentLocation.timezone', 'America/Los_Angeles') timezone!: string;

  get canViewBlocklist(): boolean {
    return (
      this.currentLocation.location?.blocklistEnabled &&
      this.canAccessBlocklist &&
      this.abilities.can('review blocklist')
    );
  }

  get locationIsConnectedToProperty(): boolean {
    return <number>this.connectedTenants?.length > 0;
  }

  get currentQuery(): string {
    // If a search query typed in, use it.
    // Fallback to query param.
    const { inputQuery, query } = this;

    return isNone(inputQuery) ? query : inputQuery;
  }

  get isSearching(): boolean {
    return this.filter !== 'all' || isPresent(this.query);
  }

  get exportButtonText(): string {
    return this.isSearching ? 'Export results' : 'Export';
  }

  get dateRangeFilterOptions(): string[] {
    const { selectedDateRangeWithDefault } = this;
    const options = [
      'Today',
      'Yesterday',
      'Past 7 days',
      'Past 30 days',
      'Past 60 days',
      'Past 90 days',
      'Month-to-date',
      'Year-to-date',
      'Past year',
      'All time',
    ];

    if (!options.includes(selectedDateRangeWithDefault)) {
      options.push(selectedDateRangeWithDefault);
    }

    return options;
  }

  get exportIframeUrl(): string {
    const { currentCompany } = this.state;
    const locationId = this.currentLocation.location.id;
    let modalLabel;
    let selectedFlowId: string | undefined;
    const defaultEndDate = moment().format('YYYY-MM-DD');
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const flows = this.model.flows;
    if (this.selectedFlow === '') {
      const flowIds = this.model.locationFlowIds;

      if (this.locationIsConnectedToProperty && this.walkinFlow) flowIds.push(this.walkinFlow.id);
      selectedFlowId = flowIds.join(',');

      modalLabel = 'All visitor types';
    } else {
      if (this.selectedFlow === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING) {
        selectedFlowId = flows.find((flow) => flow.type === FlowType.EMPLOYEE_SCREENING)?.id;
      } else {
        selectedFlowId = flows.find((flow) => flow.name === this.selectedFlow)?.id;
      }
      modalLabel = this.selectedFlow;
    }
    return urlBuilder.embeddedEntryExportModalUrl(
      currentCompany.id,
      locationId,
      selectedFlowId!,
      defaultStartDate,
      defaultEndDate,
      modalLabel,
    );
  }

  get todayAsString(): string {
    return formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get todayAsDate(): Date {
    return parseYyyyMmDdInTimeZone(this.todayAsString, new Date(), this.timezone);
  }

  get isToday(): boolean {
    const { dateWithDefault, startDateWithDefault, endDateWithDefault, todayAsString } = this;

    if (dateWithDefault !== todayAsString) return false;
    if (formatInTimeZone(startDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;
    if (formatInTimeZone(endDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;

    return true;
  }

  get isAfterToday(): boolean {
    const { startDateWithDefault, endDateWithDefault } = this;

    return isFuture(startDateWithDefault) && isFuture(endDateWithDefault);
  }

  get isSingleDay(): boolean {
    return this.startDateFormat === this.endDateFormat;
  }

  get newEntryRecordDate(): string {
    const { isSingleDay, startDateWithDefault, todayAsDate, timezone } = this;
    const date = isSingleDay ? startDateWithDefault : todayAsDate;

    return formatInTimeZone(date, timezone, DATE_FNS_YYYY_MM_DD);
  }

  /*
  A computed property that provides a fallback to today's date if the date QP is empty.

  This allows users to bookmark a live "today" view that is just `/entries`, since the empty QP
  will force the app to show the current day's view rather than forcing the URL to always contain
  a static date.
   */
  get dateWithDefault(): string {
    return this.date || formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get startDateWithDefault(): Date {
    if (this.startDate) return new Date(this.startDate);

    const { dateWithDefault } = this;

    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const start = modifyDateInTimeZone(date, this.timezone, startOfDay);

    return start;
  }

  get endDateWithDefault(): Date {
    if (this.endDate) return new Date(this.endDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const end = modifyDateInTimeZone(date, this.timezone, endOfDay);

    return end;
  }

  get selectedDateRangeWithDefault(): string {
    const { selectedDateRange, isToday } = this;

    if (isToday) return 'Today';
    if (selectedDateRange) return selectedDateRange;

    return 'Custom';
  }

  /*
   Instance of "moment" representing the string in the property date.
   This value is used to render the envoy-calendar, and do operations
   like next and previous day.
   */
  get selectedDate(): moment.Moment {
    return moment(this.dateWithDefault, 'YYYY-MM-DD');
  }

  set selectedDate(value: moment.Moment) {
    this.date = value.format('YYYY-MM-DD');
    void this.loadEntries.cancelAll();
    // @ts-ignore
    return value;
  }

  get showSignedOut(): boolean {
    return this.filter !== 'signed-in';
  }

  get filterOptions(): FilterOption[] {
    const options = [];

    let myVisitors;

    const employee = this.currentAdmin.employee;

    if (employee) {
      myVisitors = {
        name: 'My Visitors',
        filter: employee.id,
        employee,
      };
      options.push(myVisitors);
      this.bosses.forEach(function (boss) {
        options.push({
          name: `${boss.name} Visitors`,
          filter: boss.id,
          employee: boss,
        });
      });
    }

    const hasBosses = this.bosses.length > 0;

    const canViewAll = this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_ENTRY_READ);

    // Only include all visitors filter if user can view all entries or if is an employee with bosses
    if (canViewAll || hasBosses) {
      options.unshift({ name: 'All Visitors', filter: 'all' });
    }

    options.push({ name: 'Currently Signed In', filter: 'signed-in' });

    return options;
  }

  get selected(): FilterOption {
    const filter = this.filter;
    const filterOptions = this.filterOptions;
    const selectedOption = filterOptions.find((option) => option.filter === filter);

    return selectedOption || filterOptions[0]!;
  }

  get signedInEntries(): EntryModel[] {
    // eslint-disable-next-line ember/no-get
    return get(this.model, 'entries').filter((entry) => !entry.signOutTime);
  }

  get relevantEntries(): EntryModel[] {
    // eslint-disable-next-line ember/no-get
    return this.showSignedOut ? get(this.model, 'entries') : this.signedInEntries;
  }

  get relevantReviewableEntries(): EntryModel[] {
    return this.relevantEntries.filter((entry) => {
      return entry.needsApprovalReview;
    });
  }

  get entriesCountReload(): void {
    return this.calculateEntriesCount();
  }

  get hasMorePages(): boolean {
    const currentTotal = this.page * this.limit;

    return currentTotal <= this.entriesCount;
  }

  get signInFieldPages(): SignInFieldPageModel[] {
    return this.model.signInFieldPages;
  }

  get customFieldHeaders(): Field[] {
    return A(this.model.customFields)
      .compact()
      .reduce<NativeArray<SignInFieldModel>>((acc, val) => A(acc.concat(val)), A())
      .uniqBy('name')
      .map((customField) => {
        return {
          name: customField.label,
          componentName: 'custom-column',
        };
      });
  }

  get primaryHeaders(): SortableField[] {
    const headers = PRIMARY_HEADER;
    const removeHeaders: Record<string, boolean> = {
      Notification: !this.currentLocation.location.hostNotificationsEnabled,
      'Connect Notification': !this.locationIsConnectedToProperty,
      'Shared Notes': !this.locationIsConnectedToProperty,
      'Arrived At': !this.locationIsConnectedToProperty,
      NDA: Boolean(!this.currentLocation.location.hasAgreementPage),
      CRM: !this.hasCRMColumn,
      'Wi-Fi': !this.hasWifiColumn,
      Security: !this.hasSecurityColumn,
      Blacklist: !this.canViewBlocklist,
      'Pre-registration': !this.currentLocation.location.preRegistrationEnabled,
      // eslint-disable-next-line ember/no-get
      'ID scan': !get(this.currentLocation, 'config.idScanningEnabled'),
      'ID Checked': this.abilities.cannot('review id-check'),
      Desks: this.abilities.can('see entry log for desk'),
    };

    return headers.filter(({ name }) => !removeHeaders[name]);
  }

  get hasCRMColumn(): boolean {
    return this._hasActivePluginForCategory('crm');
  }

  get hasWifiColumn(): boolean {
    return this._hasActivePluginForCategory('wi-fi');
  }

  get hasSecurityColumn(): boolean {
    return this._hasActivePluginForCategory('security');
  }

  get entryDashboardFields(): Field[] {
    const fields = this.dashboardFieldsByLocationId[this.currentLocation.location.id] ?? [];
    if (this.selectedFlow.toLowerCase() != 'employee registration') return fields;
    return [...fields, { name: 'Employee Registration', componentName: 'employee-registration-column' }];
  }

  get fieldOptions(): Field[] {
    // this CP transform raw dashboardFields into list of headers and their property
    // entry headers always displayed in the same order (which defined here)
    // Note: field has to be populated when sortable is true
    // In addition, column-name HAS TO be specify because backend is checking it too
    const primaryHeaders = this.primaryHeaders;
    const customFieldHeaders = this.customFieldHeaders;

    // order has to be primary > custom > sign in and sign out
    let headers: SortableField[] = [...primaryHeaders, ...customFieldHeaders, ...SIGN_IN_AND_SIGN_OUT_HEADER];

    headers = headers.map(({ name, componentName, sort }) => {
      return {
        name,
        componentName,
        // always show name
        show: name === 'Name' ? true : isPresent(this.entryDashboardFields.find((field) => field.name === name)),
        // name should not be adding / removing from dashboard fields
        disabled:
          name === 'Name'
            ? true
            : (() =>
                this.selectedFlow.toLowerCase() === 'employee registration' &&
                name.toLowerCase() === 'employee registration')(),
        sort,
      };
    });

    return headers;
  }

  get walkinFlow(): FlowModel | undefined {
    return this.currentLocation.location.flows.findBy('type', FlowType.PROPERTY_WALKUP);
  }

  get flowOptions(): Array<FlowModel | { name: string }> {
    const { walkinFlow } = this;
    const options = [{ name: 'All visitor types' }];

    if (this.currentLocation.location.employeeScreeningEnabled) {
      options.push({ name: DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING });
    }

    if (this.locationIsConnectedToProperty && walkinFlow) options.push(walkinFlow);

    const filteredFlows = this.currentLocation.location.flows.filter(
      ({ employeeCentric, type, name }) => !(<string[]>NON_ASSIGNABLE_FLOWS).includes(type) && !employeeCentric && name,
    );
    A(filteredFlows)
      .sortBy('name')
      .forEach((flow) => options.push(flow));

    return A(options.filter(Boolean)).uniqBy('name');
  }

  get selectedFlowModels(): FlowModel[] {
    const ids = this.selectedFlows.split(',').filter((maybeId) => maybeId !== '');
    return ids.map((id) => this.store.peekRecord('flow', id)).filter((maybeFlow) => !!maybeFlow);
  }

  get modalElement(): HTMLElement | null {
    return document.getElementById('modal');
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get startDateFormat(): string {
    const { timezone, startDateWithDefault } = this;

    return formatInTimeZone(startDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get endDateFormat(): string {
    const { timezone, endDateWithDefault } = this;

    return formatInTimeZone(endDateWithDefault, timezone, 'MM/dd/yyyy');
  }

  get calendarEntryDates(): moment.Moment[] {
    return this.calendarEntries!.map((d) => moment(d, 'YYYY-MM-DD'));
  }

  selectedDidChange(selected: EntryModel[]): void {
    this.selectedEntries = selected;
  }

  selectAllEntries(): void {
    this.selectedEntries = [...this.relevantEntries];
  }

  @action
  toggleModal(): void {
    this.showExportModal = !this.showExportModal;
  }

  @action
  setStartAndEndTime(): void {
    this.startDate = this.startDateWithDefault;
    this.endDate = this.endDateWithDefault;
  }

  @action
  onDateRangeSelect(option: string): void {
    const { todayAsDate, timezone } = this;

    let start = modifyDateInTimeZone(todayAsDate, timezone, startOfDay);
    let end = modifyDateInTimeZone(todayAsDate, timezone, endOfDay);

    switch (option) {
      case 'Yesterday':
        start = subDays(start, 1);
        end = subDays(end, 1);
        break;
      case 'Past 7 days':
        start = subDays(start, 7);
        break;
      case 'Past 30 days':
        start = subDays(start, 30);
        break;
      case 'Past 60 days':
        start = subDays(start, 60);
        break;
      case 'Past 90 days':
        start = subDays(start, 90);
        break;
      case 'Month-to-date':
        start = modifyDateInTimeZone(start, timezone, startOfMonth);
        break;
      case 'Year-to-date':
        start = modifyDateInTimeZone(start, timezone, startOfYear);
        break;
      case 'Past year':
        start = subYears(start, 1);
        break;
      case 'All time':
        start = modifyDateInTimeZone(this.state.currentLocation.createdAt, timezone, startOfDay);
        break;
      case 'Custom':
        return;
    }

    this.date = '';
    this.selectedDateRange = option;
    this.startDate = start;
    this.endDate = end;

    this.metrics.trackEvent('Entries Date Range Changed', {
      date_nav_method: 'range_picker',
      date_option: option,
    });
  }

  @action
  didSelectDateRange(startDate: Date, endDate: Date): void {
    this.startDate = startDate;
    this.endDate = endDate;
    this.selectedDateRange = 'Custom';
    this.date = '';

    this.metrics.trackEvent('Entries Date Range Changed', {
      date_nav_method: 'range_calendar',
      date_start: this.startDate.toISOString(),
      date_end: this.endDate.toISOString(),
    });
  }

  @action
  setStartDate(date: Date): void {
    this.startDate = date;
  }

  @action
  setEndDate(date: Date): void {
    this.endDate = date;
  }

  sortEntries(field: string, direction: string): void {
    // set sort
    const dir = direction === 'asc' ? '' : '-';

    this.sort = `${dir}${field}`;
    this.metrics.trackEvent('Entries Sorted', { sort_field_name: field });
  }

  signOutEntries = dropTask(async (entries: EntryModel[]) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await this.showSignOutConfirmationTask.perform(entries);
    this.clearAll();
  });

  signOutEntry = dropTask(async (entry: EntryModel) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await this.showSignOutConfirmationTask.perform([entry]);
  });

  @dropTask
  showSignOutConfirmationTask: {
    perform(entries: EntryModel[]): Generator<Promise<unknown>, unknown, unknown>;
    entries: EntryModel[];
    abort?: () => void;
    continue?: (args: { deleteGroup: boolean }) => void;
  } = {
    entries: [],

    *perform(entries: EntryModel[]): Generator<Promise<unknown>, unknown, unknown> {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  reprintBadge(entry: EntryModel, printer: PrinterModel | null = null): Promise<void> {
    const entries = (<EntryModel[]>[]).concat(entry); // take a single entry or an array of entries
    let promise: ReturnType<PrinterModel['reprintBadge']>;

    // FF. multiplePrinters
    if (printer) {
      const data = entries.map((entry) => ({ type: 'entries', id: entry.id }));
      promise = printer.reprintBadge({ data });
    } else {
      // Backward compatibility with the old end-point.
      // We're only selecting a printer on the entries list page
      // but this method is also used (without passing a printer) on:
      // * Single entry page
      // * Bulk actions
      // TODO is this dead code? console.log('WAT no printer');
      // @ts-ignore this breaks type checking if this is not dead code, ignore for now
      promise = all(entries.map((entry) => entry.reprintBadge()));
    }

    return promise
      .then((response) => {
        const succeeded = response.data.filter((r) => r.attributes.result === 'success');
        // We support a success message and error message, so we display each one based on API response.
        if (succeeded.length > 0) {
          const count = succeeded.length;
          let msg = 'Printing badge!';
          if (count > 1) {
            msg = `Printing ${pluralize(count, 'badge')}!`;
          }
          this.flashMessages.showAndHideFlash('success', msg, '', true);
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'success',
            message_title: msg,
            message_codes: [],
          });
        }
        const errored = response.data.filter((r) => r.attributes.result === 'error');
        if (errored.length > 0) {
          const msg = `Cannot print ${pluralize(errored.length, 'badge', { withoutCount: true })}`;
          const componentName = 'flash-message/printer-error';
          this.flashMessages.showFlashComponent('error', msg, componentName, response);
          const statuses = A(errored.reduce<string[]>((acc, e) => acc.concat(e.attributes.statuses), [])).uniq();
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'error',
            message_title: msg,
            message_codes: statuses,
          });
        }
      })
      .catch((error) => {
        this.flashMessages.showFlash('error', <string>error);
      });
  }

  async checkID(entry: EntryModel): Promise<void> {
    entry.idCheckStatus = 'Checked';

    try {
      await entry.save();
      this.flashMessages.showAndHideFlash('success', 'Checked!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  // We currently manage the list of entries manually so have to take care of this
  // Not sure if there is a better solution
  removeEntryFromList(entry: EntryModel): void {
    set(
      this.model,
      'entries',
      this.model.entries.filter((e) => e.id !== entry.id),
    );
    this.selectedEntries = this.selectedEntries.filter((e) => entry !== e);
    this.calculateEntriesCount(-1);
  }

  deleteEntries(entries: EntryModel[]): void {
    void this.showDeleteConfirmationTask.perform(entries).then((result) => {
      if (result.deleteGroup) {
        this.deleteGroupEntries(entries);
      }
      entries.forEach((entry) => this.removeEntryFromList(entry));
      this.clearAll();
    });
  }

  deleteGroupEntries(entries: EntryModel[]): void {
    const parents = entries.filter((entry) => entry.hasAdditionalGuests);
    const parentIds = A(parents).mapBy('id');
    // This API is still a WIP - we might end up with something better
    // than this
    this.model.entries.forEach((entry) => {
      if (parentIds.includes(entry.belongsTo('groupParent').id())) {
        this.removeEntryFromList(entry);
      }
    });
  }

  deleteEntry(entry: EntryModel): void {
    void this.showDeleteConfirmationTask.perform([entry]).then((result) => {
      if (result.deleteGroup) {
        this.deleteGroupEntries([entry]);
      }
      this.removeEntryFromList(entry);
    });
  }

  @dropTask
  showDeleteConfirmationTask: {
    perform(entries: EntryModel[]): Promise<{ deleteGroup: boolean }>;
    entries: EntryModel[];
    abort?: () => void;
    continue?: (args: { deleteGroup: boolean }) => void;
  } = {
    entries: [],

    // @ts-ignore
    *perform(entries: EntryModel[]): Promise<{ deleteGroup: boolean }> {
      this.entries = entries;

      const deferred = defer<{ deleteGroup: boolean }>();

      this.abort = () => deferred.reject();
      this.continue = (args) => deferred.resolve(args);
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return yield deferred.promise;
    },
  };

  @action
  searchVisitors(event: Event): void {
    event.preventDefault();

    if (this.inputQuery) void this.mutateQuery.perform(this.inputQuery, true);
  }

  @action
  updateInputQuery(email: string): void {
    // Set input value immediately.
    // This reduces risk of rendered input reverting to a previous value.
    this.inputQuery = email;

    if (email) {
      void this.mutateQuery.perform(this.inputQuery);
    } else {
      this.clearSearch();
    }
  }

  mutateQuery = restartableTask(async (email: string, skipLengthCheck = false) => {
    // "Stop if you've heard this one before"
    // If the current query is the same as the new one, do not proceed!
    // This can happen if the search input modified and reverted rapidly
    // (e.g. with a quick "undo")
    if (this.query === email) return;

    // Stop if the query is "too short" for this ride
    if (!(skipLengthCheck || email.length >= this.minSearchLength)) return;

    await timeout(zft(500));

    void this.loadEntries.cancelAll();
    this.query = email;
    this.page = 0;
    this.metrics.trackEvent('Entries Searched', { search_value: this.query });
  });

  calculateEntriesCount(extra = 0): void {
    let total = this.totalLoadedEntries + extra;
    if (!this.showSignedOut) {
      // we need to subtract entries which are filtered out on the page, but were
      // in the result set
      const numberToExclude = this.model.entries.length - this.signedInEntries.length;
      total = total - numberToExclude;
    } else if (extra !== 0) {
      this.totalLoadedEntries = total;
    }
    this.entriesCount = total;
  }

  entryParams(_include: string[] = []): Record<string, unknown> {
    const include = ['platform-jobs', ..._include];
    const offset = (this.page - 1) * this.limit;
    let visitor_type: string | string[] = this.selectedFlow;
    if (this.featureFlags.isEnabled('visitors-filter-logs-by-multiple-flows')) {
      if (this.selectedFlows === '') {
        visitor_type = [];
      } else {
        const selectedFlowIds = this.selectedFlows.split(',');
        visitor_type = <string[]>selectedFlowIds
          .map((flowId) => {
            const flow = this.store.peekRecord('flow', flowId);
            // If this flow is an employee registration flow, always send its name to the API as
            // "Employee registration", regardless of the value we have here.
            // This is needed because the real API returns the GLOBAL employee registration flow in the
            // list of flows for a LOCATION, and the GLOBAL flow can be renamed (but the location flow can't),
            // but the LOCATION flow name is used when performing the filtering.
            if (flow?.type === FlowType.EMPLOYEE_SCREENING) {
              return EMPLOYEE_SCREENING_FLOW_NAME_OVERRIDE;
            }
            return flow?.name;
          })
          .filter((maybeFlowName) => !!maybeFlowName); // skip any empty flow names (which might happen if an invalid ID was present in the list)
      }
    }
    const filter: EntryQueryParams = {
      location: this.currentLocation.location.id,
      visitor_type,
    };
    if (isEmpty(this.query)) {
      filter['start-date'] = formatISO(this.startDateWithDefault);
      filter['end-date'] = formatISO(this.endDateWithDefault);
    } else {
      filter.query = this.query;
    }
    if (this.filter === 'signed-in') {
      filter.status = 'not-signed-out';
    }
    if (this.selected.employee) {
      filter.employee = this.selected.employee.id;
    }

    return {
      include: include.join(),
      filter,
      page: { limit: this.limit, offset },
      sort: this.sort,
    };
  }

  loadBosses = dropTask(async () => {
    try {
      const employee = this.currentAdmin.employee;
      if (!employee) {
        return;
      }
      const bossesIds = employee.hasMany('bosses') && employee.hasMany('bosses').ids();
      if (!bossesIds.length) {
        return;
      }
      const employeeBosses = await this.store.query('employee', {
        filter: { id: bossesIds.join(','), deleted: false },
      });
      const bosses = employeeBosses.filter((boss) => boss.id !== employee.id); // prevent self bossing.
      this.bosses = bosses;
    } catch (e) {
      this.logger.error(<Error>e);
    }
  });

  loadEntries = dropTask(async () => {
    try {
      if (!this.connectedTenants) {
        const { currentLocation } = this.state;
        this.connectedTenants = await currentLocation?.getPropertyConnections();
      }

      this.page++;
      const include: string[] = [];

      if (this.locationIsConnectedToProperty) include.push('invite-multi-tenancy-visitor-notification-logs');

      const entryParams = this.entryParams(include);
      const entries = <EntryPage>await this.store.query('entry', entryParams);
      if (this.abilities.can('see entry log for desk') && !!entries.length) {
        try {
          await this.store.query('reservation', {
            filter: {
              'entry-id': entries.mapBy('id').join(','),
            },
            include: 'desk',
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('Error fetching reservations', e);
        }
      }
      await this.pollEventReports.cancelAll();
      void this.pollEventReports.perform();
      // @ts-ignore private function
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.beginPropertyChanges();
      A(this.model.entries).pushObjects(A(entries.toArray()));
      // A side effect of manually managing the entries in our model
      // is that pubnub will occasionally resolve in a way that results
      // in duplicate entires in the model.
      //
      // Here we explicitly dedup the model. A better long term solution
      // will involve a larger refactor.
      set(this.model, 'entries', A(this.model.entries).uniqBy('id'));
      this.totalLoadedEntries = entries.meta?.total;
      this.hasEntries = entries.meta?.['has-entries'];
      // @ts-ignore private function
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.endPropertyChanges();
      this.calculateEntriesCount();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      // TODO: handle error state here. try...catch is quite big.
      // throw e;
      this.page--;
    }
  });

  pollEventReports = restartableTask(async () => {
    if (!this.currentLocation.location.hostNotificationsEnabled) {
      return;
    }
    const store = this.store;
    const location = this.currentLocation.location.id;
    let keepPolling = false;
    let maxPolls = NUMBER_OF_POLLING_TRIES;
    do {
      const entryEventReportIdentifiers = store.peekAll('entry').map((entry) => `vr:entry:${entry.id}`);
      const loadedEventReportsIdentifiers = store
        .peekAll('eventReport')
        .filterBy('hasTerminalStatus', true)
        .mapBy('identifier');
      const eventReportIdentifiers = entryEventReportIdentifiers.filter((eventReportIdentifier) => {
        return !loadedEventReportsIdentifiers.includes(eventReportIdentifier);
      });
      const identifierURI = eventReportIdentifiers.join(',');
      if (isBlank(identifierURI) || isBlank(location)) {
        break;
      }
      const newEventReportsQueryResult = await store.query('event-report', {
        filter: { identifier: identifierURI, location },
      });
      const newEventReports = newEventReportsQueryResult.toArray();
      if (newEventReports.length) {
        const loadedReports = this.eventReports;
        const newEventIds = A(newEventReports).mapBy('id');
        // Since it's possible that we could have an old event report in "pending" status that has since
        // changed to a "terminal" status, we always take the latest version of any duplicate event reports
        const updatedEventReportList = loadedReports
          .filter((report) => {
            return !newEventIds.includes(report.id);
          })
          .concat(newEventReports);
        this.eventReports = updatedEventReportList;
      }
      // Only poll as long as there are event reports that are either `queued` or `inProgress`.
      const hasPendingEvents = newEventReports.some((event) => event.inProgress || event.queued);
      // The events creation for a new entry is async, so the intial request for a just created entry
      // could be empty, so we give `NUMBER_OF_POLLING_TRIES`
      maxPolls--;
      keepPolling = hasPendingEvents || maxPolls > 1;
      await timeout(EVENT_REPORT_POLLING_TIMEOUT);
    } while ((config.environment !== 'test' || config.testLongPolling) && keepPolling);
  });

  fetchCalendarDataTask = task(async (specificDate: Date = new Date()) => {
    const formattedDate = format(specificDate, DATE_FNS_YYYY_MM_DD);
    const url = urlBuilder.v2.entriesCalendarUrl(this.state.currentLocation.id, formattedDate);

    let calendarEventEntries: CalenderEntriesCollection['data'] = [];

    try {
      calendarEventEntries = await this.ajax.request<CalenderEntriesCollection>(url).then((response) => response.data);
    } catch (_) {
      // Don't explode if this feature fails.
    }

    const results = calendarEventEntries
      .filter((event) => event.attributes['entries'] > 0)
      .map((event) => event.attributes.date);

    this.calendarEntries = [...new Set([...this.calendarEntries!, ...results])];

    return results;
  });

  clearAll(): void {
    this.selectedEntries = [];
  }

  _loadMore(): void | Promise<void> | TaskInstance<void> {
    // Because of the way loadMore works -- there are scenarios where
    // this can be called if destroyed
    if (this.isDestroyed) {
      return;
    }

    if (this.hasMorePages) {
      return this.loadEntries.perform();
    } else {
      return resolve();
    }
  }

  loadMore(): void | Promise<void> | TaskInstance<void> {
    return this._loadMore();
  }

  @action
  toggleDashboardField({ name, componentName, show }: Field & { show: boolean }): void {
    // componentName is no longer used, but the backend is still validating
    let dashboardFields = this.dashboardFieldsByLocationId[this.currentLocation.location.id] ?? [];

    if (show) {
      dashboardFields.push({ name, componentName });
    } else {
      dashboardFields = dashboardFields.filter((field) => field.name !== name);
    }

    this.currentLocation.location.dashboardFields = dashboardFields;
    this.dashboardFieldsByLocationId[this.currentLocation.location.id] = dashboardFields;

    void this.currentLocation.location.save();
  }

  asyncEntryExportParams(): EntryExportParams {
    let params: EntryExportParams = {};
    const filter = this.filter;
    const location = this.currentLocation.location;
    let query: string | null = this.query;
    const employee = this.selected.employee;

    // When the search is populated, we hide the date range picker. Presumably the user
    // wants to export the results of their search, regardless of date range. There is
    // really no technical reason for this.
    if (isBlank(query)) {
      query = null;
      params.startDate = startOfDay(this.startDateWithDefault);
      params.endDate = endOfDay(this.endDateWithDefault);
    }

    params = Object.assign(params, {
      employee,
      filter,
      location,
      query,
      sort: 'signed-in-at',
    });

    return params;
  }

  _hasActivePluginForCategory(name: string): boolean {
    if (!this.model || !this.model.plugins || !this.model.pluginInstalls) {
      return false;
    }

    const ids = this.model.plugins.filterBy('category', name).mapBy('id');

    return this.model.pluginInstalls.toArray().some((install) => {
      const active = install.status !== 'uninstalled';

      // eslint-disable-next-line ember/use-ember-get-and-set
      return active && ids.includes(install.get('plugin').get('id'));
    });
  }

  @action
  clearSearch(): void {
    this.page = this.query ? 0 : this.page;
    this.selectedFlow = '';
    this.inputQuery = null;
    this.query = '';
  }

  handleMessage(message: { event: string }): void {
    if (message.event === 'showEntryExportModal') {
      this.toggleModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleModal();
    }
  }

  @action
  trackGoToToday(): void {
    this.metrics.trackEvent('Entries Date Changed', {
      date_nav_method: 'go_to_today',
      date_selected: moment().toISOString(),
    });
  }

  @action
  toggleCalendarVisible(): void {
    this.mobileCalendarVisible = !this.mobileCalendarVisible;
  }

  // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  @action
  selectFlow(flow: FlowModel): void {
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.beginPropertyChanges();
    const name = isPresent(flow.id) || flow.name === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING ? flow.name : '';
    this.selectedFlow = name;
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.endPropertyChanges();
  }

  @action
  selectFlows(flows: FlowModel[]): void {
    this.selectedFlows = flows.map((flow) => flow.id).join(',');
  }

  @action
  selectFilter(option: FilterOption): void {
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.beginPropertyChanges();
    this.filter = option.filter;
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.endPropertyChanges();
    this.metrics.trackEvent('Entries Filtered', { filter_name: option.name });
  }

  @action
  setDateRange(start: Date, end: Date): void {
    this.startDate = start;
    this.endDate = end;
  }

  @action
  onShowCalendar(): void {
    this.showCalendar = true;
    void this.fetchCalendarDataTask.perform(this.startDateWithDefault);
  }

  @action
  handleGoBackOneMonth(date: Date): void {
    void this.fetchCalendarDataTask.perform(date);
  }

  @action
  handleGoToNextMonth(date: Date): void {
    void this.fetchCalendarDataTask.perform(date);
  }

  @action
  exportEntries(): TaskInstance<void> {
    const params = this.asyncEntryExportParams();
    return this.asyncExportManager.exportEntriesTask.perform(params);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'visitors.entries': VisitorsEntriesController;
  }
}
