/* eslint-disable no-unsafe-optional-chaining */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, dropTask, timeout } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';

const SAVE_NOTIFICATION_SETTING_DEBOUNCE = zft(1000);

type ResponseDataAttributes = {
  acsFailureNotificationsEnabled: boolean;
  dataMissingNotificationsEnabled: boolean;
  lastModifiedByUserId: number;
  timezone: string;
  createdAt: string;
  updatedAt: string;
};

type ResponseData = {
  attributes: ResponseDataAttributes;
  id: string;
  type: string;
};

type AcsLocationSettingsResponse = {
  data: ResponseData[];
};

export default class AppsSettingsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare ajax: AjaxService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked acsFailureNotificationsEnabled = false;
  @tracked dataMissingNotificationsEnabled = false;

  get showAccessControlAlertSettings(): boolean {
    return (
      this.featureFlags.isEnabled('show-acs-failure-alert-toggle') ||
      this.featureFlags.isEnabled('show-data-missing-alert-toggle')
    );
  }

  get showSecureOnPremiseSettings(): boolean {
    return (
      this.featureFlags.isEnabled('show-binary-download-modal') ||
      this.featureFlags.isEnabled('show-diplomat-client-config-modal')
    );
  }

  @action
  refreshDiplomatClients(): void {
    this.send('refreshModel');
  }

  toggleNotificationSetting = task(async (type: string, value: boolean) => {
    switch (type) {
      case 'acsFailure':
        this.acsFailureNotificationsEnabled = value;
        break;
      case 'dataMissing':
        this.dataMissingNotificationsEnabled = value;
        break;
    }

    await this.saveNotificationSettings.perform();
  });

  loadNotificationSettings = dropTask(async () => {
    const locationId = this.state?.currentLocation?.id;
    const url = urlBuilder.locationNotificationSettingsUrl(locationId);
    try {
      const response: AcsLocationSettingsResponse = await this.ajax.request<AcsLocationSettingsResponse>(url, {
        // @ts-ignore accept is not a valid attribute for request options
        accept: 'application/vnd.api+json',
      });
      const { data } = response;
      if (data.length !== 0) {
        this.acsFailureNotificationsEnabled = data[0]?.attributes.acsFailureNotificationsEnabled || false;
        this.dataMissingNotificationsEnabled = data[0]?.attributes.dataMissingNotificationsEnabled || false;
        return data[0]?.attributes;
      }
      return;
    } catch (_e) {
      return;
    }
  });

  saveNotificationSettings = dropTask(async () => {
    const { id: locationId, timezone } = this.state?.currentLocation;
    const { id: lastModifiedByUserId } = this.state?.currentUser;
    const { acsFailureNotificationsEnabled, dataMissingNotificationsEnabled } = this;
    const url = urlBuilder.locationNotificationSettingsUrl(locationId);

    await timeout(SAVE_NOTIFICATION_SETTING_DEBOUNCE);

    try {
      await this.ajax.request(url, {
        type: 'PATCH',
        contentType: 'application/json',
        // @ts-ignore accept is not a valid attribute for request options
        accept: 'application/vnd.api+json',
        data: {
          timezone,
          acsFailureNotificationsEnabled,
          dataMissingNotificationsEnabled,
          lastModifiedByUserId,
        },
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  });
}
