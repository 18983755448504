import Model, { type AsyncHasMany, attr, belongsTo, hasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type AreaMapModel from 'garaje/models/area-map';
import adapter from 'garaje/utils/decorators/adapter';

import type DeskModel from './desk';
import type EmployeeModel from './employee';

@adapter('maps')
class DraftModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('string') declare sharingStrategy: string;
  @attr('number') declare createdBy: number;
  @attr('number') declare lastUpdatedBy: number;
  @attr('number') declare versionNumber: number;
  @attr('number') declare createdAt: number;
  @attr('number') declare publishedAt: number;

  // Relationships
  @belongsTo('area-map') declare originalAreaMap: AreaMapModel;
  @belongsTo('area-map') declare draftAreaMap: AreaMapModel;
  @hasMany('desk') declare desks: DeskModel;
  @hasMany('employee') declare sharedEmployees: AsyncHasMany<EmployeeModel>;

  async publish(payload: unknown): Promise<unknown> {
    return await apiAction(this, { method: 'PUT', path: 'publish', data: payload });
  }
}

export default DraftModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    draft: DraftModel;
  }
}
