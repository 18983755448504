import { FormEvent, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { TemplateFormProps } from '../components/template-form/template-form';
import {
  ALL_DELIVERY_METHODS,
  MESSAGE_DELIVERY_LABEL_MAP,
  MESSAGE_DELIVERY_MAP,
} from '../data/message-delivery-methods';
import { Audience } from '../types/audience';
import { Location } from '../types/location';
import { MessageCategory } from '../types/message-category';
import { Template } from '../types/template';

export const useTemplateForm = (
  { service, values, onSave, createCategory, onDelete }: TemplateFormProps,
  form: UseFormReturn<Template>,
) => {
  const [categories, setCategories] = useState<MessageCategory[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<{ label: string; value: string }[]>([]);
  const [messageVariables, setMessageVariables] = useState<{ label: string; value: string }[]>([]);
  const [employeeAudienceOptions, setEmployeeAudienceOptions] = useState<Audience[]>([]);
  const [visitorAudienceOptions, setVisitorAudienceOptions] = useState<Audience[]>([]);
  const [locationOptions, setLocationOptions] = useState<Location[]>([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { errors, isValid } = form.formState;

  const isDisabled = useMemo(() => {
    return Object.keys(errors).length || !isValid;
  }, [errors, isValid]);

  const loadState = async () => {
    const [audiencesResult, locationsResult, messageVariablesResult, categoriesResult, deliveryMethodResult] =
      await Promise.all([
        service.getAudiences(),
        service.getLocations(),
        service.getMessageVariables(),
        service.getCategories(),
        service.getAvailableDeliveryMethods(),
      ]);

    setEmployeeAudienceOptions(audiencesResult.employees || []);
    setVisitorAudienceOptions(audiencesResult.visitors || []);
    setLocationOptions(locationsResult || []);
    setMessageVariables(messageVariablesResult || []);
    setCategories(categoriesResult || []);
    setDeliveryMethods(
      deliveryMethodResult.map((deliveryMethod) => ({
        label: MESSAGE_DELIVERY_LABEL_MAP[deliveryMethod],
        value: MESSAGE_DELIVERY_MAP[deliveryMethod],
      })),
    );
  };

  const fillForm = () => {
    form.reset({
      name: values?.name || '',
      title: values?.title || '',
      message: values?.message || '',
      category: values?.category || undefined,
      defaultChannels: values?.defaultChannels || ALL_DELIVERY_METHODS,
      defaultEmployeeAudiences: values?.defaultEmployeeAudiences || [],
      defaultVisitorAudiences: values?.defaultVisitorAudiences || [],
      customSelections: values?.customSelections || [],
      locations: values?.locations || [],
      markAsSafe: values?.markAsSafe || false,
      published: values?.published || true,
    });
  };

  const handleCreateCategory = async () => {
    try {
      setIsCreatingCategory(true);
      const category = await createCategory(newCategoryName);

      if (!category) return;

      setCategories([...categories, category].sort((a, b) => a.name.localeCompare(b.name)));
      setShowCreateCategoryModal(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCreatingCategory(false);
    }
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();

    try {
      setIsSaving(true);

      const formValues = form.getValues();
      const customSelections = formValues.customSelections || [];

      const selectedEmployees = customSelections.filter((s) => s.type === 'EMPLOYEE').map((s) => s.value);
      const selectedGroups = customSelections
        .filter((s) => ['CUSTOM_SCIM_GROUP', 'DEPARTMENT', 'DIVISION', 'ORGANIZATION'].includes(s.type))
        .map((s) => s.value);

      // Only save methods that are available to select
      const savedDeliveryMethods = formValues.defaultChannels.filter((method) =>
        deliveryMethods.some((deliveryMethod) => deliveryMethod.value === method),
      );

      await onSave({
        selectedEmployees,
        selectedGroups,
        ...formValues,
        defaultChannels: savedDeliveryMethods,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (!onDelete) return;

    try {
      setIsDeleting(true);
      await onDelete();
    } catch (err) {
      console.error(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    categories,
    deliveryMethods,
    messageVariables,
    employeeAudienceOptions,
    visitorAudienceOptions,
    locationOptions,
    isDisabled,
    isSaving,
    isDeleting,
    newCategoryName,
    showCreateCategoryModal,
    isCreatingCategory,
    setNewCategoryName,
    setShowCreateCategoryModal,
    fillForm,
    loadState,
    handleCreateCategory,
    handleDelete,
    handleSave,
  };
};
