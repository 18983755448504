import { computed, get } from '@ember/object';
import type ComputedProperty from '@ember/object/computed';
import type DeviceModel from 'garaje/models/device';
import { appVersionComparator, isAppVersionGreaterEqual } from 'garaje/utils/check-app-version';

export interface Locale {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface Translatable {
  id: string;
  isDeleted: boolean;
  isNew: boolean;
  isGlobal: boolean;
  customTranslations: {
    name: Record<string, string>;
    [key: string]: Record<string, string>;
  };
  reload: () => Promise<void>;
  [key: string]: unknown;
}

const LOCALES: Locale[] = [
  { label: 'English', value: 'en' },
  { label: 'العربية (Arabic)', value: 'ar' },
  { label: 'Български (Bulgarian)', value: 'bg-BG' },
  { label: 'Čeština (Czech)', value: 'cs' },
  { label: 'Dansk (Danish)', value: 'da' },
  { label: 'Deutsch (German)', value: 'de' },
  { label: 'Español (Spanish)', value: 'es' },
  { label: 'Eesti (Estonian)', value: 'et-EE' },
  { label: 'Suomi (Finnish)', value: 'fi' },
  { label: 'Français (French)', value: 'fr' },
  { label: 'עִבְרִית‎ (Hebrew)', value: 'he' },
  { label: 'हिन्दी (Hindi)', value: 'hi-IN' },
  { label: 'Hrvatski (Croatian)', value: 'hr' },
  { label: 'Magyar (Hungarian)', value: 'hu' },
  { label: 'Italiano (Italian)', value: 'it' },
  { label: '日本語 (Japanese)', value: 'ja' },
  { label: '한국어 (Korean)', value: 'ko' },
  { label: 'Bahasa Melayu (Malay)', value: 'ms-MY' },
  { label: 'Norsk (Norwegian)', value: 'nb' },
  { label: 'Nederlands (Dutch)', value: 'nl' },
  { label: 'Polski (Polish)', value: 'pl' },
  { label: 'Português (Portuguese)', value: 'pt-BR' },
  { label: 'Română (Romanian)', value: 'ro' },
  { label: 'Pусский (Russian)', value: 'ru' },
  { label: 'Slovenčina (Slovak)', value: 'sk' },
  { label: 'Slovenščina (Slovenian)', value: 'sl-SI' },
  { label: 'Српски (Serbian)', value: 'sr-RS' },
  { label: 'Svenska (Swedish)', value: 'sv-SE' },
  { label: 'ภาษาไทย (Thai)', value: 'th' },
  { label: 'Українська (Ukrainian)', value: 'uk' },
  { label: '简体中文 (Chinese Simplified)', value: 'zh-CN' },
  { label: '繁体中文 (Chinese Traditional)', value: 'zh-TW' },
] as const;
export default LOCALES;

const RTL_LOCALES: Locale[] = [
  { label: 'العربية (Arabic)', value: 'ar' },
  { label: 'עִבְרִית‎ (Hebrew)', value: 'he' },
];

export type LocaleKey = (typeof LOCALES)[number]['value'];

interface DeviceLocale {
  localeCode: LocaleKey;
  name: string;
}

interface AppVersionedLocales {
  [key: string]: DeviceLocale[];
}

interface DisabledLocaleInfo {
  disabledLocales: DeviceLocale[];
  appVersionNeeded: string;
}

/**
 *
 * @param locale - the locale code
 * @returns true if the language is right-to-left
 */
export function isRTLLocale(locale?: LocaleKey): boolean {
  if (!locale) return false;

  return RTL_LOCALES.map((l) => l.value).includes(locale);
}

// Object that pairs locales with the app versions needed to support them
const APP_VERSIONED_LOCALES: AppVersionedLocales = {
  '3.4.0': [
    { localeCode: 'ar', name: 'Arabic' },
    { localeCode: 'he', name: 'Hebrew' },
  ],
  '3.5.0': [
    { localeCode: 'cs', name: 'Czech' },
    { localeCode: 'ms-MY', name: 'Malay' },
    { localeCode: 'pl', name: 'Polish' },
  ],
  '3.10.0': [
    { localeCode: 'bg-BG', name: 'Bulgarian' },
    { localeCode: 'hr', name: 'Croatian' },
    { localeCode: 'hu', name: 'Hungarian' },
    { localeCode: 'sk', name: 'Slovak' },
    { localeCode: 'sl-SI', name: 'Slovenian' },
    { localeCode: 'sr-RS', name: 'Serbian' },
    { localeCode: 'uk', name: 'Ukrainian' },
  ],
  '3.10.1': [{ localeCode: 'et-EE', name: 'Estonian' }],
};

/**
 * Return an array of locales (from the LOCALES array of this module) with the
 * addition of a `disabled` key indicated whether the locale is selectable based on
 * the app versions of the given list of devices. (For example, if one or more of the
 * devices is on a lower version than the one in which a locale is supported [see
 * APP_VERSIONED_LOCALES], then it will have `disabled: true`.)
 *
 * Note: this function is similar, but not identical, to the logic in the `disabledLocalesInfo`
 * computed property. That function returns only the disabled locales, not all locales with
 * a `disabled` boolean added.
 *
 * @param devices
 */
export function localeOptions(devices: DeviceModel[]): Locale[] {
  const ipads = devices.filter((device: DeviceModel) => device.isIpad);
  const currentAppVersions = ipads.map((ipad) => ipad.appVersion).sort(appVersionComparator);
  const oldestVersion = currentAppVersions[0];

  if (!oldestVersion) return LOCALES;

  const appVersionsNeeded = Object.keys(APP_VERSIONED_LOCALES).sort(appVersionComparator);
  const disabledLocales: LocaleKey[] = [];

  // compare versions needed to the oldest version to get disabled locales
  for (const versionNeeded of appVersionsNeeded) {
    const updateNeeded = !isAppVersionGreaterEqual(oldestVersion, versionNeeded);

    if (updateNeeded) {
      disabledLocales.push(
        ...(APP_VERSIONED_LOCALES[versionNeeded] || []).map((versionedLocale) => versionedLocale.localeCode),
      );
    }
  }

  return LOCALES.map((localeItem) => ({
    ...localeItem,
    disabled: disabledLocales.includes(localeItem.value),
  }));
}

/**
 * Higher order function that returns a computed property for checking disabled locales
 *
 * @function
 * @param dependentKey - dependent key where the iPads are location. `Ex: model.ipads.@each{isIpad,appVersion}`
 */
export function disabledLocalesInfo(dependentKey: string): ComputedProperty<null | DisabledLocaleInfo> {
  return computed(`${dependentKey}.@each{isIpad,appVersion}`, function () {
    const devices = <DeviceModel[]>(get(this, dependentKey) || []);
    const ascendingCurrentVersions = (Array.isArray(devices) ? devices : [])
      .filter((ipad: DeviceModel) => ipad.isIpad)
      .map((ipad: DeviceModel) => ipad.appVersion)
      .sort((a: string, b: string) => (isAppVersionGreaterEqual(a, b) ? 1 : -1));
    const oldestVersion = ascendingCurrentVersions[0];

    if (!oldestVersion) return null;

    const disabledLocales: DeviceLocale[] = [];
    const ascendingVersionsNeeded = Object.keys(APP_VERSIONED_LOCALES).sort((a, b) =>
      isAppVersionGreaterEqual(a, b) ? 1 : -1,
    );

    // compare versions needed to the oldest version to get disabled locales
    ascendingVersionsNeeded.forEach((versionNeeded) => {
      const updateNeeded = !isAppVersionGreaterEqual(oldestVersion, versionNeeded);

      if (updateNeeded) {
        disabledLocales.push(...(APP_VERSIONED_LOCALES[versionNeeded] || []));
      }
    });

    if (disabledLocales.length === 0) return null;

    const appVersionNeeded = ascendingVersionsNeeded.slice(-1)[0] || '';

    return { disabledLocales, appVersionNeeded };
  });
}

/**
 * Higher order function that returns a computed property for displaying a locale update warning
 *
 * @function
 */
export function localeUpdateWarning(): ComputedProperty<null | { header: string; message: string }> {
  return computed(
    'disabledLocalesInfo.{disabledLocales.[],appVersionNeeded}',
    function (this: { disabledLocalesInfo: DisabledLocaleInfo }) {
      const info = <DisabledLocaleInfo | null>(this.disabledLocalesInfo || null);

      if (!info) return null;

      return {
        header: 'App update required to support new languages',
        message: `Version ${info.appVersionNeeded} or higher of the Envoy Visitors iPad app is required.`,
      };
    },
  );
}
