import { action } from '@ember/object';
import Component from '@glimmer/component';
import { isRTLLocale } from 'garaje/utils/locale-options';
import type { Locale, LocaleKey, Translatable } from 'garaje/utils/locale-options';
import { localCopy } from 'tracked-toolbox';

interface ManageTranslationsFieldRowComponentSignature {
  Args: {
    defaultLocale: LocaleKey;
    key?: string;
    nested?: boolean;
    forceDisable?: boolean;
    selectedLocale: Locale;
    translatable: Translatable;
    updateTranslatable?: (translatable: Translatable, attr: string, value: Translatable['customTranslations']) => void;
  };
}

export default class ManageTranslationsFieldRowComponent extends Component<ManageTranslationsFieldRowComponentSignature> {
  @localCopy('args.translatable.customTranslations', {}) customTranslations!: Translatable['customTranslations'];

  get key(): string {
    // args.key will be 'value' always if template is rendered recursively
    // this only happens when we render sign-in-field options for translation
    // everything else we translate is keyed on 'name' ... options though ... 🤷‍♂️
    return this.args.key || 'name';
  }

  get bestAvailableLabel(): string {
    const { translatable, key } = this.args;
    const preferredKeys = [key, 'localized', 'name'];

    if (!translatable) return '';

    for (let i = 0; i < preferredKeys.length; i++) {
      const k = preferredKeys[i];

      if (typeof k !== 'string') continue;

      const label = translatable[k];

      if (label && typeof label === 'string') return label;
    }

    return '';
  }

  get isDisabled(): boolean {
    const { forceDisable, translatable } = this.args;

    if (forceDisable) return true;

    // If it quacks like a signinfield ...
    if (typeof translatable['isCustom'] === 'boolean') return !translatable['isCustom'];

    return false;
  }

  get isRTLDefaultLocale(): boolean {
    return isRTLLocale(this.args.defaultLocale);
  }

  get isRTLLocale(): boolean {
    return isRTLLocale(this.args.selectedLocale.value);
  }

  get localeValue(): string {
    const {
      key,
      customTranslations,
      args: { selectedLocale },
    } = this;
    const locale = selectedLocale?.value ?? '';

    if (!(key && locale)) return '';

    return customTranslations[key]?.[locale] || '';
  }

  changeCustomTranslationValue(value: string): void {
    const {
      key,
      args: { translatable, selectedLocale },
    } = this;
    const { customTranslations } = translatable;
    const locale = selectedLocale?.value ?? '';

    if (!(key && locale)) return;
    if (!customTranslations[key]) customTranslations[key] = {};

    customTranslations[key][locale] = value;

    this.args.updateTranslatable?.(translatable, 'customTranslations', customTranslations);
  }

  @action
  changeValue(value: string): void {
    this.changeCustomTranslationValue(value);
  }

  @action
  cleanValue(value: string = ''): void {
    this.changeCustomTranslationValue(`${value}`.trim());
  }
}
