import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import { isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import { task, timeout } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import type EmployeeModel from 'garaje/models/employee';
import type StateService from 'garaje/services/state';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { RecordArray } from 'garaje/utils/type-utils';
import zft from 'garaje/utils/zero-for-tests';
import _cloneDeep from 'lodash/cloneDeep';

// When need to clear the extra filters because when using this task directly with
// {{power-select search=(perform employeesSearcherTask)}} it passes a second argument with
// information not needed
function cleanExtraFilters(extraFilters: unknown): Record<string, unknown> {
  const allowedFilters = ['except', 'locations']; // Add any other filter when needed
  const cleaned: Record<string, unknown> = {};
  allowedFilters.forEach((filterName) => {
    if (get(extraFilters, filterName)) {
      cleaned[filterName] = get(extraFilters, filterName);
    }
  });
  return cleaned; // cleaned filters with only allowed keys
}

type EmployeeSearcherQuery = {
  locations?: number | undefined;
  prefix?: boolean;
  filter: {
    query?: string;
    deleted?: boolean;
    locations?: string | undefined;
    excludeHidden?: boolean;
  };
};

type EmployeeSearcherQueryOptions = {
  withoutLocation?: boolean;
  [key: string]: unknown;
};

/**
 * Create and return a fresh Ember Concurrency Task that will search employees
 *
 *    Example:
 *    ```js
 *      import employeesSearcherTask from 'garaje/utils/employees-searcher';
 *      //..
 *      searchEmployeesTask: employeesSearcherTask({
 *        filter: {
 *          deleted: false,
 *         excludeHidden: false
 *        },
 *        prefix: true
 *      }).restartable(),
 *      //..
 *    ```
 * @function
 * @param {object} [{filter: {...}}] - An object with the query options like `filter` and `prefix`
 * @returns {Task} - An Ember Concurrency task
 */

interface EmployeeSearchTask {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  restartable: () => any;
}

function employeesSearcherTask(query: EmployeeSearcherQuery = { filter: { deleted: false } }): EmployeeSearchTask {
  return task({
    *perform(
      term: string,
      extraFilters: { [key: string]: unknown } = {},
      opts: EmployeeSearcherQueryOptions = {},
    ): Generator<unknown, unknown, DS.PromiseArray<EmployeeModel, RecordArray<EmployeeModel>>> {
      if (isBlank(term)) {
        return [];
      }

      yield timeout(zft(250));

      const clonedQuery: EmployeeSearcherQuery = _cloneDeep(query);

      // Clean manually passed filters
      extraFilters = cleanExtraFilters(extraFilters);

      // Add query term and extra filters
      clonedQuery.filter = {
        ...clonedQuery.filter,
        ...extraFilters,
        query: term,
      };
      // @ts-ignore
      const applicationInstance = getOwner(this.context);
      if (applicationInstance == undefined) {
        throw new Error('could not load application');
      }
      const state = applicationInstance.lookup('service:state') as StateService;
      const store = applicationInstance.lookup('service:store') as Store;
      if (state == null) {
        return [];
      }

      if (!opts.withoutLocation) {
        if (!clonedQuery.filter.locations) {
          clonedQuery.filter.locations = state.currentLocation?.id;
        }
      }
      const results = yield store.query('employee', clonedQuery) as DS.PromiseArray<
        EmployeeModel,
        RecordArray<EmployeeModel>
      >;

      return results.uniq();
    },
  });
}

export default employeesSearcherTask;
