import { A } from '@ember/array';
import { get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { isBefore, addDays, startOfDay } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import type ApprovalStatusModel from 'garaje/models/approval-status';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type RecurringInviteModel from 'garaje/models/recurring-invite';
import RecurringRule from 'garaje/models/recurring-rule';
import type UserModel from 'garaje/models/user';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { alias, and, not, notEmpty, or } from 'macro-decorators';

const ADDITIONAL_APPROVAL_FLASH_MESSAGE_TEXT = {
  location: 'Additional approval from the property manager is required before the invitation is sent.',
  property: 'The tenant will also need to approve this before the invitation is sent.',
};

interface ApprovalCheckComponentArgs {
  blocklistContacts: UserModel[];
  /**
   * The context in which the component is being used
   */
  context: 'location' | 'property';
  entry: EntryModel;
  idScanContacts: UserModel[];
  invite: InviteModel;
}

type EntryOrInvite = EntryModel | InviteModel;

export default class ApprovalCheckComponent extends Component<ApprovalCheckComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;

  @or('args.entry', 'args.invite') entryOrInvite!: EntryOrInvite;
  @or('args.invite.expectedArrivalTime', 'args.entry.signedInAt') date!: Date;
  @and('pendingReview', 'isBeforeToday', 'hasNoFutureOccurences') isExpired!: boolean;
  @not('canReview') cannotReview!: boolean;
  @not('isRecurringInviteWithFutureOccurrences') hasNoFutureOccurences!: boolean;
  @notEmpty('args.entry') isEntry!: boolean;
  @notEmpty('args.invite') isInvite!: boolean;
  @alias('entryOrInvite.fullName') fullName!: EntryOrInvite['fullName'];
  @alias('entryOrInvite.approvalStatus') approvalStatus!: EntryOrInvite['approvalStatus'];
  @alias('entryOrInvite.needsApprovalReview') pendingReview!: EntryOrInvite['needsApprovalReview'];
  @alias('entryOrInvite.approvalWasDenied') wasDenied!: EntryOrInvite['approvalWasDenied'];
  @alias('approvalStatus.reviewerName') reviewerName!: ApprovalStatusModel['reviewerName'];
  @alias('args.invite.recurringInvite.recurringRule') recurringRule!: RecurringInviteModel['recurringRule'];

  get canReview(): boolean {
    return this.abilities.can('review entry-approval', {
      context: this.context,
      report: this.approvalStatus?.failedReport,
    });
  }

  get contactedNames(): string[] {
    let contacts: string[] = [];
    if (this.approvalStatus?.didFailBlocklistCheck && this.args.blocklistContacts) {
      contacts = [...contacts, ...this.args.blocklistContacts.map((contact) => contact.fullName)];
    }
    if (this.approvalStatus?.didFailIdScanningCheck && this.args.idScanContacts.map((contact) => contact.fullName)) {
      contacts = [...contacts, ...this.args.idScanContacts.map((contact) => contact.fullName)];
    }
    return A(contacts).uniq();
  }

  get context(): ApprovalCheckComponentArgs['context'] {
    return this.args.context ?? 'location';
  }

  get isRecurringInviteWithFutureOccurrences(): boolean {
    if (!this.recurringRule) return false;
    const { until } = RecurringRule.parse(this.recurringRule);
    return !until || until.isAfter();
  }

  get isBeforeToday(): boolean {
    return isBefore(this.date, startOfDay(new Date()));
  }

  get expiredAt(): string | null {
    const expireDate = startOfDay(addDays(this.date, 1)).toISOString();
    return this.isExpired ? expireDate : null;
  }

  get sourcesForApprovalReviewMetrics(): Record<string, boolean> {
    return (
      this.approvalStatus?.failedReport.reduce((sources, report) => ({ ...sources, [report.source]: true }), {}) ?? {}
    );
  }

  trackReviewMetrics(action: string): void {
    this.metrics.trackEvent(`Dashboard ${this.isEntry ? 'Entry' : 'Invite'} - Reviewed`, {
      action,
      [this.isEntry ? 'entry_id' : 'invite_id']: this.entryOrInvite.id,
      source: `${this.isEntry ? 'Entry' : 'Invite'} Details`,
      ...this.sourcesForApprovalReviewMetrics,
    });
  }

  approveTask = task(async () => {
    try {
      if (this.isEntry) {
        const { entry } = this.args;

        await entry.approveEntry();
        // eslint-disable-next-line ember/no-get
        await get(entry, 'flow.badge'); // load async relationship for entryApprovalMessage helper dependency

        this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));

        await entry.reload();
      } else if (this.isInvite) {
        const { invite } = this.args;

        await invite.approveInvite();
        await invite.reload();

        let additionalFlashMessageText;
        if (!invite.preregistrationComplete) {
          if (invite.approved) {
            additionalFlashMessageText = 'Invitation sent!';
          } else {
            additionalFlashMessageText = ADDITIONAL_APPROVAL_FLASH_MESSAGE_TEXT[this.context];
          }
        }

        this.flashMessages.showAndHideFlash('success', 'Access approved', additionalFlashMessageText);
      }

      this.trackReviewMetrics('approve');
    } catch (error) {
      this.flashMessages.showAndHideFlash(
        'error',
        `Error approving ${this.isEntry ? 'entry' : 'invite'}`,
        parseErrorForDisplay(error),
      );
    }
  });

  denyTask = task(async () => {
    try {
      if (this.isEntry) {
        await this.args.entry.denyEntry();
        this.flashMessages.showAndHideFlash('warning', 'Entry denied');
        await this.args.entry.reload();
      } else if (this.isInvite) {
        await this.args.invite.denyInvite();
        this.flashMessages.showAndHideFlash('warning', 'Entry denied');
        await this.args.invite.reload();
      }

      this.trackReviewMetrics('deny');
    } catch (error) {
      this.flashMessages.showAndHideFlash(
        'error',
        `Error denying ${this.isEntry ? 'entry' : 'invite'}`,
        parseErrorForDisplay(error),
      );
    }
  });
}
