"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkParam = exports.NetworkDefault = void 0;
exports.NetworkDefault = {
    eventsApi: 'https://prodregistryv2.org/v1',
    initializeApi: 'https://featureassets.org/v1',
    specsApi: 'https://assetsconfigcdn.org/v1',
};
exports.NetworkParam = {
    EventCount: 'ec',
    SdkKey: 'k',
    SdkType: 'st',
    SdkVersion: 'sv',
    Time: 't',
    SessionID: 'sid',
    StatsigEncoded: 'se',
    IsGzipped: 'gz',
};
