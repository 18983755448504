import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';

export default class CreateEmployeeRegistrationFlowModal extends Component {
  @service abilities;
  @service state;
  @service store;
  @service flow;
  @service router;
  @service currentAdmin;
  @service currentLocation;
  @service flashMessages;

  @tracked name = '';
  @tracked previewing = false;
  @tracked previewingFlow = null;
  @tracked selectedFlow = null;

  @alias('state.vrSubscription') vrSubscription;
  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin;
  @alias('flow.openedModal') openedModal;
  @alias('flow.globalEmployeeScreeningFlows') globalEmployeeScreeningFlows;
  @alias('state.currentCompany') company;
  @alias('flow.selectedEmployeeScreeningFlow') selectedEmployeeScreeningFlow;
  @alias('flow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;
  @alias('flow.currentSkinnyLocation') currentSkinnyLocation;

  get canHaveMultipleFlows() {
    return !!this.state.features?.canAccessMultipleFlows;
  }

  get activeOrSelectedEmployeeScreeningFlow() {
    return this.selectedEmployeeScreeningFlow || this.activeEmployeeScreeningFlow;
  }

  constructor() {
    super(...arguments);
    this.selectedFlow = this.activeEmployeeScreeningFlow;
  }

  get disableSubmit() {
    const { name, globalEmployeeScreeningFlows } = this;
    const disabled =
      name.length && globalEmployeeScreeningFlows.length
        ? globalEmployeeScreeningFlows.find((flow) => flow.name === name)
        : true;
    return !!disabled;
  }

  @action
  previewFlow(flow) {
    this.selectedFlow = flow;

    this.openedModal = '';
    this.router.transitionTo('workplace.settings.employees.registration.edit', flow.id);
  }

  @action
  unpreview() {
    this.previewing = false;
  }

  @action
  select() {
    this.selectedEmployeeScreeningFlow = this.selectedFlow;
    this.openedModal = '';
    const transitionRouteString = this.abilities.can('visit settings for workplace')
      ? 'workplace.settings.employees.registration'
      : 'protect.settings.employees.registration';
    this.router.transitionTo(transitionRouteString);
  }

  @action
  async assignFlowToCurrentLocation() {
    const { flashMessages, currentSkinnyLocation } = this;
    currentSkinnyLocation.employeeScreeningFlow = this.selectedFlow;
    this.selectedEmployeeScreeningFlow = this.selectedFlow;
    await currentSkinnyLocation.save();
    this.openedModal = '';
    flashMessages.showAndHideFlash('success', 'Saved!');
  }

  @action
  selectFlow(flow) {
    this.selectedFlow = flow;
  }
}
