import { action } from '@ember/object';
import Component from '@glimmer/component';
import { format } from 'date-fns';
import type { DateInputField } from 'garaje/models/abstract/abstract-document';
import { DATE_FORMAT } from 'garaje/models/abstract/abstract-document';

interface UserDocumentInputFieldComponentSignature {
  Args: {
    inputField: DateInputField;
    onChange?: (value: Date) => void;
    max?: string | null;
  };
}

export default class UserDocumentInputFieldComponent extends Component<UserDocumentInputFieldComponentSignature> {
  get max(): string | null {
    if (this.args.max) {
      return this.args.max;
    } else if (this.args.max === null) {
      return null;
    } else {
      return format(new Date(), DATE_FORMAT);
    }
  }

  @action
  updateInputField(value: Date): void {
    const { inputField, onChange } = this.args;
    inputField.value = value;
    onChange?.(inputField.value);
  }
}
