import ApplicationSerializer from './application';

export default class GlobalIdScanPageSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'id-scan-pages') {
      return 'global-id-scan-page';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-id-scan-page') {
      return 'id-scan-pages';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }
}
