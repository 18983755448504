"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._getOverridableUrl = void 0;
function _getOverridableUrl(overrideUrl, overrideApi, defaultEndpoint, defaultApi) {
    if (overrideUrl) {
        return overrideUrl;
    }
    else if (overrideApi) {
        return `${overrideApi}${defaultEndpoint}`;
    }
    else {
        return `${defaultApi}${defaultEndpoint}`;
    }
}
exports._getOverridableUrl = _getOverridableUrl;
