import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import { get, action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { addDays, parse, subSeconds, isValid, isAfter, format } from 'date-fns';
import { fromZonedTime, formatInTimeZone } from 'date-fns-tz';
import type { AllInputFields } from 'garaje/models/abstract/abstract-document';
import { DATE_FORMAT } from 'garaje/models/abstract/abstract-document';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type UserDocumentTemplateConfigurationModel from 'garaje/models/user-document-template-configuration';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import { or, reads } from 'macro-decorators';

import type { FileUpload } from '../../direct-uploader/component';

interface VisitorDocumentManagerComponentSignature {
  Args: {
    /**
     * VisitorDocument record
     */
    visitorDocument: VisitorDocumentModel;
    /**
     * configs to indicate validity duration of document
     */
    userDocumentTemplateConfiguration?: UserDocumentTemplateConfigurationModel;
    /**
     * time zone to adjust to for date computations
     */
    timezone?: string;
    /**
     * respond to click on reset button ("garbage" icon)
     */
    onReset?: (visitorDocument: VisitorDocumentModel) => void;
    /**
     * action triggered when a file is selected
     */
    onFileSelected?: (
      visitorDocument: VisitorDocumentModel,
      templateAttachment: UserDocumentTemplateAttachmentModel,
      file: File,
    ) => void;
    /**
     * action triggered when a file preview is computed
     */
    onFilePreview?: (
      visitorDocument: VisitorDocumentModel,
      templateAttachment: UserDocumentTemplateAttachmentModel,
      src: string,
    ) => void;
    /**
     * action triggered when an input value changes
     */
    onInputFieldChange?: (visitorDocument: VisitorDocumentModel, inputField: AllInputFields) => void;
    /**
     * option to disable reset button
     */
    disableReset?: boolean;
  };
}

interface UserDocumentAttachmentData {
  trackingKey?: string;
  userDocumentTemplateAttachment?: UserDocumentTemplateAttachmentModel;
  userDocumentAttachment?: UserDocumentAttachmentModel;
  externalAttachment?: VisitorDocumentModel['externalAttachments'][number];
  fileUrl?: string | null;
  currentUpload?: Partial<FileUpload> | null;
  title: string;
}

export default class VisitorDocumentManagerComponent extends Component<VisitorDocumentManagerComponentSignature> {
  validFileTypes = /image\/(gif|jpe?g|png)/;

  @tracked lightboxAttachment = null;

  @reads('args.visitorDocument.hasAttachedFile') hasAttachedFile!: VisitorDocumentModel['hasAttachedFile'];
  @or('args.visitorDocument.id', 'hasAttachedFile') isResettable!: boolean;

  get sortedUserDocumentTemplateAttachments(): NativeArray<UserDocumentTemplateAttachmentModel> {
    return (<
        ArrayProxy<UserDocumentTemplateAttachmentModel> // eslint-disable-next-line ember/no-get
      >get(this.args, 'visitorDocument.userDocumentTemplate.userDocumentTemplateAttachments'))?.sortBy('position') ?? [];
  }

  get sortedUserDocumentAttachmentData(): UserDocumentAttachmentData[] {
    const { visitorDocument } = this.args;

    // Assemble pairs of template attachments and document attachments
    const sortedUserDocumentAttachmentData: UserDocumentAttachmentData[] =
      this.sortedUserDocumentTemplateAttachments.map((userDocumentTemplateAttachment, index) => {
        const userDocumentAttachment = visitorDocument?.getAttachment(userDocumentTemplateAttachment.id);
        return {
          trackingKey: `${userDocumentTemplateAttachment.id} - ${index}`,
          userDocumentTemplateAttachment,
          userDocumentAttachment,
          fileUrl: userDocumentAttachment?.fileUrl,
          currentUpload: userDocumentAttachment?.fileUrl
            ? { isValid: true, readerResult: userDocumentAttachment.fileUrl }
            : null,
          title: userDocumentTemplateAttachment.title,
        };
      });

    visitorDocument.externalAttachments.forEach((externalAttachment) => {
      sortedUserDocumentAttachmentData.push({
        externalAttachment,
        fileUrl: externalAttachment.url,
        title: externalAttachment.type,
      });
    });

    return sortedUserDocumentAttachmentData;
  }

  get issueDate(): Date | null {
    const { visitorDocument } = this.args;
    const issueDateStr = visitorDocument?.issueDate;

    if (!issueDateStr) return null;
    const issueDate = parse(issueDateStr, DATE_FORMAT, new Date());

    return isValid(issueDate) ? issueDate : null;
  }

  get issueDateValidityOffsetInDays(): number {
    return this.args.userDocumentTemplateConfiguration?.issueDateValidityOffsetInDays ?? 0;
  }

  get expirationDate(): Date | null {
    const { issueDate, issueDateValidityOffsetInDays } = this;

    if (!(issueDate && issueDateValidityOffsetInDays)) return null;

    const expirationDate = addDays(issueDate, issueDateValidityOffsetInDays);
    const adjustedExpDate = this.timezoneAdjustedExpirationDate(expirationDate);

    const result = subSeconds(adjustedExpDate, 1);

    return isValid(result) ? result : null;
  }

  get expirationDateInTimeZone(): string | null {
    return this.expirationDate ? this.formatDateForTimeZone(this.expirationDate) : null;
  }

  get expirationTimeInTimeZone(): string | null {
    return this.expirationDate
      ? this.formatDateForTimeZone(this.expirationDate, 'MMM d, yyyy, h:mm:ss aa (zzzz)')
      : null;
  }

  get isExpired(): boolean {
    const { expirationDate } = this;

    if (!isValid(expirationDate)) return false;

    return isAfter(new Date(), expirationDate!);
  }

  /**
   * Account for customer being in a time zone (browser time zone) other than
   * the specified time zone (@timezone). Also, Daylight Saving Time.
   */
  timezoneAdjustedExpirationDate(expirationDate: Date): Date {
    const { timezone } = this.args;

    return timezone ? fromZonedTime(format(expirationDate, DATE_FORMAT), timezone) : expirationDate;
  }

  formatDateForTimeZone(date: Date, dateFormat = 'MMM d, yyyy'): string {
    const { timezone } = this.args;

    return timezone ? formatInTimeZone(date, timezone, dateFormat) : format(date, dateFormat);
  }

  @action
  onReset(): void {
    const { onReset, visitorDocument } = this.args;

    onReset?.(visitorDocument);
  }

  @action
  onFileSelected(templateAttachment: UserDocumentTemplateAttachmentModel, file: File): void {
    const { onFileSelected, visitorDocument } = this.args;
    onFileSelected?.(visitorDocument, templateAttachment, file);
  }

  @action
  onFilePreview(templateAttachment: UserDocumentTemplateAttachmentModel, src: string): void {
    const { onFilePreview, visitorDocument } = this.args;
    onFilePreview?.(visitorDocument, templateAttachment, src);
  }

  @action
  onInputFieldChange(inputField: AllInputFields): void {
    const { onInputFieldChange, visitorDocument } = this.args;

    onInputFieldChange?.(visitorDocument, inputField);
  }
}
