import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Report, VisualComplianceRpsResult } from 'garaje/models/approval-status';
import { reads } from 'macro-decorators';

export interface VisualComplianceDetailsComponentSignature {
  Args: {
    // The context in which the component is being used
    context: 'location' | 'property';
    reports?: Report[];
  };

  Element: HTMLUListElement;
}

export default class VisualComplianceDetailsComponent extends Component<VisualComplianceDetailsComponentSignature> {
  @service declare abilities: AbilitiesService;

  @tracked showVisualComplianceDetails: boolean = false;

  @reads('args.reports', []) reports!: Report[];

  get isDetailsVisible(): boolean {
    const { reports, tabularData } = this;

    return reports.some((r) => r['risk-country']) || tabularData.length > 0;
  }

  get canView(): boolean {
    const { abilities, context, reports: report } = this;

    if (abilities.can('review entry-approval', { context, report })) return true;
    if (abilities.can('view visual-compliance')) return true;

    return false;
  }

  get context(): VisualComplianceDetailsComponentSignature['Args']['context'] {
    return this.args.context ?? 'location';
  }

  get tabularData(): [string, string][][] {
    const [report] = this.reports;

    if (!(report && report['rps-results'])) return [];

    return report['rps-results'].map((result) => {
      const keys = <Array<keyof VisualComplianceRpsResult>>Object.keys(result);

      return keys.map((key: keyof VisualComplianceRpsResult): [string, string] => {
        return [`${key}`, `${result[key] || ''}`];
      });
    });
  }

  get alertLevel(): number {
    const [report] = this.reports;

    if (!(report && report['alert-level'])) return 0;

    return report['alert-level'];
  }

  keyTitle = (key: keyof VisualComplianceRpsResult): string => {
    return {
      alerttype: 'Alert type',
      category: 'Category',
      city: 'City',
      country: 'Country',
      ctrycode: 'Country code',
      enddate: 'End date',
      frc: 'FRC',
      frserve: 'FRSERVE',
      id: 'ID',
      list: 'List',
      name: 'Name',
      notes: 'Notes',
      startdate: 'Start date',
      state: 'State',
      street1: 'Street',
      street2: 'Street',
      type: 'Type',
    }[key];
  };
}
