import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type CohoService from 'garaje/services/coho';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { MessageType } from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';

type OccupancyMessage = {
  event:
    | 'showOccupancyGlobalSRModal'
    | 'closeOccupancyLocationSRModal'
    | 'showToaster'
    | 'showOccupancyCopyLinkModal'
    | 'closeOccupancyCopyLinkModal';
  type?: string; // optional, used when event is 'showToaster'
  msg?: string; // optional, used when event is 'showToaster'
  location_id?: string; // optional, used when event is 'showOccupancyCopyLinkModal'
};

export default class AnalyticsOccupancyIndexController extends Controller {
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare messageBus: MessageBusService;
  @service declare coho: CohoService;
  @service declare statsig: StatsigService;

  @tracked showModal = false;
  @tracked modalIframeUrl = '';
  @tracked showCopyLinkModal = false;
  @tracked loginRequired = false;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @action
  handleMessage(message: OccupancyMessage): void {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    if (message.event === 'showOccupancyGlobalSRModal') {
      this.modalIframeUrl = urlBuilder.embeddedOccupancyLocationReportsModalUrl(
        companyId,
        locationId,
        this.loginRequired,
      );
      this.showModal = true;
    } else if (message.event === 'closeOccupancyLocationSRModal') {
      this.showModal = false;
    } else if (message.event === 'showToaster') {
      const type = message.type as MessageType;
      const msg = message.msg || '';
      this.flashMessages.showAndHideFlash(type, msg);
    } else if (message.event === 'showOccupancyCopyLinkModal') {
      let copyUrl = window.location.href;
      if (message.location_id) {
        copyUrl += `?location_id=${message.location_id}`;
      }
      this.modalIframeUrl = urlBuilder.embeddedOccupancyCopyLinkModal(
        copyUrl,
        this.loginRequired,
        companyId,
        locationId,
      );
      this.showCopyLinkModal = true;
    } else if (message.event === 'closeOccupancyCopyLinkModal') {
      this.showCopyLinkModal = false;
    }
  }

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    if (!companyId || !locationId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId, locationId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    return urlBuilder.embeddedOccupancyLocationAnalyticsUrl(companyId, locationId, this.loginRequired);
  }

  async getSigmaIframeUrl(companyId: string, locationId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getLocationSigmaUrl(companyId, locationId, 'occupancy');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    this.coho.sendEvent(WorkplaceEventNames.VIEW_ANALYTICS_DASHBOARD, { product: APP.WORKPLACE });
    this.statsig.logEvent(`coho_${WorkplaceEventNames.VIEW_ANALYTICS_DASHBOARD}`, null, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
