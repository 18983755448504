import { set } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type UserDatum from 'garaje/models/user-datum';

export default class MappedField {
  declare fieldData: UserDatum | undefined;
  declare field?: string;
  declare isRequired: boolean;

  @tracked changeset?: UserDatum['changeset'];

  constructor({ fieldData, isRequired }: { fieldData: UserDatum | undefined; isRequired: boolean }) {
    this.fieldData = fieldData;
    this.changeset = fieldData?.changeset;
    this.field = fieldData?.field;
    this.isRequired = isRequired;
  }

  get value(): string | undefined {
    return this.changeset?.value;
  }

  set value(v: string) {
    if (!this.changeset) return;
    set(this.changeset, 'value', v);
  }

  get isEmptyAndRequired(): boolean {
    return this.isRequired && isEmpty(this.value);
  }
}
