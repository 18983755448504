import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Option {
  show: boolean;
}

interface HeaderSelectorComponentArgs {
  options: Option[];
  onUpdate?: (option: Option) => void;
}

export default class HeaderSelectorComponent extends Component<HeaderSelectorComponentArgs> {
  @action
  toggleHeaders(option: Option): void {
    const show = option.show;
    option.show = !show;
    this.args.onUpdate?.(option);
  }
}
