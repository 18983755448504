import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { BlocklistFilterSource } from 'garaje/utils/enums';
import { GLOBAL_ADMIN, LOCATION_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

import type { GetCanReviewOrReadBlocklistMatchModel } from './security';

const ALLOWED_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, ZONE_ADMIN];
const CAN_MANAGE_PROPERTY = [GLOBAL_ADMIN, ZONE_ADMIN];

export default class BlocklistAbility extends Ability {
  declare model?: GetCanReviewOrReadBlocklistMatchModel;

  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canApprove(): boolean {
    return this.canReview;
  }

  get canDeny(): boolean {
    return this.canReview;
  }

  get canConfigure(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(ALLOWED_ROLES, roleNames));
  }

  get canReview(): boolean {
    const { roleNames } = this.currentAdmin;
    const blocklistContacts = this.state.currentLocation?.blocklistContacts.slice() || [];
    const isSecurityContact = !!blocklistContacts.find((contact) => contact.id === this.currentAdmin.id);

    const canReview = isPresent(_intersection(ALLOWED_ROLES, roleNames)) || isSecurityContact;

    if (canReview && isPresent(this.model)) {
      const { context, report } = this.model || {};
      const blacklistFilterSource =
        context === 'property' ? BlocklistFilterSource.PROPERTY : BlocklistFilterSource.LOCATION;
      const matchingReports =
        report?.filter((report) => {
          const reportFilterSource = report.blacklistFilterSource ?? BlocklistFilterSource.LOCATION;
          return reportFilterSource === blacklistFilterSource;
        }) ?? [];
      return matchingReports.length > 0;
    }

    return canReview;
  }

  get canManageProperty(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_PROPERTY, roleNames));
  }
}
