"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkCore = void 0;
require("./$_StatsigGlobal");
const __StatsigGlobal_1 = require("./$_StatsigGlobal");
const Diagnostics_1 = require("./Diagnostics");
const Log_1 = require("./Log");
const NetworkConfig_1 = require("./NetworkConfig");
const SDKType_1 = require("./SDKType");
const SafeJs_1 = require("./SafeJs");
const SessionID_1 = require("./SessionID");
const StableID_1 = require("./StableID");
const StatsigMetadata_1 = require("./StatsigMetadata");
const VisibilityObserving_1 = require("./VisibilityObserving");
const DEFAULT_TIMEOUT_MS = 10000;
const RETRYABLE_CODES = new Set([408, 500, 502, 503, 504, 522, 524, 599]);
class NetworkCore {
    constructor(options, _emitter) {
        this._emitter = _emitter;
        this._timeout = DEFAULT_TIMEOUT_MS;
        this._netConfig = {};
        this._options = {};
        if (options) {
            this._options = options;
        }
        if (this._options.networkConfig) {
            this._netConfig = this._options.networkConfig;
        }
        if (this._netConfig.networkTimeoutMs) {
            this._timeout = this._netConfig.networkTimeoutMs;
        }
    }
    post(args) {
        return __awaiter(this, void 0, void 0, function* () {
            let body = yield this._getPopulatedBody(args);
            if (args.isStatsigEncodable) {
                body = this._attemptToEncodeString(args, body);
            }
            return this._sendRequest(Object.assign({ method: 'POST', body }, args));
        });
    }
    get(args) {
        return this._sendRequest(Object.assign({ method: 'GET' }, args));
    }
    isBeaconSupported() {
        return (typeof navigator !== 'undefined' &&
            typeof navigator.sendBeacon === 'function');
    }
    beacon(args) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!_ensureValidSdkKey(args)) {
                return false;
            }
            const body = yield this._getPopulatedBody(args);
            const url = yield this._getPopulatedURL(args);
            const nav = navigator;
            return nav.sendBeacon.bind(nav)(url, body);
        });
    }
    _sendRequest(args) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            if (!_ensureValidSdkKey(args)) {
                return null;
            }
            if (this._netConfig.preventAllNetworkTraffic) {
                return null;
            }
            const { method, body, retries, attempt } = args;
            const currentAttempt = attempt !== null && attempt !== void 0 ? attempt : 1;
            const controller = typeof AbortController !== 'undefined' ? new AbortController() : null;
            const handle = setTimeout(() => controller === null || controller === void 0 ? void 0 : controller.abort(`Timeout of ${this._timeout}ms expired.`), this._timeout);
            const url = yield this._getPopulatedURL(args);
            let response = null;
            const keepalive = (0, VisibilityObserving_1._isUnloading)();
            try {
                const config = {
                    method,
                    body,
                    headers: Object.assign({}, args.headers),
                    signal: controller === null || controller === void 0 ? void 0 : controller.signal,
                    priority: args.priority,
                    keepalive,
                };
                if (args.isInitialize) {
                    Diagnostics_1.Diagnostics._markInitNetworkReqStart(args.sdkKey, {
                        attempt: currentAttempt,
                    });
                }
                const func = (_a = this._netConfig.networkOverrideFunc) !== null && _a !== void 0 ? _a : fetch;
                response = yield func(url, config);
                clearTimeout(handle);
                if (!response.ok) {
                    const text = yield response.text().catch(() => 'No Text');
                    const err = new Error(`NetworkError: ${url} ${text}`);
                    err.name = 'NetworkError';
                    throw err;
                }
                const text = yield response.text();
                if (args.isInitialize) {
                    Diagnostics_1.Diagnostics._markInitNetworkReqEnd(args.sdkKey, Diagnostics_1.Diagnostics._getDiagnosticsData(response, currentAttempt, text));
                }
                return {
                    body: text,
                    code: response.status,
                };
            }
            catch (error) {
                const errorMessage = _getErrorMessage(controller, error);
                if (args.isInitialize) {
                    Diagnostics_1.Diagnostics._markInitNetworkReqEnd(args.sdkKey, Diagnostics_1.Diagnostics._getDiagnosticsData(response, currentAttempt, '', error));
                }
                if (!retries ||
                    currentAttempt > retries ||
                    !RETRYABLE_CODES.has((_b = response === null || response === void 0 ? void 0 : response.status) !== null && _b !== void 0 ? _b : 500)) {
                    (_c = this._emitter) === null || _c === void 0 ? void 0 : _c.call(this, { name: 'error', error });
                    Log_1.Log.error(`A networking error occured during ${method} request to ${url}.`, errorMessage, error);
                    return null;
                }
                return this._sendRequest(Object.assign(Object.assign({}, args), { retries: retries, attempt: currentAttempt + 1 }));
            }
        });
    }
    _getPopulatedURL(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = Object.assign({ [NetworkConfig_1.NetworkParam.SdkKey]: args.sdkKey, [NetworkConfig_1.NetworkParam.SdkType]: SDKType_1.SDKType._get(args.sdkKey), [NetworkConfig_1.NetworkParam.SdkVersion]: StatsigMetadata_1.SDK_VERSION, [NetworkConfig_1.NetworkParam.Time]: String(Date.now()), [NetworkConfig_1.NetworkParam.SessionID]: SessionID_1.SessionID.get(args.sdkKey) }, args.params);
            const query = Object.keys(params)
                .map((key) => {
                return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
            })
                .join('&');
            return `${args.url}${query ? `?${query}` : ''}`;
        });
    }
    _getPopulatedBody(args) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data, sdkKey } = args;
            const stableID = StableID_1.StableID.get(sdkKey);
            const sessionID = SessionID_1.SessionID.get(sdkKey);
            const sdkType = SDKType_1.SDKType._get(sdkKey);
            return JSON.stringify(Object.assign(Object.assign({}, data), { statsigMetadata: Object.assign(Object.assign({}, StatsigMetadata_1.StatsigMetadataProvider.get()), { stableID,
                    sessionID,
                    sdkType }) }));
        });
    }
    _attemptToEncodeString(args, input) {
        var _a, _b;
        const win = (0, SafeJs_1._getWindowSafe)();
        if (!args.isStatsigEncodable ||
            this._options.disableStatsigEncoding ||
            (0, __StatsigGlobal_1._getStatsigGlobalFlag)('no-encode') != null ||
            !(win === null || win === void 0 ? void 0 : win.btoa)) {
            return input;
        }
        try {
            const result = (_a = win.btoa(input).split('').reverse().join('')) !== null && _a !== void 0 ? _a : input;
            args.params = Object.assign(Object.assign({}, ((_b = args.params) !== null && _b !== void 0 ? _b : {})), { [NetworkConfig_1.NetworkParam.StatsigEncoded]: '1' });
            return result;
        }
        catch (_c) {
            Log_1.Log.warn('/initialize request encoding failed');
            return input;
        }
    }
}
exports.NetworkCore = NetworkCore;
const _ensureValidSdkKey = (args) => {
    if (!args.sdkKey) {
        Log_1.Log.warn('Unable to make request without an SDK key');
        return false;
    }
    return true;
};
function _getErrorMessage(controller, error) {
    if ((controller === null || controller === void 0 ? void 0 : controller.signal.aborted) &&
        typeof controller.signal.reason === 'string') {
        return controller.signal.reason;
    }
    if (typeof error === 'string') {
        return error;
    }
    if (error instanceof Error) {
        return `${error.name}: ${error.message}`;
    }
    return 'Unknown Error';
}
