import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type GlobalVisualComplianceConfigurationModel from 'garaje/models/global-visual-compliance-configuration';
import type LocationModel from 'garaje/models/location';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import type { RecordArray } from 'garaje/utils/type-utils';

type VisualComplianceConfiguration = GlobalVisualComplianceConfigurationModel | VisualComplianceConfigurationModel;
type AnyFlowModel = GlobalFlowModel | FlowModel;

export const MINIMUM_IPAD_VERSION_NEEDED_FOR_FULL_ADDRESS = '4.36.0';

interface VisualCompilanceConfigureComponentSignature {
  Args: {
    configuration: VisualComplianceConfiguration;
    location?: LocationModel; // Optional for "Global" Config
    flow: AnyFlowModel;
  };
}

export default class VisualCompilanceConfigureComponent extends Component<VisualCompilanceConfigureComponentSignature> {
  @service declare store: StoreService;

  ipadVersionRequiredForFullAddress = MINIMUM_IPAD_VERSION_NEEDED_FOR_FULL_ADDRESS;

  @tracked contactCount = 0;
  @tracked contactName = '';

  @isAppUpdateRequired('devices', MINIMUM_IPAD_VERSION_NEEDED_FOR_FULL_ADDRESS)
  isAppUpdateRequiredFullAddress!: boolean;

  get isLocalityChecked(): boolean {
    return this.isCityChecked || this.isAddressChecked;
  }

  get isCityChecked(): boolean {
    return this.args.configuration?.fieldsToCheck.includes('locality');
  }

  get isAddressChecked(): boolean {
    return this.args.configuration?.fieldsToCheck.includes('locality-address');
  }

  get isCompanyChecked(): boolean {
    return this.args.configuration?.fieldsToCheck.includes('company');
  }

  get devices(): RecordArray<DeviceModel> {
    return this.store.peekAll('device');
  }

  get showFullAddressIpadVersionWarning(): boolean {
    const { isAddressChecked, isAppUpdateRequiredFullAddress, devices } = this;

    if (!isAddressChecked) return false;

    return devices.length === 0 || isAppUpdateRequiredFullAddress;
  }

  constructor(owner: unknown, args: VisualCompilanceConfigureComponentSignature['Args']) {
    super(owner, args);
    void this.loadContactsTask.perform();
  }

  loadContactsTask = task(async (): Promise<void> => {
    const { location } = this.args;

    if (!location) return;

    const locationConfig = <VisualComplianceConfiguration>await location.visualComplianceConfiguration;

    if (!locationConfig?.contactIds[0]) return;

    const firstContact = await this.store.findRecord('user', locationConfig.contactIds[0]);

    this.contactCount = locationConfig.contactIds.length;
    this.contactName = firstContact.fullName;
  });

  @action
  toggleFlowField({ checked, name, value }: HTMLInputElement): void {
    const names = name.split(',');
    const fieldsToCheck = (this.args.configuration?.fieldsToCheck ?? []).filter((field) => !names.includes(field));

    this.args.configuration.fieldsToCheck = checked ? [...fieldsToCheck, value] : fieldsToCheck;
  }
}
