"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._getFullUserHash = exports._normalizeUser = void 0;
const Hashing_1 = require("./Hashing");
const Log_1 = require("./Log");
function _normalizeUser(original, options) {
    try {
        const copy = JSON.parse(JSON.stringify(original));
        if (options != null && options.environment != null) {
            copy.statsigEnvironment = options.environment;
        }
        return copy;
    }
    catch (error) {
        Log_1.Log.error('Failed to JSON.stringify user');
        return { statsigEnvironment: undefined };
    }
}
exports._normalizeUser = _normalizeUser;
function _getFullUserHash(user) {
    return user ? (0, Hashing_1._DJB2Object)(user) : null;
}
exports._getFullUserHash = _getFullUserHash;
