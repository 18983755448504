import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { dropTask, waitForProperty } from 'ember-concurrency';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type LocationBillingService from 'garaje/services/location-billing';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { APP, PLAN_LEVEL, IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { startingSubscriptionPlanId } from 'garaje/utils/starting-subscription-plan-id';
import { or } from 'macro-decorators';

export default class VisitorsStartTrialController extends Controller {
  @service('router') declare router: RouterService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare locationBilling: LocationBillingService;
  @service declare store: StoreService;
  @service declare impressions: ImpressionsService;

  @tracked transitionDone?: boolean;
  @tracked showBuyNowModal = false;
  @tracked showIntakeForm = false;
  @tracked hasSeenIntakeForm = false;
  @tracked signinPreviewModalClosed = false;
  @tracked shouldShowIpadDemo = false;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') canStartTrial!: boolean;

  get showSigninPreviewModal(): boolean {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      return !this.showIntakeForm && this.hasSeenIntakeForm && !this.signinPreviewModalClosed;
    }
    return false;
  }

  get showIpadDemo(): boolean {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      return !this.showIntakeForm && this.hasSeenIntakeForm && this.signinPreviewModalClosed && this.shouldShowIpadDemo;
    }
    return false;
  }

  @action
  async openIntakeForm(): Promise<void> {
    this.showIntakeForm = true;
    this.hasSeenIntakeForm = true;
    try {
      await this.startTrialTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  completeiPadDemo = dropTask(async () => {
    this.signinPreviewModalClosed = true;
    this.shouldShowIpadDemo = false;
    await this.transitionToARouteTask.perform();
  });

  // eslint-disable-next-line @typescript-eslint/require-await
  startiPadDemo = dropTask(async () => {
    this.signinPreviewModalClosed = true;
    this.shouldShowIpadDemo = true;
  });

  closeIntakeForm = dropTask(async () => {
    this.showIntakeForm = false;
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_SUBMITTED);
    if (!this.featureFlags.isEnabled('growth_ipad_demo')) {
      await this.transitionToARouteTask.perform();
    }
  });

  transitionToARouteTask = dropTask(async () => {
    this.store.query('homepage-group', {
      include:
        'homepage-item-group-mappings.homepage-item.setup-guide-items,homepage-item-group-mappings.homepage-item.marketing-items,homepage-item-group-mappings.homepage-item.helpdesk-items.helpdesk-questions,homepage-item-group-mappings.homepage-item.help-resources-items',
    });

    const transitionObj = this.router.transitionTo('visitors.entries');
    void transitionObj.then(() => {
      if (!this.isDestroyed && !this.isDestroying) {
        this.transitionDone = true;
      }
    });
    await waitForProperty(this, 'transitionDone', true);
  });

  /**
   * startTrialTask
   * Creates VR Subscription and reloads the global `state` service
   * @param  evt event being passed in
   */
  startTrialTask = dropTask(async (evt?: Event) => {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const { currentCompany } = this.state;

    try {
      if (this.featureFlags.isEnabled('locationBilling')) {
        // Create the VR Subscription Batch
        const subscriptionBatch = this.store.createRecord('subscription-batch', {
          company: currentCompany,
          products: [
            {
              'subscription-plan-id': startingSubscriptionPlanId({ app: APP.VISITORS, state: this.state }),
              trial: true,
            },
          ],
          status: 'pending',
        });
        await subscriptionBatch.save();
        await this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);
      } else {
        // Create VR Subscription
        const subscription = this.store.createRecord('subscription', {
          app: APP.VISITORS,
          plan: PLAN_LEVEL.PREMIUM,
          company: currentCompany,
        });
        await subscription.save();
        // Reload state service with the new subscription added
        await this.state.initSubscriptionStateTask.perform();
      }
      this.flashMessages.showAndHideFlash('success', 'Trial started!');

      this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: APP.VISITORS });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  get canBuyNow(): boolean {
    return this.featureFlags.isEnabled('visitors-buy-now');
  }

  @action
  openBuyNowModal(): void {
    this.showBuyNowModal = true;
    this.trackBuyNowCTAClicked();
  }

  @action
  trackBuyNowCTAViewed(): void {
    void this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.CTA_VIEWED);
  }

  @action
  trackBuyNowCTAClicked(): void {
    void this.impressions.postImpression.perform(IMPRESSION_NAMES.VISTORS_BUY_NOW.CTA_CLICKED);
  }
}
