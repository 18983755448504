import Component from '@glimmer/component';
import { alias, and } from 'macro-decorators';
import { get, set } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { localCopy } from 'tracked-toolbox';
import { defer } from 'rsvp';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { Changeset } from 'ember-changeset';
import { tracked } from '@glimmer/tracking';

const MINIMUM_IPAD_VERSION_NEEDED_FOR_INTEGRATIONS = '3.13.0';

/**
 * @param {Badge}           badge
 * @param {Boolean}         canConfigureBadgePrinting
 * @param {String}          companyName
 * @param {Config}          config
 * @param {Flow}            flow
 * @param {Function}        handleSaveBadge
 * @param {Boolean}         isDisabled
 * @param {Object}          logo
 * @param {Function}        redirectToEnvoy
 * @param {Function}        rollback
 * @param {Boolean}         showBadgeIntegrationMenus
 * @param {Array<UiHook>}   uiHooks
 * @param {Task}            updateAndSaveTask
 * @param {Array<Device>}   ipads
 * @param {Boolean}         isAppUpdateRequired
 */
export default class extends Component {
  customUiHooksOptions = [{ label: 'None', urn: null }];
  sampleBarcode = '/assets/images/badge/sample-barcode.png';
  sampleQrCode = '/assets/images/badge/sample-qr-code.png';

  @service currentAdmin;
  @service state;
  @service trial;
  @service currentLocation;

  @localCopy('args.updateAndSaveTask') updateAndSaveTask;
  @localCopy('args.badge') badge;
  @localCopy('args.flow') flow;
  @localCopy('args.handleSaveBadge') handleSaveBadge;
  @localCopy('args.uiHooks', []) uiHooks;
  @localCopy('args.ipads', []) ipads;

  @alias('state.vrSubscription') vrSubscription;
  @alias('state.currentCompany') currentCompany;
  @alias('badge.hasDirtyAttributes') isDirty;

  @and('flow.agreementPage.enabled', 'flow.agreementPage.optional') shouldShowNoNdaBadgeToggle;

  @isAppUpdateRequired('ipads', MINIMUM_IPAD_VERSION_NEEDED_FOR_INTEGRATIONS) _isAppUpdateRequired;

  @tracked badgeChangeset;

  get canAccessCustomBadge() {
    return !!this.state.features?.canAccessCustomBadge;
  }

  get timezone() {
    return get(this.currentLocation, 'timezone') ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get hasAccessToVisitorPhotos() {
    return !!this.state.features?.canAccessVisitorPhotos;
  }

  get isAppUpdateRequired() {
    const isAppUpdateRequired = this.args.isAppUpdateRequired ?? this._isAppUpdateRequired;

    return isAppUpdateRequired && this.isIntegrationContentConfigured;
  }

  get isIntegrationContentConfigured() {
    const { badgeChangeset } = this;

    let result = false;

    if (badgeChangeset && typeof badgeChangeset.eachAttribute === 'function') {
      this.args.badge.eachAttribute((name) => {
        // These attributes may contain the URN prefix
        const skipAttributes = ['css', 'defaultCss', 'defaultHtml', 'html'];

        if (result || skipAttributes.includes(name)) {
          return;
        }

        // Using includes to support future token usage
        result = `${get(badgeChangeset, name)}`.includes('HOOKS|TRIGGER_NAME:');
      });
    }

    return result;
  }

  get logoUrl() {
    if (get(this, 'flow.isGlobal')) {
      return this.args.logo || '';
    }

    return this.args.logo?.smallUrl || '';
  }

  get badgeVariables() {
    return {
      company: this.companyName,
    };
  }

  // return @companyName if defined, else fallback to currentCompany.name
  get companyName() {
    return this.args.companyName ?? get(this, 'currentCompany.name');
  }

  brandingOptions = [{ label: 'Company name', value: 'name' }];

  get extraFieldsOptions() {
    const customFields = [];

    if (this.flow.isGlobal) {
      customFields.push({
        label: 'Purpose of visit',
        value: `field Purpose of visit`,
      });
    } else {
      // config.localizedFields will included the localized version of purpose of visit
      const localizedFields = this.args.config?.localizedFields;
      if (localizedFields) {
        const povMeta = localizedFields.findBy('field', 'Purpose of visit');

        customFields.push({
          label: 'Purpose of visit',
          value: `field ${get(povMeta, 'localized')}`,
        });
      }
    }

    get(this, 'flow.signInFieldPage.signInFields')
      .toArray()
      .forEach(function (field) {
        if (get(field, 'isEmail')) {
          customFields.push({ label: 'Email', value: 'email' });
        } else if (get(field, 'isPhone')) {
          customFields.push({ label: 'Phone', value: 'phone' });
        } else if (get(field, 'isCustom')) {
          customFields.push({
            label: get(field, 'name'),
            value: `field ${get(field, 'name')}`,
          });
        }
      });

    return customFields;
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  constructor() {
    super(...arguments);
    this.resetChangeset();
  }

  @dropTask
  *updateFeature(model, value) {
    yield this.updateAndSaveTask.perform(model, 'enabled', value);
  }

  saveBadgeTask = dropTask(async () => {
    await this.updateAndSaveTask.perform(this.badgeChangeset);
  });

  savePhotoBadgeTask = dropTask(async () => {
    const { badge, badgeChangeset } = this;
    const enablingPhoto = !badge.showPhoto && badgeChangeset.showPhoto;

    if (enablingPhoto) {
      const photoPage = get(this, 'flow.photoPage');

      if (!get(photoPage, 'enabled')) {
        const confirmed = await this.showSavePhotoBadgeConfirmationTask.perform();

        if (!confirmed) {
          badgeChangeset.showPhoto = false;
        } else {
          set(photoPage, 'enabled', true);
          badge.showPhoto = true;
          const pp = await photoPage;
          await pp.save();
          await this.updateAndSaveTask.perform(badge);

          // if the only thing that has changed in the changeset is showPhoto, then
          // reset it to a pristine state with the new value
          if (this.badgeChangeset.changes.length === 1 && this.badgeChangeset.changes[0].key === 'showPhoto') {
            this.resetChangeset();
          }
        }
      }
    }
  });

  @dropTask
  showSavePhotoBadgeConfirmationTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  @dropTask
  *saveBadgeCSS(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    yield this.handleSaveBadge(this.args.badge);
  }

  resetChangeset() {
    if (this.badge) this.badgeChangeset = Changeset(this.badge);
  }
}
