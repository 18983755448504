import { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type SlideshowModel from 'garaje/models/slideshow';
import adapter from 'garaje/utils/decorators/adapter';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';
import PropagableChangesTracker from 'garaje/utils/decorators/propagable-changes-tracker';

import AbstractConfig from './abstract/abstract-config';
import type IntegrationModel from './integration';

interface LocalizedField {
  field: string;
  localized: string;
}

export enum HostSearchType {
  Name = 'name',
  Email = 'email',
}

export enum HostSearchDisplayOptions {
  Title = 'title',
  Photo = 'photo',
}

// This is the list of attributes with its humanize version that, when changed,
// can be propagable to other locations
const OVERWRITABLE_SETTINGS = {
  beenHereBefore: 'Returning visitor',
  selfSignOut: 'self-sign out',
  guestQrCodeEnabled: 'Touchless walk-ins',
  welcomeBackgroundColor: 'welcome background color',
  welcomeBackgroundColorEnabled: 'welcome background',
  welcomeImage: 'welcome image',
};

@PropagableChangesTracker
@OnboardingChangesTracker({
  taskName: 'loadLocationsTask',
  attributes: ['welcomeBackgroundColorEnabled', 'guestQrCodeEnabled'],
})
@adapter('v2')
class ConfigModel extends AbstractConfig {
  @belongsTo('employee', { async: true }) declare deliveryPerson: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  @hasMany('slideshow', { async: true }) declare slideshows: AsyncHasMany<SlideshowModel>;
  @hasMany('integration', { polymorphic: true, async: true }) declare integrations: AsyncHasMany<IntegrationModel>;

  @attr('boolean', { defaultValue: false }) declare beenHereBefore: boolean;
  @attr('boolean', { defaultValue: false }) declare facialRecognitionEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare selfSignOut: boolean;
  @attr('boolean', { defaultValue: false }) declare onlyShowSlideshow: boolean;
  @attr('boolean', { defaultValue: false }) declare acceptDeliveries: boolean;
  @attr('boolean', { defaultValue: false }) declare hasPhotoPage: boolean;
  @attr('boolean', { defaultValue: false }) declare guestQrCodeEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare legalNameValidationEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare passportEnabled: boolean;
  @attr('boolean', { defaultValue: true }) declare hostSearchAutoSuggestEnabled: boolean;
  @attr('array', { defaultValue: () => [HostSearchType.Name] }) declare hostSearchTypes: HostSearchType[];
  @attr('array', { defaultValue: () => [] }) declare hostSearchDisplayOptions: HostSearchDisplayOptions[];

  // ID scanning
  @attr('boolean', { defaultValue: false }) declare idScanningEnabled: boolean;

  // Static QR
  @attr('boolean', { defaultValue: false }) declare staticQrEnabled: boolean;

  /**
   * List of custom fields
   *
   * e.g.
   * [{
   *   field: "Field",
   *   localized: "Localized field"
   * }]
   */
  @attr('array', { defaultValue: () => [] }) declare localizedFields: LocalizedField[];

  static OVERWRITABLE_SETTINGS = OVERWRITABLE_SETTINGS;
}

export default ConfigModel;
