"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports._createLayerParameterExposure = exports._createConfigExposure = exports._createGateExposure = exports._isExposureEvent = void 0;
const CONFIG_EXPOSURE_NAME = 'statsig::config_exposure';
const GATE_EXPOSURE_NAME = 'statsig::gate_exposure';
const LAYER_EXPOSURE_NAME = 'statsig::layer_exposure';
const _createExposure = (eventName, user, details, metadata, secondaryExposures) => {
    if (details.bootstrapMetadata) {
        metadata['bootstrapMetadata'] = details.bootstrapMetadata;
    }
    return {
        eventName,
        user,
        value: null,
        metadata: _addEvaluationDetailsToMetadata(details, metadata),
        secondaryExposures,
        time: Date.now(),
    };
};
const _isExposureEvent = ({ eventName, }) => {
    return eventName === GATE_EXPOSURE_NAME || eventName === CONFIG_EXPOSURE_NAME;
};
exports._isExposureEvent = _isExposureEvent;
const _createGateExposure = (user, gate) => {
    var _a, _b;
    return _createExposure(GATE_EXPOSURE_NAME, user, gate.details, {
        gate: gate.name,
        gateValue: String(gate.value),
        ruleID: gate.ruleID,
    }, (_b = (_a = gate.__evaluation) === null || _a === void 0 ? void 0 : _a.secondary_exposures) !== null && _b !== void 0 ? _b : []);
};
exports._createGateExposure = _createGateExposure;
const _createConfigExposure = (user, config) => {
    var _a, _b;
    return _createExposure(CONFIG_EXPOSURE_NAME, user, config.details, {
        config: config.name,
        ruleID: config.ruleID,
    }, (_b = (_a = config.__evaluation) === null || _a === void 0 ? void 0 : _a.secondary_exposures) !== null && _b !== void 0 ? _b : []);
};
exports._createConfigExposure = _createConfigExposure;
const _createLayerParameterExposure = (user, layer, parameterName) => {
    var _a, _b, _c;
    const evaluation = layer.__evaluation;
    const isExplicit = ((_a = evaluation === null || evaluation === void 0 ? void 0 : evaluation.explicit_parameters) === null || _a === void 0 ? void 0 : _a.includes(parameterName)) === true;
    let allocatedExperiment = '';
    let secondaryExposures = (_b = evaluation === null || evaluation === void 0 ? void 0 : evaluation.undelegated_secondary_exposures) !== null && _b !== void 0 ? _b : [];
    if (isExplicit) {
        allocatedExperiment = (_c = evaluation.allocated_experiment_name) !== null && _c !== void 0 ? _c : '';
        secondaryExposures = evaluation.secondary_exposures;
    }
    return _createExposure(LAYER_EXPOSURE_NAME, user, layer.details, {
        config: layer.name,
        parameterName,
        ruleID: layer.ruleID,
        allocatedExperiment,
        isExplicitParameter: String(isExplicit),
    }, secondaryExposures);
};
exports._createLayerParameterExposure = _createLayerParameterExposure;
const _addEvaluationDetailsToMetadata = (details, metadata) => {
    metadata['reason'] = details.reason;
    if (details.lcut) {
        metadata['lcut'] = String(details.lcut);
    }
    if (details.receivedAt) {
        metadata['receivedAt'] = String(details.receivedAt);
    }
    return metadata;
};
