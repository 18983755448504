import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { timeout, all, enqueueTask, dropTask, restartableTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type EmployeeModel from 'garaje/models/employee';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type { Field } from 'garaje/models/location';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LoggerService from 'garaje/services/logger';
import type MessageBusService from 'garaje/services/message-bus';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { DEFAULT_FLOW_NAME, FlowType, NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import { Permission } from 'garaje/utils/ui-permissions';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { alias, and, equal, not, notEmpty, reads, or } from 'macro-decorators';
import moment from 'moment-timezone';
import { resolve, reject } from 'rsvp';
import { TrackedObject } from 'tracked-built-ins';

import type { VisitorsInvitesIndexRouteModel } from './route';

export const PRIMARY_HEADERS = [
  { name: 'Name', sort: 'full_name' },
  { name: 'Entry status', componentName: 'custom-column' },
  { name: 'Purpose Of Visit', sort: 'flows.name', componentName: 'custom-column' },
  { name: 'Invited By', componentName: 'custom-column' },
];

export const OPTIONAL_HEADERS = [
  { name: 'Employee Registration', componentName: 'employee-registration-column' },
  { name: 'Group Name', componentName: 'custom-column', sort: 'bulk_invites.group_name' },
  { name: 'NDA', componentName: 'nda-column' },
  { name: 'Blacklist', componentName: 'blacklist-column' },
  { name: 'Been Here Before?', componentName: 'been-here-before-column' },
  { name: 'Registration complete', componentName: 'custom-column' },
  { name: 'Private Notes', componentName: 'private-notes-column' },
  { name: 'Due At', sort: 'expected_arrival_time', componentName: 'due-at-column' },
  { name: 'Connect Notification', componentName: 'connect-notification-column' },
  { name: 'Shared Notes', componentName: 'shared-notes-column' },
  { name: 'Arrived At', componentName: 'arrived-at-column' },
  { name: 'Signed In', componentName: 'signed-in-column' },
  { name: 'Desk', componentName: 'custom-column' },
];

// Require at least this many characters in the search field to automatically search in response to input.
// (Users can explicitly press "Enter" to perform a search with a shorter query).
const MIN_SEARCH_LENGTH = 3;

// Use this value in place of the `name` attribute for employee registration flowsl.
// This is needed because the real API returns the GLOBAL employee registration flow in the
// list of flows for a LOCATION, and the GLOBAL flow can be renamed (but the location flow can't),
// but the LOCATION flow name is used when performing the filtering.
const EMPLOYEE_SCREENING_FLOW_NAME_OVERRIDE = 'Employee registration';

interface InvitesFilter {
  employee?: string;
  location?: string;
  scope?: string;
  status?: string;
  date?: string;
  query?: string;
  visitor_type?: string | string[];
  approval_status?: string;
}
interface FilterOption {
  name: string;
  filter: string;
  scope?: string;
  employee?: EmployeeModel;
}

export interface InvitesDashboardField extends Field {
  sort?: string;
  show?: boolean;
}

export default class VisitorsInvitesIndexController extends Controller {
  declare model: VisitorsInvitesIndexRouteModel;

  @service declare abilities: AbilitiesService;
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentLocation: CurrentLocationService; // Needed for `printer` CP
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare logger: LoggerService;
  @service declare messageBus: MessageBusService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare store: StoreService;

  minSearchLength = MIN_SEARCH_LENGTH;

  queryParams = [
    'date',
    'query',
    'filter',
    'sort',
    { selectedFlows: { as: 'visitor_types' } },
    { selectedFlow: { as: 'visitor_type' } }, // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  ];
  @tracked query = '';
  @tracked _searchQuery = ''; // internal state to hold entered search query before updating this.query (which updates query param & performs search)
  @tracked filter = '';
  @tracked sort = 'expected_arrival_time';
  @tracked selectedFlows = '';
  @tracked selectedFlow = ''; // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  @tracked page = 1;
  @tracked limit = 0;

  @tracked exportIframeUrl = '';

  @tracked showCount = true; // flag to deal with model > modelTask > afterModel > loadInvites causing flash of incorrect count
  @tracked invitesCount = 0;
  @tracked date = '';
  @tracked showExportModal = false;

  @tracked sortProperties = ['expectedArrivalTime'];
  @tracked mobileCalendarVisible = false;
  @tracked mobileEntriesActive = false;
  @tracked isShowingDeleteConfirmation = false;

  @tracked bosses: EmployeeModel[] = [];
  @tracked selectedInvites: InviteModel[] = [];
  @tracked invitesToDelete: InviteModel[] = [];
  @tracked datesWithInvites = [];
  @tracked hasMultiLocationInviteSelected = false;
  @tracked inviteDashboardFieldsByLocationId: Record<string, Field[]> = new TrackedObject();

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @alias('currentAdmin.isEmployee') isEmployee!: CurrentAdminService['isEmployee'];
  @alias('model.currentLocation.preRegistrationEnabled')
  preRegistrationEnabled!: LocationModel['preRegistrationEnabled'];
  @and('currentLocation.hasAgreementPage', 'state.vrSubscription.hasPresignNda') ndaEnabled!: boolean;
  @alias('state.vrSubscription.canAccessBlocklist') canAccessBlocklist!: boolean;
  @equal('dateWithDefault', moment().format('YYYY-MM-DD')) isToday!: boolean;
  @reads('currentLocation.printer.enabled') canPrintBadges!: PrinterModel['enabled'];
  @not('ndaEnabled') ndaDisabled!: boolean;
  @not('currentLocation.config.beenHereBefore') beenHereBeforeDisabled!: boolean;
  @notEmpty('query') showFullDate!: boolean;
  @notEmpty('query') showClearButton!: boolean;

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get searchQuery(): string {
    return this._searchQuery || this.query;
  }

  set searchQuery(query: string) {
    this._searchQuery = query;
  }

  get canViewBlocklist(): boolean {
    return (
      Boolean(this.state.currentLocation?.blocklistEnabled) &&
      this.canAccessBlocklist &&
      this.abilities.can('review blocklist')
    );
  }

  get locationIsConnectedToProperty(): boolean {
    return this.model?.connectedTenants.toArray().length > 0;
  }

  get isTodayOrAfter(): boolean {
    return moment(this.dateWithDefault).isSameOrAfter(moment(), 'day');
  }

  get isAfterToday(): boolean {
    return moment(this.dateWithDefault).isAfter(moment(), 'day');
  }

  get sortField(): string {
    return this.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.sort.startsWith('-') ? 'desc' : 'asc';
  }

  get dateWithDefault(): string {
    return this.date || moment().format('YYYY-MM-DD');
  }

  get isSelectAllIndeterminate(): boolean {
    return this.selectedInvites.length > 0 && this.selectedInvites.length !== this.model.invites.length;
  }

  get canSelectMultipleInvites(): boolean {
    return (
      this.abilities.can('view all invites') ||
      this.abilities.can('edit invites') ||
      this.abilities.can('delete invites')
    );
  }

  get optionalHeaders(): InvitesDashboardField[] {
    const removeHeaders: Record<string, boolean> = {
      'Been Here Before?': this.beenHereBeforeDisabled,
      'Connect Notification': !this.locationIsConnectedToProperty,
      'Shared Notes': !this.locationIsConnectedToProperty,
      'Arrived At': !this.locationIsConnectedToProperty,
      Blacklist: !this.canViewBlocklist,
      NDA: this.ndaDisabled,
      Desk: this.abilities.cannot('see invite log for desk'),
    };
    return OPTIONAL_HEADERS.filter(({ name }) => !removeHeaders[name]);
  }

  get walkinFlow(): FlowModel | undefined {
    return this.currentLocation.location.flows.findBy('type', FlowType.PROPERTY_WALKUP);
  }

  get flowOptions(): NativeArray<{ name: string }> {
    const options = [{ name: 'All visitor types' }];

    if (this.currentLocation.location.employeeScreeningEnabled) {
      options.push({ name: DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING });
    }

    const { walkinFlow } = this;
    if (this.locationIsConnectedToProperty && walkinFlow) options.push(walkinFlow);

    A(
      this.currentLocation.location.flows.filter(
        ({ employeeCentric, type, name }) =>
          !(<string[]>NON_ASSIGNABLE_FLOWS).includes(type) && !employeeCentric && name,
      ),
    )
      .sortBy('name')
      .forEach((flow) => options.push(flow));
    return A(options.filter(Boolean)).uniqBy('name');
  }

  get selectedFlowModels(): FlowModel[] {
    const ids = this.selectedFlows.split(',').filter((maybeId) => maybeId !== '');
    return ids.map((id) => this.store.peekRecord('flow', id)).filter((maybeFlow) => !!maybeFlow);
  }

  get signInFieldPages(): VisitorsInvitesIndexRouteModel['signInFieldPages'] {
    return this.model.signInFieldPages;
  }

  get customHeaders(): InvitesDashboardField[] {
    return A(this.model.customFields.compact().reduce<SignInFieldModel[]>((acc, val) => acc.concat(val), []))
      .uniqBy('name')
      .map((customField) => {
        return {
          name: customField.label,
          componentName: 'custom-column',
        };
      })
      .sort((a, b) => {
        if (a.name === 'Host') return -1;
        if (b.name === 'Host') return 1;
        return 0; // Sort the array so that Host comes first, leave the other fields untouched
      });
  }

  get inviteDashboardFields(): Field[] {
    const fields = this.inviteDashboardFieldsByLocationId[this.currentLocation.location.id] ?? [];
    if (this.selectedFlow.toLowerCase() != 'employee registration') return fields;
    return [...fields, { name: 'Employee Registration', componentName: 'employee-registration-column' }];
  }

  get fieldOptions(): InvitesDashboardField[] {
    return [...PRIMARY_HEADERS, ...this.customHeaders, ...this.optionalHeaders].map(
      ({ name, sort, componentName }) => ({
        name,
        componentName,
        show: name === 'Name' ? true : isPresent(this.inviteDashboardFields.find((field) => field.name === name)),
        disabled:
          name === 'Name'
            ? true
            : (() =>
                this.selectedFlow.toLowerCase() === 'employee registration' &&
                name.toLowerCase() === 'employee registration')(),
        sort,
      }),
    );
  }

  approveInviteTask = enqueueTask(async (invite: InviteModel) => {
    try {
      await invite.approveInvite();
      await invite.reload();

      let additionalFlashMessageText;
      if (!invite.preregistrationComplete) {
        if (invite.approved) {
          additionalFlashMessageText = 'Invitation sent!';
        } else if (invite.needsPropertyApprovalReview) {
          // only flash this message  if a report with a property based source is present
          additionalFlashMessageText =
            'Additional approval from the property manager is required before the invitation is sent.';
        }
      }

      this.flashMessages.showAndHideFlash('success', 'Access approved', additionalFlashMessageText);
      const sourcesForApprovalReviewMetrics = (invite.approvalStatus?.failedReport || []).reduce(
        (sources, report) => ({ ...sources, [report.source]: true }),
        {},
      );
      this.metrics.trackEvent('Dashboard Invite - Reviewed', {
        action: 'approve',
        invite_id: invite.id,
        source: 'Invite Log',
        ...sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving invite', parseErrorForDisplay(error));
    } finally {
      const currentLocation = this.currentLocation.location;
      if (currentLocation.capacityLimitEnabled) {
        await fetchCapacity(this.store, currentLocation, invite.expectedArrivalTime);
      }
    }
  });

  deleteInvitesTask = enqueueTask(
    {
      maxConcurrency: 10,
    },
    async () => {
      try {
        const invitesCount = this.invitesCount;
        const deletedInvites = await all(this.invitesToDelete.map((invite) => invite.destroyRecord()));
        deletedInvites.forEach((deletedInvite) => this.removeInviteFromList(deletedInvite));
        this.invitesCount = invitesCount - deletedInvites.length;
        const successMessage = `Successfully deleted ${pluralize(deletedInvites.length, 'invite')}`;
        this.flashMessages.showAndHideFlash('success', successMessage);
        this.metrics.trackEvent('Invites Bulk Delete', { invite_count: deletedInvites.length });
        this.hasMultiLocationInviteSelected = false;
      } catch (e) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
      await this.refreshLocationsCapacity();
    },
  );

  get filterOptions(): FilterOption[] {
    const options = [];
    const myInvites = {
      name: 'My Invites',
      filter: 'mine',
      scope: 'mine',
    };
    const allInvites = { name: 'All Invites', filter: '' };
    const employee = this.currentAdmin.employee;

    options.push(myInvites);

    if (employee) {
      this.bosses.forEach(function (boss) {
        options.push({
          name: `${boss.name} Invites`,
          filter: boss.id,
          employee: boss,
        });
      });
    }

    const showAllOption = this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_INVITE_READ);

    if (showAllOption) {
      options.unshift(allInvites);
    }

    options.push(
      { name: 'Signed In', filter: 'signed-in' },
      { name: 'Signed Out', filter: 'signed-out' },
      { name: 'No Show', filter: 'no-show' },
    );

    return options;
  }

  get selected(): FilterOption | undefined {
    return this.filterOptions.find((option) => option.filter === this.filter);
  }
  set selected(value: FilterOption | undefined) {
    // @ts-ignore
    return value;
  }

  get hasMorePages(): boolean {
    return this.page * this.limit <= this.invitesCount;
  }

  /*
   Instance of "moment" representing the string in the property date.

   This value is used to render the envoy-calendar, and do operations
   like next and previous day.
   */
  get selectedDate(): moment.Moment {
    return moment(this.dateWithDefault, 'YYYY-MM-DD');
  }

  set selectedDate(value: moment.Moment) {
    this.date = value.format('YYYY-MM-DD');
  }

  get allInvitesAreSelected(): boolean {
    return this.selectedInvites.length === this.model.invites.length;
  }

  get menuHeight(): string {
    return `${document.querySelector('.invites-header')!.clientHeight + 18}px`;
  }

  get reviewableInvites(): InviteModel[] {
    const loadedForReview = this.loadReviewableInvitesTask.lastSuccessful?.value || [];
    const invites = this.model?.invites || [];
    const loadedInvites = A<InviteModel>().pushObjects(A(loadedForReview)).pushObjects(invites).uniqBy('id');

    return loadedInvites.filter((invite) => {
      return invite.needsLocationApprovalReview;
    });
  }

  get locationIsDisabled(): boolean {
    return this.state.currentLocation.disabled || this.state.currentLocation.disabledToEmployeesAt !== null;
  }

  @action
  toggleDashboardField({ name, componentName, show }: InvitesDashboardField): void {
    // componentName is no longer used, but the backend is still validating
    let inviteDashboardFields = this.inviteDashboardFields;
    if (show) {
      inviteDashboardFields.push({ name, componentName });
    } else {
      inviteDashboardFields = inviteDashboardFields.filter((field) => field.name !== name);
    }
    this.currentLocation.location.inviteDashboardFields = inviteDashboardFields;
    this.inviteDashboardFieldsByLocationId[this.currentLocation.location.id] = inviteDashboardFields;
    void this.currentLocation.location.save();
  }

  @action
  showModal(): void {
    this.messageBus.trigger('embedded-app-message', { event: 'showInviteExportModal' });
  }

  @action
  closeModal(): void {
    this.showExportModal = false;
  }

  async handleMessage(message: { event: string }): Promise<void> {
    if (message.event === 'showInviteExportModal') {
      const { currentCompany } = this.state;
      let selectedFlowId: string | undefined;
      let modalLabel;
      const locationId = this.currentLocation.location.id;
      const defaultEndDate = moment().format('YYYY-MM-DD');
      const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
      const flows = await this.state.loadFlows({
        includePropertyFlows: this.locationIsConnectedToProperty,
        locationId,
        reload: false,
      });

      if (this.selectedFlow === '') {
        selectedFlowId = A(flows).mapBy('id').join(',');
        modalLabel = 'All visitor types';
      } else {
        if (this.selectedFlow === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING) {
          selectedFlowId = flows.find((flow) => flow.type === FlowType.EMPLOYEE_SCREENING)?.id;
        } else {
          selectedFlowId = flows.find((flow) => flow.name === this.selectedFlow)?.id;
        }
        modalLabel = this.selectedFlow;
      }
      this.exportIframeUrl = urlBuilder.embeddedInviteExportModalUrl(
        currentCompany.id,
        locationId,
        selectedFlowId,
        defaultStartDate,
        defaultEndDate,
        modalLabel,
      );
      this.showExportModal = true;
    } else if (message.event === 'closeExportModal') {
      this.closeModal();
    }
  }

  @action
  selectedDidChange(selected: InviteModel[]): void {
    this.selectedInvites = selected;
  }

  @action
  clearSelectedInvites(): void {
    this.selectedInvites = [];
  }

  // We currently manage the list of invites manually so have to take care of this
  // Not sure if there is a better solution
  removeInviteFromList(invite: InviteModel): void {
    this.model.invites.removeObject(invite);

    this.selectedInvites = this.selectedInvites.filter((i) => invite !== i);
  }

  @action
  preprintBadge(invite: InviteModel, printer: PrinterModel | null | undefined = null): Promise<unknown> {
    const invites = (<InviteModel[]>[]).concat(invite);

    printer = printer || this.model.printers.firstObject;

    if (printer) {
      const data = invites.map((invite) => {
        // invites with an entry signInTime are printed as an entry instead of an invite
        // because the user may have editted the badge info on the entry after the invite was created
        // eslint-disable-next-line ember/no-get
        const hasEntry = get(invite, 'entry.signInTime');
        return {
          type: hasEntry ? 'entries' : 'invites',
          // eslint-disable-next-line ember/no-get
          id: hasEntry ? <string>get(invite, 'entry.id') : invite.id,
        };
      });
      return printer
        .reprintBadge({ data })
        .then((response) => {
          const succeeded = response.data.filter((r) => r.attributes.result === 'success');
          // We support a success message and error message, so we display each one based on API response.
          if (succeeded.length > 0) {
            const count = succeeded.length;
            let msg = 'Printing badge!';
            if (count > 1) {
              msg = `Printing ${pluralize(count, 'badge')}!`;
            }
            this.flashMessages.showAndHideFlash('success', msg, '', true);
            this.metrics.trackEvent('Viewed Flash Message', {
              type: 'success',
              message_title: msg,
              message_codes: [],
            });
          }
          const errored = response.data.filter((r) => r.attributes.result === 'error');
          if (errored.length) {
            const msg = `Cannot print ${pluralize(errored.length, 'badge', { withoutCount: true })}`;
            const componentName = 'flash-message/printer-error';
            this.flashMessages.showFlashComponent('error', msg, componentName, response);
            const statuses = A(errored.reduce<string[]>((acc, e) => acc.concat(e.attributes.statuses), [])).uniq();
            this.metrics.trackEvent('Viewed Flash Message', {
              type: 'error',
              message_title: msg,
              message_codes: statuses,
            });
          }
        })
        .catch((error: string) => {
          this.flashMessages.showFlash('error', error);
        });
    } else {
      return reject('There is no printer available.').catch((error: string) =>
        this.flashMessages.showFlash('error', error),
      );
    }
  }

  @action
  deleteInvites(invites: InviteModel[]): void {
    if (!Array.isArray(invites)) {
      invites = [invites];
    }
    this.isShowingDeleteConfirmation = true;
    this.invitesToDelete = invites;
    this.hasMultiLocationInviteSelected = this.invitesToDelete.some((invite) => {
      // eslint-disable-next-line ember/no-get
      return get(invite, 'childInviteLocations.length') || get(invite, 'parentInviteContext.content');
    });
  }

  @action
  closeDeleteConfirmationModal(): void {
    this.isShowingDeleteConfirmation = false;
    this.invitesToDelete = [];
    this.hasMultiLocationInviteSelected = false;
  }

  get visitorTypeFilter(): string | string[] {
    let visitorTypeFilter: string | string[] = this.selectedFlow;
    if (this.featureFlags.isEnabled('visitors-filter-logs-by-multiple-flows')) {
      if (this.selectedFlows === '') {
        visitorTypeFilter = [];
      } else {
        const selectedFlowIds = this.selectedFlows.split(',');
        visitorTypeFilter = <string[]>selectedFlowIds
          .map((flowId) => {
            const flow = this.store.peekRecord('flow', flowId);
            // If this flow is an employee registration flow, always send its name to the API as
            // "Employee registration", regardless of the value we have here.
            // This is needed because the real API returns the GLOBAL employee registration flow in the
            // list of flows for a LOCATION, and the GLOBAL flow can be renamed (but the location flow can't),
            // but the LOCATION flow name is used when performing the filtering.
            if (flow?.type === FlowType.EMPLOYEE_SCREENING) {
              return EMPLOYEE_SCREENING_FLOW_NAME_OVERRIDE;
            }
            return flow?.name;
          })
          .filter((maybeFlowName) => !!maybeFlowName); // skip any empty flow names (which might happen if an invalid ID was present in the list)
      }
    }
    return visitorTypeFilter;
  }

  loadInvites = dropTask(async () => {
    try {
      this.page++;

      const { limit } = this;
      const offset = (this.page - 1) * limit;

      const invites = await this._loadInvitesTask.perform(offset, limit, {
        location: this.currentLocation.location.id,
        date: this.dateWithDefault,
        query: this.query,
        visitor_type: this.visitorTypeFilter,
      });

      this.invitesCount = invites!.meta?.total;
      this.showCount = true;

      this.model.invites.pushObjects(A(invites!.toArray()));
      // A side effect of manually managing the entries in our model
      // is that pubnub will occasionally resolve in a way that results
      // in duplicate entires in the model.
      //
      // Here we explicitly dedup the model. A better long term solution
      // will involve a larger refactor.
      // @ts-ignore
      set(this.model, 'invites', this.model.invites.uniqBy('id'));
    } catch (_e) {
      this.page--;
    }
  });

  loadReviewableInvitesTask = dropTask(async (): Promise<InviteModel[] | void> => {
    try {
      // If there is a chance this user may be able to review an invite,
      // try to fetch invites with an approval status of "review"
      if (!(this.isTodayOrAfter && this.abilities.can('review invites'))) return [];

      const { limit } = this;
      const offset = 0;

      const invites = await this._loadInvitesTask.perform(offset, limit, {
        location: this.model.currentLocation.id,
        date: this.dateWithDefault,
        query: this.query,
        visitor_type: this.visitorTypeFilter,
        approval_status: 'review',
      });

      return invites!.toArray();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
    }
  });

  _loadInvitesTask = enqueueTask(
    async (
      offset: number = 0,
      limit: number = this.limit,
      filter: InvitesFilter = {},
    ): Promise<PaginatedRecordArray<InviteModel> | undefined> => {
      try {
        if (this.selected && this.selected.employee) {
          filter.employee = this.selected.employee.id;
        } else if (this.selected && this.selected.scope) {
          filter.scope = this.selected.scope;
        } else if (this.filter) {
          filter.status = this.filter;
        }

        const include = ['platform-jobs'];

        if (this.locationIsConnectedToProperty) include.push('multi-tenancy-visitor-notification-logs');

        const inviteParams = {
          filter,
          page: { offset, limit },
          sort: this.sort,
          include: include.join(),
        };
        const invites = <PaginatedRecordArray<InviteModel>>await this.store.query('invite', inviteParams);

        if (this.abilities.can('see invite log for desk') && !!invites.length) {
          try {
            await this.store.query('reservation', {
              filter: {
                'invite-id': invites.mapBy('id').join(','),
              },
              include: 'desk',
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error fetching reservations', e);
          }
        }

        return invites;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log({ e });
        return undefined;
      }
    },
  );

  _loadMore(): Promise<void> | void {
    // Because of the way loadMore works -- there are scenarios where
    // this can be called if destroyed
    if (this.isDestroyed) {
      return;
    }

    if (this.hasMorePages) {
      return this.loadInvites.perform();
    } else {
      return resolve();
    }
  }

  @action
  loadMore(): Promise<void> | void {
    return this._loadMore();
  }

  @action
  searchInvites(event: Event): void {
    event.preventDefault();
    if (this.query !== this.searchQuery) {
      void this.mutateQuery.perform(this.searchQuery, true);
    }
  }

  mutateQuery = restartableTask(async (email: string, skipLengthCheck: boolean = false) => {
    this.searchQuery = email;

    // If input emptied by user, clear query
    if (!email) {
      this.clearSearch();

      return;
    }

    await timeout(zft(500));

    if (skipLengthCheck || email.length >= this.minSearchLength) {
      this.showCount = false;
      this.query = email;

      this.metrics.trackEvent('Invites Searched', { search_value: this.query });
    }
  });

  // DEPRECATED - remove once "visitors-filter-logs-by-multiple-flows" feature flag is 100% rolled out
  @action
  selectFlow(flow: FlowModel): void {
    const name = isPresent(flow.id) || flow.name === DEFAULT_FLOW_NAME.EMPLOYEE_SCREENING ? flow.name : '';
    this.selectedFlow = name;
  }

  @action
  selectFlows(flows: FlowModel[]): void {
    this.selectedFlows = flows.map((flow) => flow.id).join(',');
  }

  @action
  selectFilter(option: FilterOption): void {
    this.selected = option;
    this.filter = option.filter;
    this.metrics.trackEvent('Invites Filtered', { filter_name: option.name });
  }

  loadBosses = dropTask(async (): Promise<void> => {
    try {
      const employee = this.currentAdmin.employee;

      if (!employee) return;

      const employeeBosses = await employee.bosses;

      // prevent self bossing.
      const bosses = employeeBosses.filter((boss) => !boss.deleted && boss.id !== employee.id);

      this.bosses = bosses;
    } catch (e) {
      this.logger.error(<Error>e);
    }
  });

  refreshLocationsCapacity(): Promise<unknown> | void {
    const { currentLocation } = this.state;
    if (currentLocation?.capacityLimitEnabled) {
      return fetchCapacity(this.store, currentLocation, this.model.date);
    }
  }

  resetSearchQueries(): void {
    this.query = '';
    this.searchQuery = '';
  }

  @action
  clearSearch(): void {
    // If search term was too short, keep the count visible
    this.showCount = !this.query;
    this.selectedFlow = '';
    this.resetSearchQueries();
  }

  @action
  chooseToday(): void {
    this.resetSearchQueries();
    this.selectedDate = moment();
  }

  @action
  trackGoToToday(): void {
    this.metrics.trackEvent('Invites Date Changed', {
      date_nav_method: 'go_to_today',
      date_selected: moment().toISOString(),
    });
  }

  @action
  didSelectDate(date: moment.Moment): void {
    this.selectedDate = date;
    this.metrics.trackEvent('Invites Date Changed', {
      date_nav_method: 'calendar_selection',
      date_selected: date.toISOString(),
    });
  }

  @action
  goBack(e: Event): void {
    if (e) {
      e.preventDefault();
    }
    this.resetSearchQueries();
    const date = moment(this.selectedDate);

    this.selectedDate = date.subtract(1, 'day');
    this.metrics.trackEvent('Invites Date Changed', {
      date_nav_method: 'left_arrow',
      date_selected: this.selectedDate.toISOString(),
    });
  }

  @action
  goForward(e: Event): void {
    if (e) {
      e.preventDefault();
    }
    this.resetSearchQueries();
    const date = moment(this.selectedDate);

    this.selectedDate = date.add(1, 'day');
    this.metrics.trackEvent('Invites Date Changed', {
      date_nav_method: 'right_arrow',
      date_selected: this.selectedDate.toISOString(),
    });
  }

  @action
  selectAllInvites(): void {
    this.selectedInvites = [...this.model.invites.toArray()];
  }

  @action
  deselectAllInvites(): void {
    this.clearSelectedInvites();
  }

  @action
  toggleCalendarVisible(): void {
    this.mobileCalendarVisible = !this.mobileCalendarVisible;
  }

  @action
  sortInvites(field: string, direction: string): void {
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;

    this.metrics.trackEvent('Invites Sorted', { sort_field_name: field });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
  interface Registry {
    'visitors.invites.index': VisitorsInvitesIndexController;
  }
}
