export interface BinaryDownloadModalOption {
  value: string;
  name: string;
}

export interface DiplomatAblePluginOption {
  id: string;
  name: string;
}

export const OPERATING_SYSTEM_OPTIONS: BinaryDownloadModalOption[] = [
  { value: 'linux', name: 'Linux' },
  { value: 'windows', name: 'Windows' },
];

export const PLATFORM_ARCHITECTURE_OPTIONS: BinaryDownloadModalOption[] = [
  { value: 'amd64', name: 'AMD64' },
  { value: 'arm', name: 'ARM' },
  { value: '386', name: '386' },
];

export const DIPLOMAT_ABLE_PLUGIN_OPTIONS: DiplomatAblePluginOption[] = [
  { id: 'f03ff630-d6be-4f0d-99d9-ecf810a0a5d3', name: 'LenelS2 Netbox' },
];
