import gql from 'graphql-tag';

export default gql`
  query AnnouncementAvailableNotificationChannelsQuery($locationId: ID!) {
    featureConfig {
      communications(locationId: $locationId) {
        notificationChannels {
          availableChannels
        }
      }
    }
  }
`;
