import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { arrayToSentence } from 'garaje/helpers/array-to-sentence';
import lookupValidator from 'ember-changeset-validations';
import { service } from '@ember/service';
import { all, dropTask, task } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import { tracked } from '@glimmer/tracking';
import { alias, equal, reads, notEmpty } from 'macro-decorators';
import config from 'garaje/config/environment';
import EmployeeValidations from 'garaje/validations/employee';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';

export default class LocationOverviewEmployeesDirectoryEmployeeController extends Controller {
  @service abilities;
  @service flashMessages;
  @service state;
  @service currentLocation;
  @service featureFlags;
  @service skinnyLocations;
  @service router;
  @service employeeDirectory;
  @service coho;
  @service statsig;

  @tracked showDeleteEmployeeModal = false;
  @tracked showDeleteAllLocationsModal = false;
  @tracked changeset;
  @tracked isEditModeEnabled = false;

  @alias('state.currentCompany') currentCompany;
  @alias('state.vrSubscription') vrSubscription;
  @alias('skinnyLocations.currentCompanyLocations') locations;
  @alias('skinnyLocations.manageableByCurrentAdmin') manageableLocations;
  @reads('changeset.isDirty') hasDirtyAttributes;
  @equal('currentLocation.location.employeesCsvUploadStatus', 'in_progress') employeeUploadInProgress;
  @notEmpty('currentCompany.scimIntegration.content') isScim;

  @(employeesSearcherTask().restartable())
  searchEmployeesTask;

  blankLocationOption = { id: null, name: '' };

  get isEmployeeEditable() {
    return !(this.isReadOnly || this.model.employee.deleted);
  }

  get isFieldDisabled() {
    return this.isReadOnly || !this.isEditModeEnabled || this.isSynced;
  }

  get isReadOnly() {
    return this.isGlobalOverview
      ? this.abilities.cannot('manage all employees')
      : this.abilities.cannot('manage employees');
  }

  get isSynced() {
    return (
      this.currentCompany.directorySyncProvider && !this.model.employee.doNotSync && !this.model.employee.manuallyAdded
    );
  }

  get hideEmployeeDetails() {
    if (!this.isReadOnly) return false;

    if (this.router.currentRouteName?.includes('location-overview')) {
      return this.abilities.cannot('see directory details for employees');
    } else {
      return this.abilities.cannot('see directory details at location for employees');
    }
  }

  get scimProvider() {
    const provider = this.currentCompany.scimIntegration.content?.provider;
    let integration = '';
    if (provider === 'okta') {
      integration = 'Okta';
    } else if (provider === 'onelogin') {
      integration = 'OneLogin';
    }

    return integration;
  }

  get showDoNotSyncToggle() {
    const notManuallyAdded = !this.model.employee.manuallyAdded;
    const notDeleted = !this.model.employee.deleted;
    const hasSync = this.currentCompany.directorySyncProvider;

    return notManuallyAdded && notDeleted && hasSync;
  }

  get defaultCountry() {
    return config.environment === 'test' ? 'us' : 'auto';
  }

  get employeeLocationOptions() {
    const employeeLocationIds = new Set(
      this.model.employeeLocations.map((employeeLocation) => employeeLocation.locationId),
    );

    const employeeLocations = [];
    this.locations.map((location) => {
      if (employeeLocationIds.has(parseInt(location.id, 10))) {
        // only inactive locations should be disabled
        const disabled = !isEmpty(location.disabledToEmployeesAt);
        let name = location.name;
        if (disabled) {
          name += ' (Disabled)';
        }
        employeeLocations.push({
          ...location,
          id: location.id,
          disabled,
          name,
        });
      }
    });

    return [this.blankLocationOption, ...employeeLocations];
  }

  get defaultLocation() {
    const defaultLocationId = this.changeset.defaultLocationId;
    return this.locations.find((location) => location.id == defaultLocationId) || this.blankLocationOption;
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get activeUserDocumentTemplateConfigurations() {
    return this.model.userDocumentTemplateConfigurations?.filterBy('active') || [];
  }

  get hasDocumentStatusEnabled() {
    return this.activeUserDocumentTemplateConfigurations.length > 0;
  }

  @action
  updateDefaultLocation(selectedLocation) {
    this.changeset.defaultLocationId = selectedLocation.id;
  }

  @action
  doSearch(term) {
    const extraFilters = {
      except: this.model.employee.id,
    };
    return this.searchEmployeesTask.perform(term, extraFilters);
  }

  @action
  searchAllEmployees(term) {
    const manager = this.changeset.manager;
    const except = [this.model.employee.id];
    if (manager?.id) {
      except.push(manager.id);
    }
    return this.searchEmployeesTask.perform(term, { except }, { withoutLocation: true });
  }

  @action
  afterDelete() {
    this.send('resetEmployees');
    this.checkRestrictions().then(() =>
      this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`),
    );
  }

  @action
  closeEmployeeDeleteModal() {
    this.showDeleteEmployeeModal = false;
  }

  @action
  cancel() {
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`);
  }

  @action
  toggleDoNotSync() {
    const currentState = this.model.employee.doNotSync;
    if (currentState) {
      this.changeset.rollback();
    }
    this.model.employee.doNotSync = !currentState;
  }

  @action
  toggleEditMode() {
    this.isEditModeEnabled = !this.isEditModeEnabled;
  }

  updateAssistantsTask = task(async (assistants) => {
    this.model.employee.assistants = assistants;
    try {
      await this.model.employee.save();
      this.flashMessages.showAndHideFlash('success', 'Assistants updated');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  updateManagerTask = task(async (manager) => {
    this.model.employee.manager = manager;
    try {
      await this.model.employee.save();
      this.flashMessages.showAndHideFlash('success', 'Manager updated');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  restoreEmployee = task({ drop: true }, async () => {
    this.model.employee.deleted = false;

    try {
      await this.model.employee.save();

      this.employeeDirectory.addEmployee(this.model.employee);

      await this.checkRestrictions();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees`);
    } catch (e) {
      this.model.employee.deleted = true;
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  deleteEmployeeLocationsTask = dropTask({ debug: true }, async (selectedLocations, resetSelectedLocations) => {
    const { employee } = this.model;
    const deleteEmployeeCompletely = this.model.employeeLocations.length === selectedLocations.length;
    let actions = [];

    // if the employee doesn`t have any locations left then destroy it
    if (deleteEmployeeCompletely) {
      actions.push(employee.destroyRecord());
    } else {
      actions = selectedLocations.map((employeeLocation) => employeeLocation.destroyRecord());
    }

    try {
      await all(actions);
      resetSelectedLocations();
      this.flashMessages.showAndHideFlash('success', 'Employee removed');
      if (deleteEmployeeCompletely) {
        this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
        this.send('resetEmployees');
      }
    } catch (error) {
      const locationIds = selectedLocations.map((location) => location.id);
      const locationNames = this.locations
        .filter((location) => locationIds.includes(location.id))
        .map((location) => location.name)
        .join(', ');
      const errorText = `We were unable to remove the ${employee.name} from ${locationNames}`;
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  deleteEmployeeLocation = dropTask(async (employeeLocation) => {
    const { employee } = this.model;
    const deleteEmployeeCompletely = this.model.employeeLocations.length === 1;

    try {
      if (deleteEmployeeCompletely) {
        this.showDeleteAllLocationsModal = false;
        await employee.destroyRecord();
        this.flashMessages.showAndHideFlash('success', 'Employee removed');
        this.send('resetEmployees');
        this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
      } else {
        const locationId = employeeLocation.locationId;
        await employeeLocation.destroyRecord();

        if (this.changeset.defaultLocationId === locationId) {
          this.changeset.defaultLocationId = null;
        }

        this.flashMessages.showAndHideFlash('success', 'Employee location removed');
      }
    } catch (error) {
      const locationName = get(employeeLocation.location, 'name');
      const errorText = `We were unable to remove ${employee.name} from ${locationName}`;
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  saveTask = task(async (changeset) => {
    await changeset.validate();
    const isValid = changeset.isValid;

    if (!isValid) {
      const errorTypes = [];

      if (this.changeset.error?.name?.validation) {
        errorTypes.push('name');
      }
      if (this.changeset.error?.email?.validation) {
        errorTypes.push('properly formatted email');
      }
      if (this.changeset.error?.phoneNumber?.validation) {
        errorTypes.push('properly formatted phone number');
      }

      const error = `Please have a ${arrayToSentence(errorTypes)}`;
      this.flashMessages.showFlash('error', error);
    } else {
      await changeset.save();
      await this.checkRestrictions();

      this.toggleEditMode();
      this.coho.sendEvent(WorkplaceEventNames.EMPLOYEE_EDITED, { product: APP.WORKPLACE });
      this.statsig.logEvent(`coho_${WorkplaceEventNames.EMPLOYEE_EDITED}`, null, { product: APP.WORKPLACE });
      if (!this.featureFlags.isEnabled('employee-directory-redesign')) {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    }
  });

  setupChangeset() {
    const validator = lookupValidator(EmployeeValidations);
    this.changeset = new Changeset(this.model.employee, validator, EmployeeValidations);
  }

  checkRestrictions() {
    return this.state.checkRestrictionsTask.perform(true);
  }
}
