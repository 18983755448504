import type { AsyncHasMany } from '@ember-data/model';
import Component from '@glimmer/component';
import type AnnouncementChannelsModel from 'garaje/models/announcement-channel';
import type { AnnouncementChannelValue } from 'garaje/models/announcement-channel';

type AnnouncementRecipientListRowType = {
  channels: AsyncHasMany<AnnouncementChannelsModel>;
};

export default class AnnouncementRecipientListRow extends Component<AnnouncementRecipientListRowType> {
  get showEmail(): boolean {
    return this.showChannel('email');
  }

  get showSms(): boolean {
    return this.showChannel('sms');
  }

  get showPush(): boolean {
    return this.showChannel('push');
  }

  get showSlack(): boolean {
    return this.showChannel('slack');
  }

  get showMsTeams(): boolean {
    return this.showChannel('ms_teams');
  }

  private showChannel(channelName: AnnouncementChannelValue) {
    return this.args.channels.any((ac) => ac.channel === channelName);
  }
}
