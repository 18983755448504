import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';

export default function buildFlowFieldValidations(field) {
  const validations = [];

  if (field.required && !(field.allowVisitorRespondents ?? true)) {
    validations.push(
      validatePresence({
        presence: true,
        message: 'This is a required field',
      }),
    );
  }

  if (field.isEmail) {
    validations.push(
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: 'Please enter a valid email address',
      }),
    );
  }

  return { value: validations };
}
