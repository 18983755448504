/* eslint-disable ember/no-get */
import { A } from '@ember/array';
import type Enumerable from '@ember/array/-private/enumerable';
import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action, get, set } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import ObjectProxy from '@ember/object/proxy';
import { inject as service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';
import { isEmpty, isBlank, isPresent } from '@ember/utils';
import type { AsyncBelongsTo } from '@ember-data/model';
import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task, TaskInstance } from 'ember-concurrency';
import { task, timeout, dropTask, restartableTask, all } from 'ember-concurrency';
import config from 'garaje/config/environment';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import type AbstractFlowModel from 'garaje/models/abstract/abstract-flow';
import type AgreementModel from 'garaje/models/agreement';
import type ConfigModel from 'garaje/models/config';
import type EmployeeModel from 'garaje/models/employee';
import type EntryModel from 'garaje/models/entry';
import type EventReportModel from 'garaje/models/event-report';
import type FlowModel from 'garaje/models/flow';
import type PlatformJobModel from 'garaje/models/platform-job';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type UserDatum from 'garaje/models/user-datum';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import type ActiveStorageService from 'garaje/services/active-storage-extension';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { NON_ASSIGNABLE_FLOWS, PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import MappedField from 'garaje/utils/mapped-field';
import normalizeResponse from 'garaje/utils/normalize-response';
import { Permission } from 'garaje/utils/ui-permissions';
import urlBuilder from 'garaje/utils/url-builder';
import addOrUpdateUserData from 'garaje/utils/user-data';
import zft from 'garaje/utils/zero-for-tests';
import _intersection from 'lodash/intersection';
import { reads, notEmpty } from 'macro-decorators';
import moment from 'moment-timezone';
import { defer } from 'rsvp';
import { cached } from 'tracked-toolbox';

import type { VisitorsEntryModel } from './route';

const EVENT_REPORT_POLLING_TIMEOUT = zft(10000);
const NUMBER_OF_POLLING_TRIES = zft(3);
const testWaiter = buildWaiter('new-visitor-form-component-waiter');
const isTesting = config.environment === 'test';

type FieldChangeset = DetailedChangeset<Partial<SignInFieldModel> | SignInFieldModel>;

export default class VisitorsEntryController extends Controller {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service declare activeStorageExtension: ActiveStorageService;
  @service declare ajaxFetch: AjaxFetchService;
  @service declare authz: AuthzService;
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;
  @service declare featureFlags: FeatureFlagsService;

  checkStatuses = ['Checked', 'Not checked'];
  @tracked previousEntries = A<EntryModel>([]);
  @tracked displayPrinterSelectorModal = false;
  @tracked morePreviousEntriesUrl: string | null = null;
  @tracked eventReports?: Array<EventReportModel | PlatformJobModel>;
  @tracked userDataChangeset?: FieldChangeset[];
  @tracked staticFields?: MappedField[];
  @tracked confirmRemovalOfDocument: VisitorDocumentModel | null = null;
  @tracked hasAttachments = false;

  declare connectedTenants: VisitorsEntryModel['connectedTenants'];
  declare config: VisitorsEntryModel['config'];
  declare agreeableNdas: VisitorsEntryModel['agreeableNdas'];
  declare agreements: AgreementModel[];
  declare printers: VisitorsEntryModel['printers'];
  declare vrSubscription: VisitorsEntryModel['vrSubscription'];
  declare blocklistContacts: VisitorsEntryModel['blocklistContacts'];
  declare idScanContacts: VisitorsEntryModel['idScanContacts'];
  declare model: EntryModel;

  @action
  updateUserDataField(): void {
    /*
     * NOOP -- the way we interact with userData is slightly different
     * here to how we do it invites. Since the code was copy pasted we
     * need to keep this until we refactor the component entry-fields.
     *
     * Intentional TODO: Refactor this.
     */
  }

  @reads('model.approvalStatus') approvalStatus!: EntryModel['approvalStatus'];
  @reads('state.currentLocation') currentLocation!: StateService['currentLocation'];
  @reads('hasChanges') canSave!: boolean;
  @reads('flowField.value') flowName!: string;
  @notEmpty('model.approvalStatus.preRegistrationRequiredReport') hasPreRegistrationRequiredReports!: boolean;
  @notEmpty('model.invite.id') hasInvite!: boolean;
  @reads('flow.activeUserDocumentTemplateConfigurations')
  activeUserDocumentTemplateConfigurations!: FlowModel['activeUserDocumentTemplateConfigurations'];

  // @ts-ignore
  @(employeesSearcherTask({
    filter: {
      deleted: false,
    },
    prefix: true,
  }).restartable())
  searchEmployeesTask!: Task<EmployeeModel[], [string]>;

  @dependentKeyCompat
  get hasDirtyUserData(): boolean {
    return this.model.userData.isAny('isDirty') || this.isStaticDirty;
  }

  get cannotChangeFlows(): boolean {
    return (
      this.abilities.cannot('edit entry') ||
      this.isANonAssignableFlow ||
      this.isMultiLocation ||
      this.featureFlags.isEnabled('disable-flow-change-on-existing-invites-and-entries')
    );
  }

  get isMultiLocation(): boolean {
    return this.locationNames.length > 1;
  }

  get locationNames(): string[] {
    return this.model.locationNames || [];
  }

  get primaryLocation(): string | undefined {
    return this.locationNames[0];
  }

  get secondaryLocations(): string[] {
    const [_, ...childLocationNames] = this.locationNames;
    return childLocationNames;
  }

  get isStaticDirty(): boolean {
    return Boolean(
      this.staticFields?.find((field) => {
        return (<DetailedChangeset<unknown>>field.changeset).isDirty;
      }),
    );
  }

  get endOfSignedOutMonth(): moment.Moment {
    return moment(this.model.signedOutAt).add(1, 'M').endOf('month');
  }

  get canPrintBadges(): boolean {
    const hasPrintPermission = this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_PRINTER_READ);

    return Boolean(hasPrintPermission && this.vrSubscription?.canEnableBadgePrinting);
  }

  get canSetPropertyNotes(): boolean {
    return this.locationIsConnectedToProperty;
  }

  get isANonAssignableFlow(): boolean {
    return this.model.isFromEmployeeScreening || this.model.isFromWalkUp;
  }

  get legalDocumentDescriptions(): Record<string, string> {
    return this.agreements.reduce<Record<string, string>>((obj, agreement) => {
      const agreeableNda = this.model.agreeableNdas.find((nda) => nda.agreement.id === agreement.id);
      /**
       * There may be agreeableNdas that have signed agreements (i.e. an "NDA" PDF) but that don't have a
       * relationship to an agreement (i.e., a legal document template) and therefore have no agreement name.
       * These signed agreements are from before visitor type flows existed. We assign them a generic
       * "Non-disclosure Agreement" name because NDAs used to be the only kind of agreement we supported.
       */
      const agreementName = typeof agreement.name === 'undefined' ? 'Non-disclosure Agreement' : agreement.name;

      if (agreeableNda?.signedAt) {
        const entrySignedInAt = moment(this.model.signedInAt);
        const agreementSignedAt = moment(agreeableNda?.signedAt);
        const wasPreviouslySigned = entrySignedInAt.diff(agreementSignedAt, 'days') !== 0;
        const description = wasPreviouslySigned ? `signed ${agreementSignedAt.format('MMM D, YYYY')}` : 'signed';

        obj[agreementName] = description;
      } else if (agreeableNda?.agreementOptional) {
        obj[agreementName] = 'optional';
      } else {
        obj[agreementName] = 'not signed';
      }

      return obj;
    }, {});
  }

  get locationIsConnectedToProperty(): boolean {
    return <number>this.connectedTenants.length > 0;
  }

  get isAdmin(): boolean {
    const roleNames = this.currentAdmin.roleNames;
    return isPresent(_intersection(['Global Admin', 'Location Admin', 'Front Desk Admin'], roleNames));
  }

  get thumbnailPath(): string {
    const largeThumbnail = this.model.thumbnails['large'];
    return largeThumbnail ? largeThumbnail : '/assets/images/features/avatar-placeholder.png';
  }

  get showSignOutSection(): boolean {
    const entry = this.model;

    // don't show "Signed Out" section if approval was denied and visitor never entered
    if (entry.approvalWasDenied) return false;

    // show "Signed Out" section is the entry is signed out, or the current user can edit the entry
    return !!entry.signedOutAt || this.abilities.can('edit entry', entry);
  }

  _buildStaticFields(): void {
    const staticFields: MappedField[] = [];

    const fullNameObject = new MappedField({
      fieldData: this.model.userData.findBy('field', 'Your Full Name'),
      isRequired: true,
    });

    if (!isEmpty(get(this.currentLocation, 'config.localizedFields')) && fullNameObject.fieldData) {
      staticFields.push(fullNameObject);
    }

    const emailAddress = this.model.userData.findBy('field', 'Your Email Address');
    const phoneNumber = this.model.userData.findBy('field', 'Your Phone Number');

    if (emailAddress) {
      const decoratedEmail = new MappedField({ fieldData: emailAddress, isRequired: false });
      staticFields.push(decoratedEmail);
    }

    if (phoneNumber) {
      const decoratedPhone = new MappedField({ fieldData: phoneNumber, isRequired: false });

      staticFields.push(decoratedPhone);
    }

    this.model.userData.forEach((datum) => {
      if (!staticFields.find((field) => field.field === datum.field) && 'Purpose of visit' !== datum.field) {
        staticFields.push(new MappedField({ fieldData: datum, isRequired: false }));
      }
    });

    this.staticFields = staticFields;
  }

  get sourcesForApprovalReviewMetrics(): Record<string, boolean> {
    return this.approvalStatus.failedReport.reduce<Record<string, boolean>>(
      (sources, report) => ({ ...sources, [report.source]: true }),
      {},
    );
  }

  /* MVT related
   * Keeping all the code related with MVT below
   */
  /*
   * flowField attempts to lookup an Entry's flow, first
   * by POV, then by the key given in config.localizedFields,
   * and finally by provided a POJO with the value of flowName
   *
   * TODO: What is the logic behind this? There's totally an
   * undefined case that's not covered here.
   */
  get flowField(): Partial<UserDatum> | UserDatum | undefined {
    const { model, currentLocation } = this;
    const { flowName, userData } = model;
    let povField: string | undefined = model.povKey;

    // This is only relevant on legacy entries.
    // After MVT all userData will be stored in English
    if (!povField) {
      povField = get(<ConfigModel>(<unknown>currentLocation.config), 'localizedFields').find(
        ({ field }) => field === 'Purpose of visit',
      )?.localized;
    }

    let flowField: Partial<UserDatum> | UserDatum | undefined = userData.findBy('field', povField);

    if (!flowField && flowName) {
      // Notice POJO instead of ED object
      flowField = {
        value: flowName,
      };
    }

    return flowField;
  }

  /*
   * This is the current flow found on the entry, even if it does
   * not exist in `currentLocation.flows`
   */
  get currentFlowField(): Partial<FlowModel> {
    // Since flow and Purpose of Visit are completely different fields,
    // but the form element to change Purpose of visit relies on
    // currentLocation.flows, we have to make this composite structure
    // so that the entry's flow, as it is stored, is compatible with
    // the `currentLocation.flows`'s type

    let flowFieldJSON;
    if (this.flowField && !this.flowField.toJSON) {
      // This is when flowField returns as POJO
      flowFieldJSON = this.flowField;
    } else {
      // This is when flowField is an ED obj or UserDatum
      flowFieldJSON = this.flowField?.toJSON!();
    }
    let o;
    o = Object.assign({}, flowFieldJSON);
    o = Object.assign(o, { name: <string>this.flowField?.value });
    return o;
  }

  /*
   * All possible flows include all flows in `currentLocation.flows`
   * as well as any flow found on the entry.
   */
  get possibleFlowsForEntries(): NativeArray<FlowModel | AsyncBelongsTo<FlowModel> | Partial<FlowModel>> {
    const possibleFlowsForEntries = A<FlowModel | AsyncBelongsTo<FlowModel> | Partial<FlowModel>>();
    if (this.model.isFromEmployeeScreening || this.purposeOfVisit?.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      possibleFlowsForEntries.push(this.model.flow);
      return possibleFlowsForEntries;
    }
    possibleFlowsForEntries.push(this.currentFlowField);
    this.currentLocation.flows
      .filter(({ employeeCentric, type, name }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric && name)
      .forEach((f) => possibleFlowsForEntries.push(f));
    return possibleFlowsForEntries.uniqBy('name');
  }

  get hasFlowThatNoLongerExists(): boolean {
    const locationFlows = this.currentLocation.flows.filter(
      ({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric,
    );
    return this.possibleFlowsForEntries.length > locationFlows.length;
  }

  get purposeOfVisit(): UserDatum | undefined {
    return this.model.userData.findBy('field', 'Purpose of visit');
  }

  /*
   * Flow is the full flow object, looked up from currentLocation,
   * that exists if it's found by name from what's listed on the entry
   */
  get flow(): AbstractFlowModel | undefined | null {
    const flowId = <string>get(this.model, 'flow.id');
    const pov = <string>this.purposeOfVisit?.value;

    if (this.model.isFromEmployeeScreening || pov === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.currentLocation.employeeScreeningFlow;
    }

    // fallback on name instead of id if using non model based flow
    return this.currentLocation.flows.findBy('id', flowId) ?? this.currentLocation.flows.findBy('name', pov);
  }

  get hasChanges(): boolean {
    const userDataChangesetModified = this.userDataChangesetModified;
    const hasDirtyAttributes = this.model.hasDirtyAttributes;
    const hasDirtyUserData = this.hasDirtyUserData;
    const hasDirtyVisitorDocuments = this.hasDirtyVisitorDocuments;

    return userDataChangesetModified || Boolean(hasDirtyAttributes) || hasDirtyUserData || hasDirtyVisitorDocuments;
  }

  @dependentKeyCompat
  get userDataChangesetModified(): boolean {
    return Boolean(this.userDataChangeset?.find((field) => field.isDirty));
  }

  get signInFields(): NativeArray<SignInFieldModel> | SignInFieldModel[] {
    if (!this.flow) {
      return [];
    }

    return <NativeArray<SignInFieldModel> | SignInFieldModel[]>get(this.flow, 'signInFieldPage.signInFields') || [];
  }

  _buildUserDataChangeset(): void {
    const { model, currentLocation } = this;
    let signInFields: SignInFieldModel[] = [];
    const fields: Array<FieldChangeset> = [];

    if (this.flow) {
      signInFields = <SignInFieldModel[]>get(this.flow, 'signInFieldPage.signInFields');
    }

    // bootstrap editable sign-in-fields based in entry.userData
    model.userData.forEach((userDatum, index) => {
      const { field: fieldName, value } = userDatum;
      /*
       * We ignore flow field, it is not editable via the
       * {{entry-fields}} component. If we allow flow edit, then
       * it will happen through a different interface in the template.
       */
      // eslint-disable-next-line ember/use-ember-get-and-set
      if (value !== this.flow?.get('name') && isPresent(signInFields)) {
        let field: Partial<SignInFieldModel> | SignInFieldModel | undefined = signInFields.find(
          (f) => f.name === fieldName,
        );

        if (!field) {
          /* The following conditions brute force a match for host, email or phone.
           *
           * In an ideal world we should have a found a match already, but:
           *
           *  - field for host changes (since it's customizable)
           *  - email can be saved on a different locale
           *  - phone number was store with the bdField (API regression)
           */
          if (value === model.host) {
            field = signInFields.find((f) => f.isHost);
          } else if (value === model.email) {
            field = signInFields.find((f) => f.isEmail);
          } else if (fieldName === 'phoneNumber') {
            field = signInFields.find((f) => f.isPhone);
          }
        }

        if (!field) {
          /*
           * If we get here, it means there used to be a
           * sign-in-field model for this field, but it was
           * removed.
           *
           * We create a "pseudo-field", so it displays as a
           * text-field in the template.
           *
           * Position 100 is to show the field at the bottom of
           * the list.
           */
          field = {
            id: `${index}-${currentLocation.id}`, // pseudo-id
            name: fieldName,
            isCustom: true,
            localized: fieldName,
            isLoaded: <Model['isLoaded']>(<unknown>true),
            position: 100,
          };
        }

        const fieldChangeset = <FieldChangeset>(<unknown>Changeset(ObjectProxy.create({ content: field, value })));
        fields.push(fieldChangeset);
      }
    });

    if (isPresent(signInFields)) {
      signInFields.forEach((field) => {
        // We want to prevent duplicate fields in the case where userData
        // also contains the same field
        const fieldIsAlreadyBootstrapped = !!fields.find((f) => f.id === field.id);
        const fieldIsGuestName = field.isGuestName;

        if (fieldIsGuestName || fieldIsAlreadyBootstrapped) {
          return;
        }

        fields.push(<FieldChangeset>(<unknown>Changeset(ObjectProxy.create({ content: field, value: '' }))));
      });
    }

    this.userDataChangeset = fields;
  }

  @action
  doSearch(term: string): TaskInstance<EmployeeModel[]> {
    return this.searchEmployeesTask.perform(term);
  }

  @action
  setHost(employee: EmployeeModel): void {
    this.model.host = employee && employee.name;
    this.model.employee = employee;
  }

  approveEntryTask = task(async (entry: EntryModel) => {
    try {
      await entry.approveEntry();
      await get(entry, 'flow.badge'); // load async relationship
      this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));
      await entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'approve',
        entry_id: entry.id,
        source: 'Entry Details',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving entry', parseErrorForDisplay(error));
    }
  });

  denyEntryTask = task(async (entry: EntryModel) => {
    try {
      await entry.denyEntry();
      this.flashMessages.showAndHideFlash('warning', 'Entry denied');
      await entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'deny',
        entry_id: entry.id,
        source: 'Entry Details',
        ...this.sourcesForApprovalReviewMetrics,
      });
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error denying entry');
    }
  });

  reprintBadgeTask = task(async (entry: EntryModel, printer: PrinterModel | null = null) => {
    try {
      let response;
      // FF. multiplePrinters
      if (printer) {
        const data = [{ type: 'entries', id: entry.id }];
        response = await printer.reprintBadge({ data });
      } else {
        // TODO Is this dead code?
        await entry.reprintBadge();
      }
      this._trackReprintBadgeRequested(entry.id);
      if (response) {
        const errored = response.data.filter((r) => r.attributes.result === 'error');
        if (errored.length) {
          const msg = 'Cannot print badge';
          const componentName = 'flash-message/printer-error';
          this.flashMessages.showFlashComponent('error', msg, componentName, response);
          const statuses = errored[0]?.attributes.statuses;
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'error',
            message_title: msg,
            message_codes: statuses,
          });
        } else {
          const msg = 'Printing badge!';
          this.flashMessages.showAndHideFlash('success', msg);
          this.metrics.trackEvent('Viewed Flash Message', {
            type: 'success',
            message_title: msg,
            message_codes: [],
          });
        }
      } else {
        this.flashMessages.showAndHideFlash('success', 'Printing badge!');
      }
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Cannot print badge');
    }
  });

  _trackReprintBadgeRequested(entryId: string): void {
    const entry_id = parseInt(entryId, 10);
    const properties = {
      action_origin: 'entry_page',
      entry_id,
    };
    this.metrics.trackEvent('Reprint Entry Badge Requested', properties);
  }

  @dropTask
  showDeleteConfirmationTask: {
    entries: EntryModel[];
    abort?(): void;
    continue?(args: EntryModel[]): void;
    perform(entries: EntryModel[]): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    entries: [],
    *perform(entries: EntryModel[]): Generator<Promise<unknown>, unknown, unknown> {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = (args) => deferred.resolve(args);
      return yield deferred.promise;
    },
  };

  saveTask = task(async () => {
    const { model } = this;
    const { visitorDocuments } = model;
    const token = isTesting ? testWaiter.beginAsync() : false;

    this.updateAllUserData(model);

    await model.save();
    try {
      await all(
        (visitorDocuments ?? []).map((visitorDocument) => this.saveVisitorDocumentTask.perform(model, visitorDocument)),
      );
    } catch (_error) {
      this.flashMessages.showFlash('error', 'Visitor saved! But one or more document uploads failed');

      return;
    } finally {
      if (token) testWaiter.endAsync(token);
    }

    this.flashMessages.showAndHideFlash('success', 'Saved!');
  });

  loadEventReports = dropTask(async () => {
    const location = this.currentLocation.id;
    const identifier = `vr:entry:${this.model.id}`;
    try {
      /**
       * We're querying for both event-reports and host-notification platform-jobs,
       * because eventually we will deprecate event-reports.
       * For now, we can de-dupe them by replacing any event-reports that have a corresponding
       * platform-job with the same name.
       */
      const platformJobsQueryResult = await this.store.query('platform-job', { filter: { identifier } });
      const newPlatformJobs = <Enumerable<EventReportModel>>(
        (<unknown>platformJobsQueryResult.toArray().filter((job) => job.pluginCategory === 'host-notifications'))
      );
      const platformJobNames = newPlatformJobs.map((job) => job.name);
      const newEventReportsQueryResult = await this.store.query('event-report', { filter: { identifier, location } });
      const newEventReports = A(newEventReportsQueryResult.filter((report) => !platformJobNames.includes(report.name)));
      newEventReports.pushObjects(newPlatformJobs);

      if (newEventReports.length) {
        const loadedReports = this.eventReports;
        const newEventIds = newEventReports.map((report) => report.sourceId);

        // Since it's possible that we could have an old event report in "pending" status that has since
        // changed to a "terminal" status, we always take the latest version of any duplicate event reports
        const updatedEventReportList = loadedReports
          ?.filter((report) => !newEventIds.includes(report.sourceId))
          .concat(newEventReports);

        this.eventReports = updatedEventReportList;
      }
      return newEventReports;
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error loading notification statuses', parseErrorForDisplay(e));
      throw e;
    }
  });

  pollEventReports = restartableTask(async () => {
    if (!this.currentLocation.hostNotificationsEnabled) {
      return;
    }

    const location = this.currentLocation.id;
    let keepPolling = false;
    let maxPolls = NUMBER_OF_POLLING_TRIES;
    do {
      if (isBlank(location)) {
        break;
      }
      const newEventReports = await this.loadEventReports.perform();
      // Only poll as long as there are event reports that are either `queued` or `inProgress`.
      const hasPendingEvents = newEventReports.some((event) => event.inProgress || event.queued);
      // The events creation for a new entry is async, so the intial request for a just created entry
      // could be empty, so we give `NUMBER_OF_POLLING_TRIES`
      maxPolls--;

      keepPolling = hasPendingEvents || maxPolls > 1;
      await timeout(EVENT_REPORT_POLLING_TIMEOUT);
    } while ((config.environment !== 'test' || config.testLongPolling) && keepPolling);
  });

  rollbackChanges(): void {
    const { model } = this;
    const userDataChangeset = this.userDataChangeset;

    model.rollbackAttributes();
    userDataChangeset?.forEach((changeset) => changeset.rollback());
    model.visitorDocuments.invoke('rollbackAttributes');
  }

  @action
  setAdditionalHosts(hosts: EmployeeModel[]): void {
    this.model.additionalHosts = hosts;
  }

  @action
  setHostAndUserData(changeset: FieldChangeset, employee: EmployeeModel): void {
    this.setHost(employee);
    set(changeset, 'value', employee.name);
  }

  updateAllUserData(entry: EntryModel): void {
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.beginPropertyChanges();
    this.userDataChangeset
      ?.filter((changeset) => changeset.isDirty)
      .forEach((fieldChangeset) => {
        addOrUpdateUserData(entry, fieldChangeset.name, fieldChangeset.value);

        fieldChangeset.execute();
        fieldChangeset.rollback();
      });

    this.staticFields
      ?.filter((field) => field.changeset?.isDirty)
      .forEach((field) => {
        field.changeset?.execute();
        field.changeset?.rollback();
      });
    // @ts-ignore private function
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.endPropertyChanges();
  }

  loadPreviousEntries = task(async (url?: string) => {
    if (isBlank(url) && this.model.id) {
      url = urlBuilder.v3.entry.previousEntriesUrl(this.model.id);
    }
    const result = await this.ajaxFetch.request<{ links: { next: string } }>(url!, {
      headers: { accept: 'application/vnd.api+json' },
      contentType: 'application/vnd.api+json',
      data: { page: { limit: 3 } },
    });

    const entries = normalizeResponse(this.store, 'entry', result);

    const previousEntries = <Enumerable<EntryModel>>(<unknown>this.store.push(entries));
    this.previousEntries.addObjects(previousEntries);

    this.morePreviousEntriesUrl = result.links.next;
  });

  @action
  setFlow(flow: FlowModel): void {
    this.model.flowName = flow.name;
    this.flowField!.value = flow.name;
    // Only assign a model to the rel.
    // Need to reset rel so fallback on virtual flow looks up proper flow.
    // Will get fixed on save when proper flow is returned from API
    // @ts-ignore
    set(this.model, 'flow', flow.id ? flow : undefined);
    this._buildUserDataChangeset();
  }

  @action
  save(): void {
    void this.saveTask.perform();
  }

  @action
  delete(): void {
    const dateForDashboard = moment(this.model.signInTime).format('YYYY-MM-DD');
    (<TaskInstance<void>>(<unknown>this.showDeleteConfirmationTask.perform([this.model]))).then(
      () => {
        this.transitionToRoute('visitors.entries', { queryParams: { date: dateForDashboard } });
      },
      (reason: Error) => {
        if (reason && reason.message !== 'TransitionAborted') {
          throw reason;
        }
      },
    );
  }

  @action
  reprintBadge(entry: EntryModel, printer = null): void {
    void this.reprintBadgeTask.perform(entry, printer);
  }

  @action
  signOut(time: string): void {
    const signInDateFormatted = moment(this.model.signInTime).format('YYYY-MM-DD');
    const signOutTime = time ? moment(`${signInDateFormatted} ${time}`, 'YYYY-MM-DD h:mm a').toDate() : new Date();
    // we need to do this because we set signOutTime here, and again in the sign-out-entry
    // and Ember will throw an error because we're hammering this value
    this.model._signOutTime = signOutTime;
    (<TaskInstance<void>>(<unknown>this.showSignOutConfirmationTask.perform([this.model]))).then(
      () => {
        return this.transitionToRoute('visitors.entries', {
          queryParams: { date: signInDateFormatted },
        });
      },
      (reason: Error) => {
        if (reason && reason.message !== 'TransitionAborted') {
          throw reason;
        } else {
          this.model.rollbackAttributes();
        }
      },
    );
  }

  @action
  checkForAttachments(): void {
    this.hasAttachments =
      A(this.visitorDocuments ?? []).isAny('hasAttachedFile') ||
      A(this.visitorDocuments ?? []).isAny('hasInputFieldData');
  }

  // Manage Visitor Documents

  @cached
  get visitorDocuments(): VisitorDocumentModel[] {
    const { model, activeUserDocumentTemplateConfigurations } = this;

    return (activeUserDocumentTemplateConfigurations ?? []).sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        model.visitorDocumentForTemplate(userDocumentTemplate) ||
        this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get hasDirtyVisitorDocuments(): boolean {
    return A(this.visitorDocuments).any((visitorDocument) => this.isVisitorDocumentPersistable(visitorDocument));
  }

  get isValidVisitorDocuments(): boolean {
    const documentsToCheck = this.visitorDocuments?.filter((visitorDocument) => visitorDocument.hasAttachedFile);
    if (isEmpty(documentsToCheck)) return true;

    return documentsToCheck.every((visitorDocument) => visitorDocument.isValidDocument);
  }

  get modalElement(): HTMLElement | null {
    return document.getElementById('modal');
  }

  isVisitorDocumentPersistable(visitorDocument: VisitorDocumentModel): boolean {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;
    const hasDirtyAttributes = <boolean>(<unknown>visitorDocument.hasDirtyAttributes) && !visitorDocument.isNew;

    return isAssociatedToActiveTemplate && (hasAttachmentsWithPendingUploads || hasDirtyAttributes);
  }

  @action
  attachFileToDocument(
    visitorDocument: VisitorDocumentModel,
    userDocumentTemplateAttachment: UserDocumentTemplateAttachmentModel,
    update: File | string,
  ): void {
    visitorDocument.entries.addObject(this.model);

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument: VisitorDocumentModel): void {
    const { model } = this;
    const { userDocumentTemplate } = visitorDocument;

    if (<boolean>(<unknown>visitorDocument.isNew)) {
      model.visitorDocuments.removeObject(visitorDocument);
      visitorDocument.unloadRecord();
      model.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
    } else {
      this.confirmRemovalOfDocument = visitorDocument;
    }
  }

  @dropTask
  showSignOutConfirmationTask: {
    entries: EntryModel[];
    continue?(): void;
    abort?(): void;
    perform(entries: EntryModel[]): Generator<Promise<unknown>, void, void>;
  } = {
    entries: [],

    *perform(entries: EntryModel[]): Generator<Promise<unknown>, void, void> {
      this.entries = entries;

      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  saveVisitorDocumentTask = task(async (entry: EntryModel, visitorDocument: VisitorDocumentModel) => {
    if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

    // Associate the entry's location to the visitor document (fallback to current location)
    const location = this.store.peekRecord('location', entry.belongsTo('location').id()) || this.state.currentLocation;

    visitorDocument.locations = A([location]);

    await this.uploadVisitorDocumentAttachmentsTask.perform(entry, visitorDocument);
    await visitorDocument.save();

    // Cleanup attachments
    visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

    return visitorDocument;
  });

  uploadVisitorDocumentAttachmentsTask = task(async (entry: EntryModel, visitorDocument: VisitorDocumentModel) => {
    if (!visitorDocument.userDocumentAttachmentsPendingUpload.length) return;

    const { activeStorageExtension } = this;
    const directUploadURL = '/a/visitors/api/direct-uploads';
    const userDocumentTemplateId = visitorDocument.belongsTo('userDocumentTemplate').id();

    if (!(entry?.id && userDocumentTemplateId)) return;

    const prefix = `user-documents/${userDocumentTemplateId}/entries/${entry.id}`;
    const endpoint = `${directUploadURL}?prefix=${prefix}`;

    return await all(
      visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
        this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension),
      ),
    );
  });

  @task uploadDocumentAttachmentTask: {
    progress: number;
    perform(
      userDocumentAttachment: UserDocumentAttachmentModel,
      endpoint: string,
      activeStorageExtension: ActiveStorageService,
    ): Generator<Promise<{ signedId: string }>, unknown, { signedId: string }>;
  } = {
    progress: 0,

    *perform(
      userDocumentAttachment: UserDocumentAttachmentModel,
      endpoint: string,
      activeStorageExtension: ActiveStorageService,
    ): Generator<Promise<{ signedId: string }>, unknown, { signedId: string }> {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          this.progress = progress;
          if (!userDocumentAttachment.isDestroyed && !userDocumentAttachment.isDestroying) {
            set(userDocumentAttachment, 'uploadProgress', progress);
          }
        },
      });

      userDocumentAttachment.file = signedId;

      return signedId;
    },
  };

  removeVisitorDocumentFromEntryTask = task(async (visitorDocument: VisitorDocumentModel, entry: EntryModel) => {
    const { userDocumentTemplate } = visitorDocument;

    try {
      await visitorDocument.removeFromEntries([entry]);
      entry.visitorDocuments.removeObject(visitorDocument);
      entry.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
      this.flashMessages.showAndHideFlash('success', `${visitorDocument.title} removed from entry`);
    } catch (_) {
      entry.visitorDocuments.removeObject(entry.visitorDocumentForTemplate(userDocumentTemplate)!);
      entry.visitorDocuments.addObject(visitorDocument);
      this.flashMessages.showFlash('error', `Failed to remove ${visitorDocument.title} from entry`);
    }
  });
}
