import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, task, type Task } from 'ember-concurrency';
import type ApprovalStatus from 'garaje/models/approval-status';
import type { Report } from 'garaje/models/approval-status';
import type BlacklistFilter from 'garaje/models/blacklist-filter';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import _countBy from 'lodash/countBy';
import { filter, mapBy, unique } from 'macro-decorators';

export interface ApprovalReviewComponentSignature {
  Args: {
    approvalStatus: ApprovalStatus;
    approveTask: Task<void, []>;
    denyTask: Task<void, []>;
    isBulkReview: boolean;
    canReview: boolean;
  };
}

export default class ApprovalReviewComponent extends Component<ApprovalReviewComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;

  @filter('uniqueBlocklistFilterIdsFromReports', Boolean) blocklistFiltersIdsThatMatched!: string[];
  @mapBy('args.approvalStatus.blocklistReport', 'blacklistFilterId') blocklistFilterIdsFromReports!: Array<
    string | null
  >;
  @mapBy('currentBlocklistFilterReports', 'blacklistKeyword') currentBlocklistFilterMatchedValues!: Array<
    string | null
  >;
  @unique('blocklistFilterIdsFromReports') uniqueBlocklistFilterIdsFromReports!: Array<string | null>;

  @tracked blocklistFilters: BlacklistFilter[];
  @tracked currentBlocklistFilterIndex: number;

  constructor(owner: unknown, args: ApprovalReviewComponentSignature['Args']) {
    super(owner, args);
    this.blocklistFilters = [];
    this.currentBlocklistFilterIndex = 0;
    void this.loadBlocklistFiltersTask.perform();
  }

  get currentBlocklistFilter(): BlacklistFilter | undefined {
    return this.prioritizedBlocklistFilters[this.currentBlocklistFilterIndex];
  }

  get prioritizedBlocklistFilters(): BlacklistFilter[] {
    // if filter A has more reports than filter B - then filter A comes first
    const count = this.blocklistFilterReportCounts;

    return this.blocklistFilters.sort((a, b) => (count[b.id] ?? 0) - (count[a.id] ?? 0));
  }

  get blocklistFilterReportCounts(): { [key: string]: number } {
    return _countBy(this.blocklistFilterIdsFromReports);
  }

  get currentBlocklistFilterReports(): Report[] {
    return this.args.approvalStatus.blocklistReport.filter(
      (report) =>
        report.blacklistFilterId &&
        this.currentBlocklistFilter?.id &&
        parseInt(report.blacklistFilterId) === parseInt(this.currentBlocklistFilter.id),
    );
  }

  loadBlocklistFiltersTask = task(async () => {
    try {
      const blocklistFilters = await all(
        this.blocklistFiltersIdsThatMatched.map((id) => this.store.findRecord('blacklist-filter', id)),
      );

      this.blocklistFilters = blocklistFilters;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });
}
