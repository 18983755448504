import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import { isProductDisabled } from 'garaje/helpers/product-disabled';
import type DraftModel from 'garaje/models/draft';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { DraftSharingStrategy } from 'garaje/utils/enums';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST } from 'garaje/utils/roles';
import { Permission, PermissionType } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_VISIT_MAPS_EDIT = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST];

export default class MapAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureConfig: FeatureConfigService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canVisitMapsNav(): boolean {
    if (isProductDisabled('desks')) {
      return false;
    }

    return this.canEdit || this.canVisitLiveMaps || this.canVisitDrafts || this.canVisitNeighborhoods;
  }

  get canVisitLiveMaps(): boolean {
    const permissions = [Permission.MAPS_LIVE_READ, Permission.MAPS_FLOOR_READ, Permission.DESKS_NEIGHBORHOOD_READ];

    const employeeLocations = this.currentAdmin.employee?.employeeLocations;
    const locationId = this.state.currentLocation?.id;
    let isEmployeeAtLocation = false;
    if (employeeLocations && locationId) {
      employeeLocations.forEach((loc) => {
        if (String(loc.locationId) === locationId) {
          isEmployeeAtLocation = true;
        }
      });
    }
    return (
      this.featureConfig.isEnabled('maps.view') &&
      (this.featureFlags.isEnabled('map-authz-spaces2')
        ? this.authz.hasAllPermissionsAtCurrentLocation(permissions) || isEmployeeAtLocation
        : true)
    );
  }

  get canVisitDrafts(): boolean {
    return this.featureConfig.isEnabled('maps.drafts');
  }

  get canAccessOverview(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_MAPS_EDIT, roleNames)) && this.canEdit;
  }

  get canVisitNeighborhoods(): boolean {
    const permissions = [
      Permission.MAPS_FLOOR_READ,
      Permission.DESKS_NEIGHBORHOOD_CREATE,
      Permission.DESKS_NEIGHBORHOOD_UPDATE,
      Permission.DESKS_NEIGHBORHOOD_DELETE,
      Permission.DESKS_DESK_READ,
    ];

    if (this.featureFlags.isEnabled('map-authz-spaces2')) {
      return (
        this.featureConfig.isEnabled('desks.neighborhood') && this.authz.hasAllPermissionsAtCurrentLocation(permissions)
      );
    }

    const { roleNames } = this.currentAdmin;
    return (
      isPresent(_intersection(CAN_VISIT_MAPS_EDIT, roleNames)) && this.featureConfig.isEnabled('desks.neighborhood')
    );
  }

  get canVisitEdit(): boolean {
    return this.canEdit || this.canEditNeighborhoods;
  }

  get canEdit(): boolean {
    const permissions = [
      Permission.MAPS_LIVE_CREATE,
      Permission.MAPS_LIVE_UPDATE,
      Permission.MAPS_LIVE_DELETE,
      Permission.MAPS_FLOOR_CREATE,
      Permission.MAPS_FLOOR_UPDATE,
      Permission.MAPS_FLOOR_DELETE,
      Permission.MAPS_AREA_MAP_CREATE,
      Permission.MAPS_AREA_MAP_UPDATE,
      Permission.MAPS_AREA_MAP_DELETE,
      Permission.MAPS_FEATURE_CREATE,
      Permission.MAPS_FEATURE_UPDATE,
      Permission.MAPS_FEATURE_DELETE,
    ];

    if (this.featureFlags.isEnabled('map-authz-spaces2')) {
      return this.featureConfig.isEnabled('maps.edit') && this.authz.hasAllPermissionsAtCurrentLocation(permissions);
    }

    const { roleNames } = this.currentAdmin;
    return this.featureConfig.isEnabled('maps.edit') && isPresent(_intersection(CAN_VISIT_MAPS_EDIT, roleNames));
  }

  get canEditNeighborhoods(): boolean {
    if (this.canEdit) return true;

    return !!this.editableNeighborhoodIds.length;
  }

  get viewableDraftIds(): string[] {
    return Object.keys(this.authz.permissions[PermissionType.RESOURCE]?.maps?.draft || []);
  }

  get editableNeighborhoodIds(): string[] {
    return Object.keys(this.authz.permissions[PermissionType.RESOURCE]?.desks?.neighborhood || []);
  }

  get canCreateMap(): boolean {
    return (
      this.featureConfig.isEnabled('maps.edit') &&
      (this.featureFlags.isEnabled('map-authz-spaces2')
        ? this.authz.hasPermissionAtCurrentLocation(Permission.MAPS_LIVE_CREATE)
        : true)
    );
  }

  get canManageFloor(): boolean {
    const permissions = [Permission.MAPS_FLOOR_CREATE, Permission.MAPS_FLOOR_UPDATE, Permission.MAPS_FLOOR_DELETE];

    return (
      this.featureConfig.isEnabled('maps.edit') &&
      (this.featureFlags.isEnabled('map-authz-spaces2')
        ? this.authz.hasAllPermissionsAtCurrentLocation(permissions)
        : this.canEdit)
    );
  }

  @action
  canViewDraft(draft: DraftModel): boolean {
    return (
      this.canEdit ||
      this.viewableDraftIds.includes(draft.id) ||
      draft.sharingStrategy === DraftSharingStrategy.EMPLOYEES_AT_LOCATION
    );
  }
}
