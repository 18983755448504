/* eslint-disable no-console */
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class AnalyticsOccupancyIndexRoute extends Route {
  title = 'Occupancy · Analytics · Envoy';

  @service abilities;
  @service featureFlags;
  @service router;
  @service state;
  @service workplaceMetrics;
  @service locations;
  @service coho;
  @service statsig;

  queryParams = {
    location_id: { refreshModel: true },
  };

  setupController() {
    super.setupController(...arguments);
  }

  async activate() {
    super.activate(...arguments);
    const { location_id } = this.paramsFor(this.routeName);
    const protectedController = this.controllerFor('protected');
    if (location_id && this.state.currentLocation.id != location_id) {
      protectedController.send('switchLocation', location_id, 'analytics.occupancy.index');
      //if the location_id does not match with an active location that the user has access to, we redirect them to unauthorized - try again tests
      await this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
      const matchingLocation = get(this.locations, 'allActive').filter((entry) => entry.id === location_id);
      if (matchingLocation.length == 0) {
        this.router.transitionTo('unauthorized');
      }
    }
  }

  redirect() {
    // using the negative rather than affirmative to preserve code functionality from before
    const cannotUserVisitOccupancyAnalytic = this.abilities.cannot('visit occupancy-analytic');
    this.workplaceMetrics.trackEvent('OCCUPANCY_DASHBOARD_VISITED', { cannotUserVisitOccupancyAnalytic });
    this.coho.sendEvent(WorkplaceEventNames.OCCUPANCY_DASHBOARD_VISITED, { product: APP.WORKPLACE });
    this.statsig.logEvent(`coho_${WorkplaceEventNames.OCCUPANCY_DASHBOARD_VISITED}`, null, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });

    if (cannotUserVisitOccupancyAnalytic) {
      this.router.transitionTo('unauthorized');
    } else if (this.abilities.cannot('visit-occupancy analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }

  deactivate() {
    super.deactivate();
    const { location_id } = this.paramsFor(this.routeName);
    if (location_id) {
      this.transitionTo({ queryParams: { location_id: null } }); // eslint-disable-line ember/no-deprecated-router-transition-methods
    }
  }
}
