import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type { CookiesService } from 'ember-cookies/services/cookies';
import type CurrentLocationService from 'garaje/services/current-location';
import type LocalStorageService from 'garaje/services/local-storage';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import type ProtectedController from './controller';

export default class ProtectedRoute extends Route {
  declare controller: ProtectedController;

  @service declare currentLocation: CurrentLocationService;
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare cookies: CookiesService;
  @service declare session: SessionService;
  @service declare router: RouterService;

  beforeModel(transition: Transition): void {
    this.session.requireAuthentication(transition, 'login');

    // eslint-disable-next-line ember/no-controller-access-in-routes
    if (this.controller) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      void this.controller.getEmployeeGatingModal.perform();
      this.deactivate(transition);
    }
  }

  model(_: unknown, transition: Transition): Promise<void> {
    return this.state.initializeState.perform(transition?.from?.name).catch(throwUnlessTaskDidCancel);
  }

  afterModel(resolvedModel: unknown, transition: Transition): void {
    void super.afterModel(resolvedModel, transition);
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  redirect(_model: unknown, transition: Transition): Transition<unknown> | undefined {
    if (!this.state.currentCompany) {
      return this.router.transitionTo('profile');
    }

    const defaultRoute =
      this.cookies.read('signup_intent_url') ||
      this.localStorage.getItem('default_route') ||
      this.localStorage.getItem('intent_url');
    // allow direct access to some pages
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const intentUrl = <string>transition.intent?.url;
    const allowDirectAccess = [
      /^\/start-trial$/,
      /^\/landing-page$/,
      /^\/billing(\\.*)?/,
      /^\/integrations(\\.*)?/,
      /^\/employees(\\.*)?/,
      /^\/setup$/,
    ];

    if (
      intentUrl &&
      allowDirectAccess.some((reg) => {
        return reg.test(intentUrl);
      })
    ) {
      return;
    }

    if (defaultRoute && defaultRoute !== 'undefined') {
      this.cookies.clear('signup_intent_url');
      this.localStorage.setItem('default_route', '');
      this.localStorage.setItem('intent_url', '');
      return this.router.transitionTo(defaultRoute);
    }

    return;
  }

  // TODO: remove this hack when we use ember-wormhole to render the kiosk messages
  // instead from within the current-location template
  // this setupController is a noop so that model does not overwrite the current value of
  // model which has the kioskForceUpgradeMessage set in `current-location` route
  setupController(controller: ProtectedController, model: unknown, transition: Transition): void {
    //noop
    super.setupController(controller, model, transition);
    void controller.getEmployeeGatingModal.perform();
    void controller.getContactBdrModalTask.perform();
    void controller.checkSignupQuestions.perform();
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);

    this.currentLocation.teardownPubnub();
    this.currentLocation.teardownHeadway();
  }

  title(tokens: string[]): string {
    return `${tokens.reverse().join(' · ')} · Envoy`;
  }
}
