/* eslint-disable ember/no-computed-properties-in-native-classes */
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlowService from 'garaje/services/flow';
import type StateService from 'garaje/services/state';

export default class IntegrationsMenuComponent extends Component {
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service('flow') declare employeeScreeningFlow: FlowService;

  @reads('employeeScreeningFlow.activeEmployeeScreeningFlow')
  activeEmployeeScreeningFlow!: FlowService['activeEmployeeScreeningFlow'];
  @reads('state.currentLocation') currentLocation!: StateService['currentLocation'];

  get showAppsSettings(): boolean {
    return (
      this.featureFlags.isEnabled('show-acs-failure-alert-toggle') ||
      this.featureFlags.isEnabled('show-data-missing-alert-toggle') ||
      this.featureFlags.isEnabled('show-binary-download-modal') ||
      this.featureFlags.isEnabled('show-diplomat-client-config-modal')
    );
  }
}
