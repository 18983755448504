import Component from '@glimmer/component';
import { alias, or } from 'macro-decorators';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';

import { StatsigWorkplaceEventNames } from 'garaje/services/statsig';

/**
 * @param {Function}                      addLocation
 * @param {Function}                      boss
 * @param {Function}                      clickLogo
 * @param {Class<Company>}                company
 * @param {Function}                      editProfile
 * @param {boolean}                       isBoss
 * @param {Function}                      logout
 * @param {Class<Subscription>}           vrSubscription
 * @param {Function}                      switchLocationFromMenu
 * @param {Function}                      switchZoneFromMenu
 * @param {Function}                      transitionToLocationOverview
 * @param {boolean}                       isSidebarViewportHeight
 * @param {object}                        currentZone
 * @param {Array<object>}                 zones
 */
export default class SideBar extends Component {
  @service abilities;
  @service currentLocation;
  @service featureFlags;
  @service router;
  @service skinnyLocations;
  @service state;
  @service session;
  @service ajax;
  @service thinkific;
  @service currentAdmin;
  @service metrics;
  @service coho;
  @service statsig;

  @alias('state.resellerPartner') resellerPartner;
  @alias('state.hasChildren') hasChildren;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isReceptionist', 'currentAdmin.isLocationAdmin') isAdmin;

  get showGlobalMenu() {
    return this.router.currentRouteName.startsWith('location-overview');
  }

  get showMyLocations() {
    return this.router.currentRouteName.startsWith('my-locations');
  }

  get showConnectMenu() {
    return this.router.currentRouteName.startsWith('connect');
  }

  get isOnPropertyRoute() {
    return this.router.currentRouteName.startsWith('property');
  }

  get showPropertyMenu() {
    return this.isOnPropertyRoute || this.args.currentZone;
  }

  get hasLocationSwitcher() {
    return (
      this.skinnyLocations.readableByCurrentAdmin?.length > 1 ||
      this.abilities.can('create locations') ||
      this.abilities.can('visit global-overview') ||
      this.args.zones?.length >= 1
    );
  }

  get ariaNavigationLabel() {
    if (this.showGlobalMenu) {
      return 'Global Overview Menu';
    } else if (this.showMyLocations) {
      return 'My Locations Menu';
    } else if (this.showConnectMenu) {
      return 'Connect Overview Menu';
    } else if (this.showPropertyMenu) {
      return 'Property Menu';
    } else {
      return 'Location Menu';
    }
  }

  get showActivityLogLink() {
    return (
      this.args.vrSubscription?.isEnterprisePlan &&
      this.abilities.can('visit changelog') &&
      !this.isOnPropertyRoute &&
      !this.showConnectMenu
    );
  }

  get showBillingLink() {
    return (
      this.abilities.can('visit billing') &&
      (!this.resellerPartner || this.featureFlags.isEnabled('growth_billing_page_access_override')) &&
      !this.isOnPropertyRoute &&
      !this.showConnectMenu
    );
  }

  get showBuyDevicesAndBadgesLink() {
    return this.abilities.can('visit device') && !this.isOnPropertyRoute && !this.showConnectMenu;
  }

  get showLearningAcademyLink() {
    return this.featureFlags.isEnabled('learning-academy-in-dashboard') && this.isAdmin;
  }

  @action
  trackClickEvent(trackingString) {
    if (trackingString && trackingString.length > 0) {
      this.metrics.trackEvent(trackingString);
    }
  }

  @action
  trackActivityLog() {
    this.trackClickEvent('SideBar-ActivityLog');
    this.coho.sendEvent(WorkplaceEventNames.ACTIVITY_LOG_CLICKED, { product: APP.WORKPLACE });
    this.statsig.logEvent(StatsigWorkplaceEventNames.ActivityLogClicked, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });
  }

  @action
  async redirectToThinkific(event) {
    event.preventDefault();
    event.stopPropagation();
    this.trackClickEvent('SideBar-LearningCenter');
    const redirectUrl = await this.thinkific.getThinkificUrlTask.perform();
    window.location = redirectUrl;
  }
}
