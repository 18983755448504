import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { AsyncHasMany } from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';

interface DeviceNotificationsComponentSignature {
  Args: {
    deviceNotificationsHasChanges: boolean;
    hasDevices: boolean;
    location: LocationModel;
    onUpdate: (changes: { hasChanges: boolean }) => void;
    searchUsersTask: Task<void, [string, AsyncHasMany<UserModel>]>;
  };
}

export default class DeviceNotificationsComponent extends Component<DeviceNotificationsComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare router: RouterService;

  @tracked isOpen = false;
  @tracked isShowingRollbackConfirmation = false;

  enableTask = dropTask(async () => {
    try {
      this.args.location.deviceNotificationsEnabled = true;
      await this.args.location.save();
      this.isOpen = true;
      void this.args.location.pinDeviceContacts();
      this.flashMessages.showAndHideFlash('success', 'iPad notifications enabled!');
      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_IPAD_STATUS_EMAIL['ENABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      this.isOpen = false;
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  disableTask = dropTask(async () => {
    try {
      this.args.location.deviceNotificationsEnabled = false;
      await this.args.location.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'iPad notifications disabled!');
      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_NOTIFICATIONS_DEVICE_IPAD_STATUS_EMAIL['DISABLED'],
      );
    } catch (e) {
      this.args.location.rollbackAttributes();
      this.isOpen = true;
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  saveTask = dropTask(async () => {
    try {
      await this.args.location.save();
      await this.args.location.pinDeviceContacts();
      this.args.onUpdate({ hasChanges: false });
      this.isOpen = false;
      void this.impressions.postImpression.perform(IMPRESSION_NAMES.IPAD_ALERTS_CONFIG_UPDATED);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      this.args.onUpdate({ hasChanges: true });
    }
  });

  get isVirtualFrontDesk(): boolean {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  editTask = dropTask(async () => {
    await this.args.location.pinDeviceContacts();
    this.isOpen = true;
  });

  searchTask = restartableTask(async (term: string) => {
    await timeout(zft(250));
    return await this.args.searchUsersTask.perform(term, this.args.location.deviceContacts);
  });

  updateDeviceContactsTask = restartableTask(async (users: UserModel[]) => {
    await this.args.location.updateDeviceContacts(users);
    this.args.onUpdate({ hasChanges: true });
  });

  @action
  close(): void {
    if (this.args.deviceNotificationsHasChanges) {
      this.isShowingRollbackConfirmation = true;
    } else {
      this.isOpen = false;
    }
  }

  @action
  rollbackAndClose(): void {
    this.isShowingRollbackConfirmation = false;
    this.args.location.rollbackDeviceContacts();
    this.isOpen = false;
    this.args.onUpdate({ hasChanges: false });
  }
}
