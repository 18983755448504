"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const client_core_1 = require("@statsig/client-core");
class EvaluationStore {
    constructor(_sdkKey) {
        this._sdkKey = _sdkKey;
        this._rawValues = null;
        this._values = null;
        this._source = 'Uninitialized';
        this._lcut = 0;
        this._receivedAt = 0;
        this._bootstrapMetadata = null;
        this._warnings = new Set();
    }
    reset() {
        this._values = null;
        this._rawValues = null;
        this._source = 'Loading';
        this._lcut = 0;
        this._receivedAt = 0;
        this._bootstrapMetadata = null;
    }
    finalize() {
        if (this._values) {
            return;
        }
        this._source = 'NoValues';
    }
    getValues() {
        return this._rawValues
            ? (0, client_core_1._typedJsonParse)(this._rawValues, 'has_updates', 'EvaluationStoreValues')
            : null;
    }
    setValues(result, user) {
        if (!result) {
            return false;
        }
        const values = (0, client_core_1._typedJsonParse)(result.data, 'has_updates', 'EvaluationResponse');
        if (values == null) {
            return false;
        }
        this._source = result.source;
        if ((values === null || values === void 0 ? void 0 : values.has_updates) !== true) {
            return true;
        }
        this._rawValues = result.data;
        this._lcut = values.time;
        this._receivedAt = result.receivedAt;
        this._values = values;
        this._bootstrapMetadata = this._extractBootstrapMetadata(result.source, values);
        if (result.source && values.user) {
            this._setWarningState(user, values);
        }
        return true;
    }
    getGate(name) {
        var _a;
        return this._getDetailedStoreResult((_a = this._values) === null || _a === void 0 ? void 0 : _a.feature_gates, name);
    }
    getConfig(name) {
        var _a;
        return this._getDetailedStoreResult((_a = this._values) === null || _a === void 0 ? void 0 : _a.dynamic_configs, name);
    }
    getLayer(name) {
        var _a;
        return this._getDetailedStoreResult((_a = this._values) === null || _a === void 0 ? void 0 : _a.layer_configs, name);
    }
    getParamStore(name) {
        var _a;
        return this._getDetailedStoreResult((_a = this._values) === null || _a === void 0 ? void 0 : _a.param_stores, name);
    }
    _extractBootstrapMetadata(source, values) {
        if (source !== 'Bootstrap') {
            return null;
        }
        const bootstrapMetadata = {};
        if (values.user) {
            bootstrapMetadata.user = values.user;
        }
        if (values.sdkInfo) {
            bootstrapMetadata.generatorSDKInfo = values.sdkInfo;
        }
        bootstrapMetadata.lcut = values.time;
        return bootstrapMetadata;
    }
    _getDetailedStoreResult(lookup, name) {
        let result = null;
        if (lookup) {
            result = lookup[name] ? lookup[name] : lookup[(0, client_core_1._DJB2)(name)];
        }
        return {
            result,
            details: this._getDetails(result == null),
        };
    }
    _setWarningState(user, values) {
        var _a;
        const stableID = client_core_1.StableID.get(this._sdkKey);
        if (((_a = user.customIDs) === null || _a === void 0 ? void 0 : _a.stableID) !== stableID) {
            this._warnings.add('StableIDMismatch');
            return;
        }
        if ('user' in values) {
            const bootstrapUser = values['user'];
            if ((0, client_core_1._getFullUserHash)(user) !== (0, client_core_1._getFullUserHash)(bootstrapUser)) {
                this._warnings.add('PartialUserMatch');
            }
        }
    }
    getCurrentSourceDetails() {
        if (this._source === 'Uninitialized' || this._source === 'NoValues') {
            return { reason: this._source };
        }
        const sourceDetails = {
            reason: this._source,
            lcut: this._lcut,
            receivedAt: this._receivedAt,
        };
        if (this._warnings.size > 0) {
            sourceDetails.warnings = Array.from(this._warnings);
        }
        return sourceDetails;
    }
    _getDetails(isUnrecognized) {
        var _a, _b;
        const sourceDetails = this.getCurrentSourceDetails();
        let reason = sourceDetails.reason;
        const warnings = (_a = sourceDetails.warnings) !== null && _a !== void 0 ? _a : [];
        if (this._source === 'Bootstrap' && warnings.length > 0) {
            reason = reason + warnings[0];
        }
        if (reason !== 'Uninitialized' && reason !== 'NoValues') {
            const subreason = isUnrecognized ? 'Unrecognized' : 'Recognized';
            reason = `${reason}:${subreason}`;
        }
        const bootstrapMetadata = this._source === 'Bootstrap'
            ? (_b = this._bootstrapMetadata) !== null && _b !== void 0 ? _b : undefined
            : undefined;
        if (bootstrapMetadata) {
            sourceDetails.bootstrapMetadata = bootstrapMetadata;
        }
        return Object.assign(Object.assign({}, sourceDetails), { reason });
    }
}
exports.default = EvaluationStore;
