import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';

export default class ManageSettingsAbility extends Ability {
  @service declare abilities: AbilitiesService;
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canVisitLocal(): boolean {
    return (
      this.canVisitLocalAdmins ||
      this.canVisitLocalCustomRoles ||
      this.canUpdateLocalSettings ||
      this.canVisitLocalAuditLog
    );
  }

  get canVisitLocalAdmins(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_ADMIN_READ);
  }

  get canVisitLocalCustomRoles(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.USER_MANAGEMENT_CUSTOM_ROLE_READ);
  }

  // TODO: gate location settings on a specific, to-be-created authz permission
  get canUpdateLocalSettings(): boolean {
    return this.abilities.can('edit location-setting');
  }

  get canVisitLocalAuditLog(): boolean {
    return this.featureFlags.isEnabled('audit-log') && this.currentAdmin.roleNames.includes(GLOBAL_ADMIN);
  }

  get canVisitGlobal(): boolean {
    return (
      this.canVisitAccountSettings ||
      this.canVisitGlobalAdmins ||
      this.canVisitGlobalCustomRoles ||
      this.canVisitGlobalAuditLog
    );
  }

  get canVisitAccountSettings(): boolean {
    return (
      this.canAccessInactivityLogout &&
      this.authz.hasPermissionAtCurrentCompany(Permission.ACCOUNT_MANAGEMENT_COMPANY_CONFIG_UPDATE)
    );
  }

  get canVisitGlobalAdmins(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_ADMIN_READ);
  }

  get canVisitGlobalCustomRoles(): boolean {
    return this.authz.hasPermissionAtCurrentCompany(Permission.USER_MANAGEMENT_CUSTOM_ROLE_READ);
  }

  get canVisitGlobalAuditLog(): boolean {
    return this.featureFlags.isEnabled('audit-log') && this.currentAdmin.roleNames.includes(GLOBAL_ADMIN);
  }

  get canAccessInactivityLogout(): boolean {
    return !!this.state.features?.canAccessInactivityLogout;
  }
}
