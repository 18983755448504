import { get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type Delivery from 'garaje/models/delivery';
import type DeliveryArea from 'garaje/models/delivery-area';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';
import { or, reads } from 'macro-decorators';

const CAN_MANAGE_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_EDIT_NICKNAMES_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_EDIT_NOTIFICATION_CONFIG_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_TRIAL_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST]);

const CAN_VIEW_PROOF_OF_PICK_UP_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN]);

const CAN_VIEW_ONBOARDING_STEPS_ROLES = Object.freeze([GLOBAL_ADMIN, LOCATION_ADMIN]);

const CAN_EDIT_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, DELIVERIES_ADMIN];

const CAN_CHANGE_STATUS_FOR_SECURE_DELIVERY_AREA_ROLES = Object.freeze([
  GLOBAL_ADMIN,
  LOCATION_ADMIN,
  RECEPTIONIST,
  DELIVERIES_ADMIN,
]);

export default class DeliveryAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  deliveryArea: DeliveryArea | null = null;
  selectedDeliveries: Array<Delivery> | null = null;

  @reads('state.deliveriesSubscription') deliveriesSubscription!: StateService['deliveriesSubscription'];
  @reads('state.features.canAccessDeliveriesApplication') canAccessDeliveries!: boolean;
  @reads('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];

  get isEnabledProduct(): boolean {
    return !!this.state?.features?.canAccessDeliveriesApplication;
  }

  get canManage(): boolean {
    const { roleNames } = this.currentAdmin;
    return (
      !!this.state.features?.canAccessDeliveriesApplication && isPresent(_intersection(CAN_MANAGE_ROLES, roleNames))
    );
  }

  get canEditNicknames(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_NICKNAMES_ROLES, roleNames));
  }

  get canEditNotificationConfig(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_EDIT_NOTIFICATION_CONFIG_ROLES, roleNames));
  }

  get canTrial(): boolean {
    const { roleNames } = this.currentAdmin;
    return (
      isPresent(_intersection(CAN_TRIAL_ROLES, roleNames)) && !this.canAccessDeliveries && !this.workplaceSubscription
    );
  }

  get canViewProofOfPickUp(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VIEW_PROOF_OF_PICK_UP_ROLES, roleNames));
  }

  get canViewOnboardingSteps(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VIEW_ONBOARDING_STEPS_ROLES, roleNames));
  }

  get canChangeStatus(): boolean {
    const deliveryArea = this.deliveryArea;
    const { roleNames } = this.currentAdmin;

    const canChangeStatusRoles = CAN_CHANGE_STATUS_FOR_SECURE_DELIVERY_AREA_ROLES;

    // eslint-disable-next-line ember/no-get
    if (deliveryArea && get(deliveryArea, 'requiresSecurePickUp')) {
      return isPresent(_intersection(canChangeStatusRoles, roleNames));
    }

    return !!deliveryArea;
  }

  get canEdit(): boolean {
    const { roleNames } = this.currentAdmin;
    const canEditRoles = CAN_EDIT_ROLES;

    return isPresent(_intersection(canEditRoles, roleNames));
  }

  get canChangeStatuses(): boolean {
    const selectedDeliveries = this.selectedDeliveries;
    const { roleNames } = this.currentAdmin;

    const canChangeStatusRoles = CAN_CHANGE_STATUS_FOR_SECURE_DELIVERY_AREA_ROLES;

    if (
      selectedDeliveries &&
      // eslint-disable-next-line ember/no-get
      selectedDeliveries.every((item) => get(item.deliveryArea, 'requiresSecurePickUp'))
    ) {
      return isPresent(_intersection(canChangeStatusRoles, roleNames));
    }

    return !!selectedDeliveries;
  }

  /**
   * @returns {boolean} whether user can visit *any* deliveries routes
   */
  @or(
    'isEnabledProduct',
    'canManage',
    'canEditNicknames',
    'canEditNotificationConfig',
    'canTrial',
    'canViewProofOfPickUp',
    'canViewOnboardingSteps',
    'canChangeStatus',
    'canEdit',
    'canChangeStatuses',
  )
  canVisit!: boolean;
}
