import { A } from '@ember/array';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type SessionStorageService from 'garaje/services/session-storage';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import type { Constructor } from 'type-fest';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
function withLocationStateFunctionality<TBase extends Constructor<any>>(Base: TBase) {
  class withLocationStateFunctionality extends Base {
    @service declare store: Store;
    @service declare state: StateService;
    @service declare featureFlags: FeatureFlagsService;
    @service declare contextSwitcher: ContextSwitcherService;
    @service declare skinnyLocations: SkinnyLocationsService;
    @service declare metrics: MetricsService;
    @service declare abilities: AbilitiesService;
    @service declare sessionStorage: SessionStorageService;

    @tracked loadedFrom: string | undefined = undefined;

    preloadLocations = dropTask(async (loadedFrom?: string) => {
      this.loadedFrom = loadedFrom;
      const queue = [this.defaultLocationId(), this.cachedLocationId];
      const locationIds = compact(uniq((await Promise.all(queue)) as string[]));

      if (!locationIds.length) {
        return;
      }

      const promises = locationIds.map((id) => this.fetchLocation(id));

      await Promise.all(promises);
    });

    initLocationState = dropTask(async (loadedFrom?: string) => {
      this.loadedFrom = loadedFrom;

      const companyId = this.state._companyId;

      if (!companyId) {
        return;
      }

      // Attempt to load default location

      const defaultLocationId = (await this.defaultLocationId()) as string;
      const defaultLocation = await this.fetchLocation(defaultLocationId);

      if (defaultLocation) {
        // set session storage when the user has been sent to the default location
        this.sessionStorage.setItem('defaultLocationId', defaultLocation.id);
        this.state.currentLocation = defaultLocation;
        return defaultLocation;
      }

      // Attempt to load cached location

      const cachedLocationId = this.cachedLocationId;
      const cachedLocation = await this.fetchLocation(cachedLocationId);

      if (cachedLocation) {
        this.state.currentLocation = cachedLocation;
        return cachedLocation;
      }

      // Do not load current location if we are loading into a zone

      if (this.state?.zoneState?.currentZone) {
        return;
      }

      // Load most relevant location

      const relevantLocationId = (await this.relevantLocationId()) as string;

      if (!relevantLocationId) {
        return;
      }

      const relevantLocation = await this.store.findRecord('location', relevantLocationId);

      this.state.currentLocation = relevantLocation;

      return relevantLocation;
    });

    get cachedLocationId(): string | null {
      return this.contextSwitcher.locationId;
    }

    async fetchLocation(id?: string | null): Promise<LocationModel | undefined> {
      try {
        const companyId = this.state._companyId;

        if (!id || !companyId) {
          return;
        }

        const location = await this.store.findRecord('location', id);

        if (!location || location.belongsTo('company').id() !== companyId) {
          return;
        }

        // employees should not see disabled locations
        if (!isBlank(location.disabledToEmployeesAt) && !this.abilities.can('read-disabled-location locations')) {
          return;
        }

        return location;
      } catch (_e) {
        return;
      }
    }

    async relevantLocationId(): Promise<string | undefined> {
      const companyId = this.state._companyId;

      await this.skinnyLocations.loadAllTask.perform();

      const locationsForCompany = A(
        this.store.peekAll('skinny-location').filter((location) => location.belongsTo('company').id() === companyId),
      ).sortBy('disabledToEmployeesAt');

      this.metrics.trackEvent('Fetching locations for company', { locations: locationsForCompany.toString() });

      if (locationsForCompany.length === 0) {
        return;
      }

      return locationsForCompany.firstObject!.id;
    }

    async defaultLocationId(): Promise<string | undefined> {
      const loadedFrom = this.loadedFrom;

      // only check for default location if user has just logged in or opened up a new tab
      if (loadedFrom && loadedFrom !== 'login') return;

      // check session storage, if the user was already sent to the default location, don't use it
      const cachedDefaultLocationId = this.sessionStorage.getItem('defaultLocationId');
      if (cachedDefaultLocationId) return;

      try {
        // if the user has an employee with a default location, send them to that location
        const locationRoles = this.state.currentUser?.locationRoles;

        if (!locationRoles || locationRoles.toArray().length === 0) return;

        const locationRole = locationRoles.find((role) => role.roleName === 'Employee');

        // unconfirmed location roles will return a 403 when fetching employee
        if (!locationRole?.isConfirmed) return;

        const employee = await locationRole.employee;

        // employee has no default location set
        if (!employee?.defaultLocationId) return;

        return employee.defaultLocationId.toString();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        // ignore all errors
        return;
      }
    }
  }

  return withLocationStateFunctionality;
}

export default withLocationStateFunctionality;
