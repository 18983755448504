import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import fetch from 'fetch';
import type LocationModel from 'garaje/models/location';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import type { DiplomatAblePluginOption } from 'garaje/utils/binary-download-options';
import { DIPLOMAT_ABLE_PLUGIN_OPTIONS } from 'garaje/utils/binary-download-options';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface WorkplaceSettingsBinaryDownloadDiplomatConfigdModalArgs {
  location: LocationModel;
  plugins: PluginModel[];
  pluginInstalls: PluginInstallModel[];
  diplomatClients: DiplomatClient[];
  onUpdateDiplomatClient: () => Promise<void>;
}

interface DiplomatClientTableRow {
  id: string;
  secret: string;
  enabled: boolean;
  connectionStatus: string;
  pluginName: string;
  pluginId: string;
  pluginInstallId: number;
  internalUrl: string;
}

interface DiplomatClient {
  id: string;
  secret: string;
  location_id: number;
  enabled: boolean;
  plugin_install_id: number;
  plugin_id: string;
  internal_url: string;
  connected: boolean;
  created_at: string;
  updated_at: string;
  plugin_name?: string;
}

/**
 * @param {Class<Location>} location Location
 * @param {Boolean} [disabled] If true, open button is disabled
 */
export default class WorkplaceSettingsBinaryDownloadDiplomatConfigdModal extends Component<WorkplaceSettingsBinaryDownloadDiplomatConfigdModalArgs> {
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @service declare state: StateService;
  @service declare ajax: AjaxFetchService;

  @tracked isSettingsPanelOpen = false;
  @tracked diplomatEnabledPluginsOptions: DiplomatAblePluginOption[] = [];
  @tracked showCreateDiplomatClient = false;
  @tracked showEditDiplomatClient = false;
  @tracked selectedClient: DiplomatClientTableRow | null = null;
  @tracked clientToDelete: DiplomatClient | null = null;

  diplomatAblePluginOptions: DiplomatAblePluginOption[] = DIPLOMAT_ABLE_PLUGIN_OPTIONS;

  /**
   * Shows settings panel
   */
  @action
  showSettings(): void {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_BINARY_DOWNLOAD_DIPLOMAT_CONFIG_EDIT_BUTTON_CLICKED');
    this.isSettingsPanelOpen = true;
  }

  /**
   * Hides settings panel
   */
  @action
  hideSettings(): void {
    this.isSettingsPanelOpen = false;
  }

  @action
  editClient(client: DiplomatClientTableRow): void {
    this.setSelectedClient(client);
    this.showEditDiplomatClient = true;
  }

  @action
  setSelectedClient(client: DiplomatClientTableRow): void {
    this.selectedClient = client;
  }

  get formattedDiplomatClients(): object[] {
    return this.args.diplomatClients.map((diplomatClient) => {
      const { plugin_id } = diplomatClient;
      const plugin = this.args.plugins.find((plugin) => plugin.id === plugin_id);
      const formattedDiplomatClient = { ...diplomatClient };
      formattedDiplomatClient['plugin_name'] = plugin ? plugin.name : 'Unknown integration';
      return formattedDiplomatClient;
    });
  }

  deleteClientTask = task(async () => {
    if (!this.clientToDelete) {
      return;
    }
    const url: string = urlBuilder.diplomatServer.deleteDiplomatClientUrl(this.clientToDelete.id);
    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        credentials: 'include',
      });
      this.clientToDelete = null;
      await this.args.onUpdateDiplomatClient();
      this.flashMessages.showAndHideFlash('success', 'Successfully deleted.');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
