import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';

export default class CreateNeighborhoodModalComponent extends Component {
  @service state;
  @service store;
  @service flashMessages;
  @service metrics;
  @service coho;
  @service statsig;

  @tracked newNeighborhoodName = '';
  @tracked neighborhoodNameError = [];

  @dropTask
  *createNeighborhoodTask(e) {
    const { createNeighborhoodTask, onCreateNeighborhood } = this.args;
    e.preventDefault();
    if (!this.newNeighborhoodName) {
      this.neighborhoodNameError = ['Please enter a neighborhood name'];
    } else if (this.isDuplicateName()) {
      this.neighborhoodNameError = ['Neighborhood name already exists'];
    }

    if (!this.neighborhoodNameError.length) {
      const neighborhood = this.store.createRecord('neighborhood', {
        location: this.state.currentLocation,
        name: this.newNeighborhoodName,
      });
      try {
        yield neighborhood.save();
        this.flashMessages.showAndHideFlash('success', 'Neighborhood created');
        this.metrics.trackEvent('Created neighborhood', {
          neighborhoodId: neighborhood.id,
          neighborhoodName: neighborhood.name,
        });
        this.coho.sendEvent(WorkplaceEventNames.NEIGHBORHOOD_CREATED, { product: APP.WORKPLACE });
        this.statsig.logEvent(`coho_${WorkplaceEventNames.NEIGHBORHOOD_CREATED}`, null, {
          product: APP.WORKPLACE,
          location_id: this.state?.currentLocation?.id,
        });
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
      }

      if (onCreateNeighborhood) {
        onCreateNeighborhood(neighborhood);
      }
      this.newNeighborhoodName = '';
      this.neighborhoodNameError = [];
      createNeighborhoodTask.last.continue();
    }
  }

  @action
  setInitialNeighborhoodName() {
    const { neighborhoodName } = this.args;
    if (neighborhoodName) {
      this.newNeighborhoodName = neighborhoodName;
    }
  }

  @action
  onCancel() {
    const { createNeighborhoodTask } = this.args;
    this.newNeighborhoodName = '';
    this.neighborhoodNameError = [];
    createNeighborhoodTask.last.abort();
  }

  @action
  onNeighborhoodNameChange(name) {
    this.newNeighborhoodName = name;
    if (name === '') {
      this.neighborhoodNameError = ['Please enter a neighborhood name'];
    } else if (this.isDuplicateName()) {
      this.neighborhoodNameError = ['Neighborhood name already exists'];
    } else {
      this.neighborhoodNameError = [];
    }
  }

  isDuplicateName() {
    const { neighborhoods } = this.args;
    return neighborhoods.any(
      (neighborhood) =>
        neighborhood.name === this.newNeighborhoodName &&
        get(neighborhood.location, 'id') === this.state.currentLocation.id,
    );
  }
}
