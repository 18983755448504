export enum DeliveryMethods {
  Sms = 'SMS',
  Email = 'Email',
  Push = 'Push Notification',
  Slack = 'Slack',
  Teams = 'Microsoft Teams',
}

export type DeliveryMethod = 'sms' | 'email' | 'push' | 'slack' | 'ms_teams';

export enum GQLDeliveryMethods {
  Sms = 'SMS',
  Email = 'EMAIL',
  MobilePush = 'MOBILE_PUSH',
  Slack = 'SLACK',
  Teams = 'TEAMS',
}

export type GQLDeliveryMethod = 'SMS' | 'EMAIL' | 'MOBILE_PUSH' | 'SLACK' | 'TEAMS';
