import { action, setProperties } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import fade from 'ember-animated/transitions/fade';
import { task, timeout, type TaskInstance, allSettled } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type { FetchError } from 'garaje/services/ajax/errors';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';
import _sample from 'lodash/sample';
import { alias } from 'macro-decorators';

type ResponseData = {
  data: {
    'currently-signed-in': number;
    'currently-signed-in-change': number;
    'expected-today': number;
    'expected-today-change': number;
    'visitors-weekly-change': number;
    'currently-waiting-for-pickup': number;
    'currently-waiting-for-pickup-change': number;
    'scanned-this-week': number;
    'scanned-this-week-change': number;
    'auto-released': number;
    'auto-released-change': number;
    booked: number;
    'booked-change': number;
  };
};

const DATA_POLLING_TIMEOUTS = [3500, 4500, 6500, 7500, 8500];

export default class LocationOverview extends Component<{
  visitorsSetupComplete: boolean;
  workplaceSetupComplete: boolean;
  showVisitorsStats: boolean;
  showDeliveriesStats: boolean;
  roomsStepsComplete: boolean;
  logEvent: (params: unknown) => void;
}> {
  @service declare state: StateService;
  @service declare ajax: AjaxService;
  @service declare session: SessionService;

  @tracked pollingInstance: TaskInstance<void> | null = null;
  @tracked isLoading: boolean = true;
  @tracked currentlySignedIn: number = 0;
  @tracked currentlySignedInChange: number = 0;
  @tracked expectedToday: number = 0;
  @tracked expectedTodayChange: number = 0;
  @tracked visitorsWeeklyChange: number = 0;
  @tracked currentlyWaitingForPickup: number = 0;
  @tracked currentlyWaitingForPickupChange: number = 0;
  @tracked scannedThisWeek: number = 0;
  @tracked scannedThisWeekChange: number = 0;
  @tracked autoReleased: number = 0;
  @tracked autoReleasedChange: number = 0;
  @tracked booked: number = 0;
  @tracked bookedChange: number = 0;

  @alias('state.currentLocation') declare currentLocation: LocationModel;
  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];

  transition = fade;

  @action
  onInsert(): void {
    this.pollingInstance = this.pollForChangesTask.perform();
  }

  willDestroy(...args: Parameters<Component['willDestroy']>): void {
    super.willDestroy(...args);
    if (this.pollingInstance) {
      void this.pollingInstance.cancel();
    }
  }

  get hasAccessToWorkplace(): boolean {
    return !!this.state.features?.canAccessWorkplaceApplication;
  }

  pollForChangesTask = task({ drop: true }, async () => {
    while (config.environment !== 'test') {
      await allSettled([
        this.fetchVisitorsDataTask.perform(),
        this.fetchDeliveriesDataTask.perform(),
        this.fetchRoomsDataTask.perform(),
      ]);

      this.isLoading = false;

      await timeout(<number>_sample(DATA_POLLING_TIMEOUTS));
    }
  });

  fetchVisitorsDataTask = task({ drop: true }, async () => {
    if (!this.args.showVisitorsStats) return;

    const url = urlBuilder.v2.visitorsLandingPage(this.currentLocation.id);

    try {
      const {
        data: {
          'currently-signed-in': currentlySignedIn,
          'currently-signed-in-change': currentlySignedInChange,
          'expected-today': expectedToday,
          'expected-today-change': expectedTodayChange,
          'visitors-weekly-change': visitorsWeeklyChange,
        },
      } = await this.ajax.request<ResponseData>(url);
      setProperties(this, {
        currentlySignedIn,
        currentlySignedInChange,
        expectedToday,
        expectedTodayChange,
        visitorsWeeklyChange,
      });
    } catch (error: unknown) {
      if (error && (<FetchError>error).status === 401) {
        await this.pollingInstance!.cancel();
        await this.session.invalidate();
      }
    }
  });

  fetchDeliveriesDataTask = task({ drop: true }, async () => {
    if (!this.args.showDeliveriesStats) return;

    const url = urlBuilder.v2.deliveriesLandingPage(this.currentLocation.id);

    try {
      const {
        data: {
          'currently-waiting-for-pickup': currentlyWaitingForPickup,
          'currently-waiting-for-pickup-change': currentlyWaitingForPickupChange,
          'scanned-this-week': scannedThisWeek,
          'scanned-this-week-change': scannedThisWeekChange,
        },
      } = await this.ajax.request<ResponseData>(url);
      setProperties(this, {
        currentlyWaitingForPickup,
        currentlyWaitingForPickupChange,
        scannedThisWeek,
        scannedThisWeekChange,
      });
    } catch (error: unknown) {
      if (error && (<FetchError>error).status === 401) {
        await this.pollingInstance!.cancel();
        await this.session.invalidate();
      }
    }
  });

  fetchRoomsDataTask = task({ drop: true }, async () => {
    if (!this.args.roomsStepsComplete) return;

    const url = urlBuilder.v2.roomsLandingPage(this.currentLocation.id);

    try {
      const {
        data: {
          'auto-released': autoReleased,
          'auto-released-change': autoReleasedChange,
          booked: booked,
          'booked-change': bookedChange,
        },
      } = await this.ajax.request<ResponseData>(url);
      setProperties(this, {
        autoReleased,
        autoReleasedChange,
        booked,
        bookedChange,
      });
    } catch (error: unknown) {
      if (error && (<{ status: number }>error).status === 401) {
        await this.pollingInstance!.cancel();
        await this.session.invalidate();
      }
    }
  });
}
