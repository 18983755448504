import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type CohoService from 'garaje/services/coho';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';

export default class DeliveriesAnalyticsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare coho: CohoService;
  @service declare statsig: StatsigService;

  queryParams = ['deliveryAreaId'];

  // workaround for performance issue when tracking queryParams
  // https://github.com/emberjs/ember.js/issues/18715
  @tracked _deliveryAreaId: string = '';

  get deliveryAreaId(): string {
    return this._deliveryAreaId;
  }

  set deliveryAreaId(value: string) {
    this._deliveryAreaId = value;
  }

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    if (!companyId || !locationId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId, locationId);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    return urlBuilder.embeddedDeliveriesAnalyticsUrl(companyId, locationId, this.deliveryAreaId);
  }

  @action
  logViewToCohoAndStatsig(): void {
    this.coho.sendEvent(WorkplaceEventNames.VIEW_ANALYTICS_DASHBOARD, { product: APP.WORKPLACE });
    this.statsig.logEvent(`coho_${WorkplaceEventNames.VIEW_ANALYTICS_DASHBOARD}`, null, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });
  }

  async getSigmaIframeUrl(companyId: string, locationId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getLocationSigmaUrl(companyId, locationId, 'deliveries');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }
}
