import { DeliveryMethod, GQLDeliveryMethods } from '../types/delivery-method';

export const ALL_DELIVERY_METHODS: DeliveryMethod[] = ['email', 'push', 'slack', 'sms', 'ms_teams'];

export const MESSAGE_DELIVERY_MAP: Record<GQLDeliveryMethods, DeliveryMethod> = {
  [GQLDeliveryMethods.Email]: 'email',
  [GQLDeliveryMethods.MobilePush]: 'push',
  [GQLDeliveryMethods.Slack]: 'slack',
  [GQLDeliveryMethods.Sms]: 'sms',
  [GQLDeliveryMethods.Teams]: 'ms_teams',
};

export const MESSAGE_DELIVERY_LABEL_MAP: Record<GQLDeliveryMethods, string> = {
  [GQLDeliveryMethods.Email]: 'Email',
  [GQLDeliveryMethods.MobilePush]: 'Mobile Push',
  [GQLDeliveryMethods.Slack]: 'Slack',
  [GQLDeliveryMethods.Sms]: 'SMS',
  [GQLDeliveryMethods.Teams]: 'Microsoft Teams',
};
