import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import { alias } from 'macro-decorators';
import { RoleScopeType } from 'garaje/utils/custom-roles';
import { RoleId } from 'garaje/utils/custom-roles';

/**
 * @param {Array<string>}             locationsFilter
 * @param {boolean}                   refreshingModel
 * @param {Function}                  updateLocationsFilter
 * @param {Function}                  updateRolesFilter
 * @param {Array<Location>}           selectedLocations
 * @param {Array<Location>}           locations
 * @param {Array}                     rolesFilter
 */
export default class AdminRolesFilters extends Component {
  @service abilities;
  @service store;
  @service currentAdmin;
  @service state;
  @service authz;

  @tracked selectedLocationIds = '';
  @tracked groups = [];

  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;

  constructor(owner, args) {
    super(owner, args);

    if (this.args.selectedLocations) {
      const locationIds = [];
      this.args.selectedLocations.forEach((loc) => {
        locationIds.push(get(loc, 'id'));
      });
      this.selectedLocationIds = locationIds.join(',');
    }

    this.computeGroupsTask.perform();
  }

  get roleOptions() {
    const authzRoles = this.authz.roles;
    const companyRoles = [];
    const locationRoles = [];
    const zoneRoles = [];
    authzRoles.forEach((authzRole) => {
      if (!authzRole.deactivated) {
        switch (authzRole.roleScope) {
          case RoleScopeType.COMPANY:
            if (authzRole.id === RoleId.ANALYTICS_VIEWER) {
              // only show role for workplace premium, premium-plus accounts
              if (this.state.features?.canAccessAnalyticsViewerRole) {
                companyRoles.push(authzRole);
              }
            } else if (authzRole.id !== RoleId.COMPANY_START_FROM_SCRATCH) {
              companyRoles.push(authzRole);
            }
            break;
          case RoleScopeType.LOCATION:
            if (authzRole.id !== RoleId.EMPLOYEE && authzRole.id !== RoleId.LOCATION_START_FROM_SCRATCH) {
              locationRoles.push(authzRole);
            }
            break;
          case RoleScopeType.ZONE:
            zoneRoles.push(authzRole);
            break;
        }
      }
    });

    const roleOptions = [
      {
        groupName: 'Company roles',
        options: companyRoles,
      },
      {
        groupName: 'Location roles',
        options: locationRoles,
      },
    ];

    if (this.abilities.can('use property admin-role')) {
      roleOptions.push({
        groupName: 'Property roles',
        options: zoneRoles,
      });
    }

    return roleOptions;
  }

  computeGroupsTask = task({ restartable: true }, async () => {
    this.groups = await this.store.findAll('group');
  });

  @action
  selectLocationsFilter(options) {
    // already selected ids
    let ids = this.selectedLocationIds.split(',').filter((el) => isPresent(el));

    // add an id of a clicked location if it's not already selected
    options.forEach((option) => {
      const optionId = get(option, 'id');
      ids = ids.includes(optionId) ? ids.filter((id) => id !== optionId) : [...ids, optionId];
    });

    this.selectedLocationIds = ids.join(',');

    this.args.updateLocationsFilter(ids);
  }

  @action
  updateRolesFilter(selectedRoles) {
    this.args.updateRolesFilter(
      selectedRoles.map((role) => {
        return {
          id: role.id,
          name: role.name,
          type: role.type,
        };
      }),
    );
  }
}
