import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { gt, readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { all, task } from 'ember-concurrency';
import type EmployeeModel from 'garaje/models/employee';
import type FallbackContactModel from 'garaje/models/fallback-contact';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface FallbackNotificationsComponentSignature {
  Args: {
    fallbackContacts: FallbackContactModel[];
    onUpdateFallbackContacts: (changes: { hasChanges: boolean }) => void;
    reloadFallbackContacts: () => Promise<void>;
  };
}

export default class FallbackNotificationsComponent extends Component<FallbackNotificationsComponentSignature> {
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare store: StoreService;

  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;
  @tracked fallbackEmployees!: EmployeeModel[];
  @tracked _fallbackEmployees!: EmployeeModel[];
  @tracked hasChangedFallbackContacts = false;

  @gt('_fallbackEmployees.length', 0) hasSavedFallbackEmployees!: boolean;
  @readOnly('currentLocation.location.fallbackNotificationsEnabled') isEnabled!: boolean;

  constructor(owner: unknown, args: FallbackNotificationsComponentSignature['Args']) {
    super(owner, args);
    this._mapContactsToEmployees();
  }

  // @ts-ignore
  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: false,
    },
    prefix: true,
  }).restartable())
  searchEmployeesTask!: Task<EmployeeModel[], [string]>;

  _mapContactsToEmployees(): void {
    // Even though we pass in fallbackContacts, every other operation
    // we need to do is simpler in terms of the employee
    const fallbackEmployees = this.args.fallbackContacts.map((fallbackContact) => fallbackContact.employee);
    this.fallbackEmployees = <EmployeeModel[]>(<unknown>fallbackEmployees);

    // '_fallbackEmployees' allows us to compare which fallback contacts are new (which
    // need to be saved) vs old (which need to be deleted) upon save
    this._fallbackEmployees = <EmployeeModel[]>(<unknown>fallbackEmployees);
  }

  searchEmployeesWithoutDupsTask = task(async (term: string) => {
    const employees = await this.searchEmployeesTask.perform(term);
    const currentEmployeeIds = this.fallbackEmployees?.map((employee) => employee.id);

    return employees.filter((employee) => {
      return !currentEmployeeIds?.includes(employee.id);
    });
  });

  enableFallbackNotifications = task(async () => {
    const currentLocation = this.currentLocation.location;
    // We only want to enable this feature if we
    // have contacts
    if (this.hasSavedFallbackEmployees) {
      currentLocation.fallbackNotificationsEnabled = true;
      await currentLocation.save();
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_NOTIFICATIONS_FALLBACK_NOTIFICATION['ENABLED']);
    }
  });

  disableFallbackNotifications = task(async () => {
    const currentLocation = this.currentLocation.location;
    currentLocation.fallbackNotificationsEnabled = false;
    await currentLocation.save();
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_NOTIFICATIONS_FALLBACK_NOTIFICATION['DISABLED']);
  });

  _saveEmployeeAsFallbackContact(employee: EmployeeModel): Promise<FallbackContactModel> {
    const store = this.store;
    const location = this.currentLocation.location;
    const fallbackContactRecord = store.createRecord('fallback-contact', {
      employee,
      location,
    });
    return fallbackContactRecord.save();
  }

  _deleteFallbackContactForEmployee(employee: EmployeeModel): Promise<FallbackContactModel> | undefined {
    const fallbackContact = this.store
      .peekAll('fallback-contact')
      .find((contact) => contact.belongsTo('employee').id() === employee.id);
    return fallbackContact?.destroyRecord();
  }

  _findNewFallbackContacts(oldContacts: EmployeeModel[], newContacts: EmployeeModel[]): EmployeeModel[] {
    return [...newContacts].filter((contact) => {
      return !oldContacts.includes(contact);
    });
  }

  _findRemovedFallbackContacts(oldContacts: EmployeeModel[], newContacts: EmployeeModel[]): EmployeeModel[] {
    return [...oldContacts].filter((contact) => {
      return !newContacts.includes(contact);
    });
  }

  syncFallbackContactsTask = task(async () => {
    const newFallbackContacts = [...this.fallbackEmployees];
    const oldFallbackContacts = [...this._fallbackEmployees];

    const newContactsToSave = this._findNewFallbackContacts(oldFallbackContacts, newFallbackContacts);
    const oldContactsToDelete = this._findRemovedFallbackContacts(oldFallbackContacts, newFallbackContacts);

    try {
      await all(newContactsToSave.map(this._saveEmployeeAsFallbackContact.bind(this)));
      await all(oldContactsToDelete.map(this._deleteFallbackContactForEmployee.bind(this)));

      this.hasChangedFallbackContacts = false;
      this._fallbackEmployees = this.fallbackEmployees;

      const contact = newContactsToSave.length > 1 ? 'contacts' : 'contact';
      this.flashMessages.showAndHideFlash('success', `Saved fallback ${contact}!`);

      if (!this.isEnabled) {
        await this.enableFallbackNotifications.perform();
      }
      this.args.onUpdateFallbackContacts({ hasChanges: false });
    } catch (error) {
      this.args.onUpdateFallbackContacts({ hasChanges: true });
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
    await this.args.reloadFallbackContacts();
  });

  @action
  async onContinue(): Promise<void> {
    if (this.hasChangedFallbackContacts) {
      await this.args.reloadFallbackContacts();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  async cancel(): Promise<void> {
    if (this.hasChangedFallbackContacts) {
      this.showUnsavedEditsWarning = true;
    } else {
      await this.onContinue();
    }
  }

  @action
  async enable(): Promise<void> {
    await this.enableFallbackNotifications.perform();
    this.isOpen = true;
  }

  @action
  edit(): void {
    this.isOpen = true;
  }

  @action
  async disable(): Promise<void> {
    await this.disableFallbackNotifications.perform();
    this.isOpen = false;
  }

  @action
  setFallbackContacts(newFallbackContacts: EmployeeModel[]): void {
    this.fallbackEmployees = newFallbackContacts;
    this.hasChangedFallbackContacts = true;
    this.args.onUpdateFallbackContacts({ hasChanges: true });
  }
}
