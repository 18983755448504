import Service, { service } from '@ember/service';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type { WorkplaceEventNames, VisitorsEventNames, SubscriptionEventNames } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';

import type FeatureFlagsService from './feature-flags';

export interface CohoEventRequestBody {
  user_id: string;
  event_name: string;
  product: string;
  timestamp: string;
  company_id: string;
  location_id: string;
}

export interface CohoRequiredParams {
  product: string;
}

const COHO_ENDPOINT = 'coho/proxy';

export default class CohoService extends Service {
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare featureFlags: FeatureFlagsService;

  sendEvent(
    eventName: WorkplaceEventNames | VisitorsEventNames | SubscriptionEventNames,
    data: CohoRequiredParams,
  ): void {
    if (this.featureFlags.isEnabled('growth_coho_service')) {
      try {
        const { currentUser, currentLocation, currentCompany, currentAdmin } = this.state;
        let userID = currentUser?.id;
        if (!userID) {
          userID = currentAdmin?.id;
        }

        const requestData: CohoEventRequestBody = {
          user_id: userID,
          event_name: eventName,
          product: data.product,
          timestamp: new Date().toISOString(),
          company_id: currentCompany?.id,
          location_id: currentLocation?.id,
        };

        const endpoint = urlBuilder.growth.getGrowthServiceUrl() + COHO_ENDPOINT;

        fetch(endpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ data: requestData }),
        })
          .then()
          .catch((e: Error) => {
            this.metrics.trackEvent('coho_api_error', { cohoEventName: eventName, error: e });
          });
      } catch (e) {
        this.metrics.trackEvent('coho_api_error', { cohoEventName: eventName, error: e });
      }
    }
  }
}
