import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { TaskInstance } from 'ember-concurrency';
import type EmployeeModel from 'garaje/models/employee';
import type StateService from 'garaje/services/state';
import { alias } from 'macro-decorators';

interface AssistantsDropdownSignature {
  Args: {
    allowClear: boolean;
    assistants: Array<EmployeeModel>;
    changeset: EmployeeModel;
    isAssistantsLoaded: boolean;
    isEmployeeDeleted: boolean;
    searchEmployees: (term: string) => TaskInstance<EmployeeModel[]>;
    updateAssistants: (assistants: Array<EmployeeModel>) => void;
  };
}

export default class AssistantsDropdown extends Component<AssistantsDropdownSignature> {
  @service declare state: StateService;
  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];

  get isDisabled(): boolean {
    if (this.args.isEmployeeDeleted) {
      return true;
    }

    return !this.state.features?.canAccessAssistants;
  }
}
