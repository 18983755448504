import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';

export default class GlobalPrinterAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare state: StateService;

  get canDelete(): boolean {
    if (!this._hasAccessToFeature) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_PRINTER_DELETE);
  }

  get canManage(): boolean {
    if (!this._hasAccessToFeature) return false;

    const permissions = [
      Permission.VISITORS_PRINTER_CREATE,
      Permission.VISITORS_PRINTER_UPDATE,
      Permission.VISITORS_PRINTER_DELETE,
    ];

    return this.authz.hasAnyPermissionAtCurrentCompany(permissions);
  }

  get canRead(): boolean {
    if (!this._hasAccessToFeature) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_PRINTER_READ);
  }

  get canUpdate(): boolean {
    if (!this._hasAccessToFeature) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_PRINTER_UPDATE);
  }

  get canVisit(): boolean {
    return this.canRead;
  }

  get canViewNavItem(): boolean {
    if (!this._hasAccessToFeature) return false;

    return this.authz.hasPermissionAtCurrentCompany(Permission.VISITORS_PRINTER_NAV);
  }

  get _hasAccessToFeature(): boolean {
    return !!this.state.features?.canAccessGlobalPrinters;
  }
}
