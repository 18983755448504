import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AnalyticsStrategicInsightsRoute extends Route {
  @service router;
  @service featureConfig;
  @service abilities;

  title = 'Strategic Insights · Analytics · Envoy';

  beforeModel() {
    if (this.abilities.cannot('visit strategic insights in analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }
}
