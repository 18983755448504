import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import MailerTemplateModel from 'garaje/models/mailer-template';
import type UiHookModel from 'garaje/models/ui-hook';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { routeEvent } from 'garaje/utils/decorators/route';
import type { PromiseArray, RecordArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type SettingsEmailTemplatesShowController from '../controller';
import type { SettingsEmailTemplatesShowRouteModel } from '../route';

const { TRIGGER_NAME_MAPPING } = MailerTemplateModel;

export default class SettingsEmailTemplatesShowCustomRouteParams extends Route {
  declare controller: SettingsEmailTemplatesShowController;
  controllerName = 'visitors.settings.email-templates.show';

  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare flashMessages: FlashMessagesService;

  get allowUIHooksInCustomContent(): boolean {
    return this.featureFlags.isEnabled('hooks-custom-content-in-email-template');
  }

  async model({ mailer_template_id }: { mailer_template_id: string }): Promise<SettingsEmailTemplatesShowRouteModel> {
    const { allowUIHooksInCustomContent } = this;
    const parentRouteModel = <SettingsEmailTemplatesShowRouteModel>(
      this.modelFor('visitors.settings.email-templates.show')
    );
    const mailerTemplate = await this.store.findRecord('mailer-template', mailer_template_id, {
      reload: true,
      include: 'mailer-sections,mailer-sections.mailer-blocks',
    });

    let uiHooks: PromiseArray<UiHookModel, RecordArray<UiHookModel>> | undefined;
    try {
      uiHooks = allowUIHooksInCustomContent
        ? this.store.query('ui-hook', {
            location: parentRouteModel.currentLocation.id,
            triggerName: TRIGGER_NAME_MAPPING[<keyof typeof TRIGGER_NAME_MAPPING>mailerTemplate.identifier],
          })
        : undefined;
    } catch (_error) {
      this.flashMessages.showAndHideFlash(
        'error',
        'Error loading UI hooks. Some portions of the site may not work as expected.',
      );
    }

    const mailerSections = await mailerTemplate.mailerSections;

    return hash({
      ...parentRouteModel,
      ...{
        mailerTemplate,
        mailerSections,
        uiHooks,
      },
    });
  }

  setupController(
    controller: SettingsEmailTemplatesShowController,
    model: SettingsEmailTemplatesShowRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    controller.transitionConfirmed = false;
    void controller.previewEmailTask.perform();
  }

  @action
  loading(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <SettingsEmailTemplatesShowController>this.controllerFor(this.routeName);
    controller.loadingTemplateRoute = true;
    void transition.promise.finally(function () {
      controller.loadingTemplateRoute = false;
      controller.tempSelectId = null;
    });
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controller.confirmTransition(transition);
  }
}
