import Controller from '@ember/controller';
import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

export default class AnalyticsStrategicInsightsController extends Controller {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;

  get iframeUrl(): Promise<string | undefined> {
    const companyId = this.state?.currentCompany?.id;
    const locationId = this.state?.currentLocation?.id;

    if (!companyId || !locationId) {
      return Promise.resolve(undefined);
    }

    return this.getSigmaIframeUrl(companyId, locationId);
  }

  async getSigmaIframeUrl(companyId: string, locationId: string): Promise<string | undefined> {
    try {
      return this.sigmaUrl.getLocationSigmaUrl(companyId, locationId, 'strategic_insights');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  }
}
