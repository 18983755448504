import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class WorkplaceRoute extends Route {
  @service state;
  @service router;

  beforeModel() {
    this.redirectOnExpiredTrial();
  }

  @routeEvent
  routeWillChange(transition) {
    if (transition.targetName.startsWith('workplace.')) {
      this.redirectOnExpiredTrial();
    }
  }

  redirectOnExpiredTrial() {
    const { workplaceSubscription } = this.state;

    if (workplaceSubscription?.cancelled && !this.state.features?.canAccessProtectLegacyFeatures) {
      this.router.transitionTo('workplace.trial-ended');
    }
  }
}
