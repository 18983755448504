import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type Invite from 'garaje/models/invite';
import { TrackedSet } from 'tracked-built-ins';

interface VisitorsApprovalsTableArgs {
  approveInviteTask: Task<void, [Invite]>;
  bulkApproveInvitesTask: Task<Invite[], [Invite[]]>;
  bulkDenyInvitesTask: Task<Invite[], [Invite[]]>;
  columns: string[];
  denyInviteTask: Task<void, [Invite]>;
  hasMoreData: boolean;
  invites: Invite[];
  sortBy: string;
  sortDirection: 'asc' | 'desc';

  changeSort(field: string, direction: string): unknown;
  loadMoreData(): void;
}
export default class VisitorsApprovalsTable extends Component<VisitorsApprovalsTableArgs> {
  @service declare abilities: AbilitiesService;

  @tracked selectedInvites: TrackedSet<Invite> = new TrackedSet();

  /**
   * It's possible for an invite to require approval, but the current user isn't able to approve.
   * For example, if the current user is a Location Admin and not named in the blocklist contacts,
   * they won't be able to review an invite that matched a blocklist rule.
   *
   * This getter filters the list of approval-required invites to the ones that are actionable by the
   * current user. It's used to determine whether a particular invite is reviewable, or if all reviewable
   * invites are selected, but not for determining which invites to display (which should be all, including
   * ones that aren't actionable).
   */
  get reviewableInvites(): TrackedSet<Invite> {
    const reviewableInvites = this.args.invites.filter((invite: Invite) => {
      return this.abilities.can('review entry-approval', {
        report: invite.approvalStatus?.failedReport,
        context: 'location',
      });
    });
    return new TrackedSet(reviewableInvites);
  }

  get showBulkReviewButtons(): boolean {
    return this.selectedInvites.size > 0;
  }

  get bulkReviewButtonsDisabled(): boolean {
    return !this.showBulkReviewButtons || this.bulkApproveInvitesTask.isRunning || this.bulkDenyInvitesTask.isRunning;
  }

  bulkApproveInvitesTask = dropTask(async (): Promise<void> => {
    const invitesToRemove = await this.args.bulkApproveInvitesTask.perform([...this.selectedInvites]);
    invitesToRemove.forEach((invite) => this.selectedInvites.delete(invite));
  });

  bulkDenyInvitesTask = dropTask(async (): Promise<void> => {
    const invitesToRemove = await this.args.bulkDenyInvitesTask.perform([...this.selectedInvites]);
    invitesToRemove.forEach((invite) => this.selectedInvites.delete(invite));
  });

  @action
  selectOrDeselectAllInvites(): void {
    if (this.selectedInvites.size < this.reviewableInvites.size) {
      // not all invites are selected; select all (from the ones the current user can review)
      this.selectedInvites = new TrackedSet(this.reviewableInvites);
    } else {
      this.selectedInvites.clear();
    }
  }

  @action
  selectOrDeselectInvite(invite: Invite): void {
    if (this.selectedInvites.has(invite)) {
      this.selectedInvites.delete(invite);
    } else {
      this.selectedInvites.add(invite);
    }
  }

  // local helpers
  @action
  isInviteSelected(invite: Invite): boolean {
    return this.selectedInvites.has(invite);
  }

  @action
  showColumn(columnName: string): boolean {
    return this.args.columns.includes(columnName);
  }
}
