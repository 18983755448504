import { set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import ObjectProxy from '@ember/object/proxy';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import userDatumValidations from 'garaje/validations/user-datum';

/**
 * UserDatum is a pseudo-model for client side manipulation of objects
 * Paired with the user-datum DS.Transform, the backend can send us id-less objects
 * A changeset is used to determine when the object dirty
 * Replacement for what was a Fragment
 */
export default class UserDatum {
  declare _field?: string;
  declare _value: string;
  declare isUserDatum: boolean;
  declare changeset: DetailedChangeset<
    ObjectProxy<object> & {
      field?: string;
      value: string;
    }
  >;

  constructor({ field, value }: { field?: string; value: string }) {
    this.isUserDatum = true;
    this._field = field;
    this._value = value;

    set(
      this,
      'changeset',
      Changeset(ObjectProxy.create({ field, value }), lookupValidator(userDatumValidations), userDatumValidations),
    );
  }

  @alias('changeset.isDirty') isDirty!: boolean;

  get field(): string | undefined {
    return this._field;
  }

  get value(): string {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.changeset.get('value');
  }

  set value(v: string) {
    set(this.changeset, 'value', v);
  }

  toJSON(): { field: string | undefined; value: string } {
    return { field: this.field, value: this.value };
  }
}
