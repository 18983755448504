import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

const NOTIFICATION_DELAY_OPTIONS = [
  { label: 'No delay', value: 0 },
  { label: '15 minutes', value: 15 },
  { label: '1 hour', value: 60 },
  { label: '1 day', value: 1440 },
];

const REMINDER_INTERVAL_OPTIONS = [
  { label: 'Off', value: 'off' },
  { label: 'Daily', value: 'daily' },
  { label: 'Every other day', value: 'every-other-day' },
  { label: 'Weekly', value: 'weekly' },
];

@adapter('deliveries')
class NotificationConfig extends Model {
  @belongsTo('location', { inverse: null }) location;
  @attr('boolean') canAccessSlack;
  @attr('boolean', { defaultValue: false }) emailNotificationsEnabled;
  @attr('number', { defaultValue: 0 }) notificationDelay;
  @attr('string', { defaultValue: 'off' }) notificationReminderInterval; // off, daily, every-other-day, weekly
  @attr('boolean', { defaultValue: false }) notificationsEnabled;
  @attr('boolean', { defaultValue: false }) slackNotificationsEnabled;

  static NOTIFICATION_DELAY_OPTIONS = NOTIFICATION_DELAY_OPTIONS;
  static REMINDER_INTERVAL_OPTIONS = REMINDER_INTERVAL_OPTIONS;
}

export default NotificationConfig;
