import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type { Task, TaskGroup } from 'ember-concurrency';
import { task, dropTaskGroup, dropTask } from 'ember-concurrency';
import type FallbackContactModel from 'garaje/models/fallback-contact';
import type LocationModel from 'garaje/models/location';
import type SubscriptionModel from 'garaje/models/subscription';
import type { PluginPreference } from 'garaje/pods/components/notification-preferences-loader/component';
import type CohoService from 'garaje/services/coho';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import {
  SLACK_V2_PLUGIN_KEY,
  MSTEAMS_V2_PLUGIN_KEY,
  IMPRESSION_NAMES,
  APP,
  VisitorsEventNames,
} from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { and, or, notEmpty } from 'macro-decorators';

interface HostOptionsComponentSignature {
  Args: {
    closeFeature: () => void;
    fallbackContacts: FallbackContactModel[];
    onUpdateFallbackContacts: (fallbackContacts: FallbackContactModel[]) => void;
    reloadFallbackContacts: () => void;
    feature: string;
    pluginPreferences: PluginPreference[];
    location: LocationModel;
    openFeature: () => void;
    vrSubscription: SubscriptionModel;
    searchUsersTask: Task<void, []>;
    locationIsConnectedToProperty: boolean;
  };
}

export default class HostOptionsComponent extends Component<HostOptionsComponentSignature> {
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare state: StateService;
  @service declare statsig: StatsigService;
  @service declare coho: CohoService;

  @notEmpty('slackPlugins') hasSlackEnabled!: boolean;
  @notEmpty('msTeamsPlugins') hasMSTeamsEnabled!: boolean;
  @and('hasMSTeamsEnabled', 'featureFlags.msTeamsHostReplies') msTeamsHostRepliesEnabled!: boolean;
  @and('featureFlags.twoWayHostMessaging', 'hasHostRepliesCompatiblePlugin') showHostRepliesSection!: boolean;
  @or('hasSlackEnabled', 'hasMSTeamsEnabled') hasHostRepliesCompatiblePlugin!: boolean;

  @dropTaskGroup toggleTasks!: TaskGroup<unknown>;

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;
  msTeamsV2PluginKey = MSTEAMS_V2_PLUGIN_KEY;

  get slackPlugins(): PluginPreference[] {
    return this.args.pluginPreferences?.filter(
      (pluginPreference) => pluginPreference.plugin.key === this.slackV2PluginKey,
    );
  }

  get msTeamsPlugins(): PluginPreference[] {
    return this.args.pluginPreferences?.filter(
      (pluginPreference) => pluginPreference.plugin.key === this.msTeamsV2PluginKey,
    );
  }

  get canShowAddEmployeesTooltip(): boolean {
    if (
      this.args?.location?.hostNotificationsEnabled &&
      this.state?._companyMeta?.['employees-count'] === 1 &&
      this.featureFlags.isEnabled('growth_employee_directory_empty_state')
    ) {
      return true;
    }
    return false;
  }

  saveLocation = dropTask(async (location: LocationModel) => {
    try {
      await location.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  toggleEmailNotificationEnabled = task({ group: 'toggleTasks' }, async (value: boolean) => {
    this.args.location.emailNotificationEnabled = value;
    await this.saveLocation.perform(this.args.location);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_EMAIL_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  toggleSMSNotificationEnabled = task({ group: 'toggleTasks' }, async (value: boolean) => {
    this.args.location.smsNotificationEnabled = value;
    await this.saveLocation.perform(this.args.location);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_SMS_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  toggleHostNotificationsEnabled = task({ group: 'toggleTasks' }, async (value: boolean) => {
    this.args.location.hostNotificationsEnabled = value;
    await this.saveLocation.perform(this.args.location);

    const eventName = value
      ? VisitorsEventNames.HostNotificationsEnabled
      : VisitorsEventNames.HostNotificationsDisabled;
    this.coho.sendEvent(eventName, { product: APP.VISITORS });
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_HOST_NOTIFICATIONS[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  toggleNotifyReceptionistsOnHostReplyEnabled = task({ group: 'toggleTasks' }, async (value: boolean) => {
    this.args.location.notifyReceptionistsOnHostReplyEnabled = value;
    await this.saveLocation.perform(this.args.location);
    await this.impressions.postImpression.perform(
      IMPRESSION_NAMES.HOST_OPTIONS_RECEPTIONIST_NOTIFICATION[value ? 'ENABLED' : 'DISABLED'],
    );
  });

  @action
  trackTooltipShown(): void {
    this.statsig.logEvent('employee-host-tooltip-seen');
  }

  @action
  trackTooltipLinkClick(): void {
    this.statsig.logEvent('employee-host-tooltip-directory-clicked');
  }
}
