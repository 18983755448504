import type NativeArray from '@ember/array/-private/native-array';
import type ArrayProxy from '@ember/array/proxy';
import { get } from '@ember/object';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany, SyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type { Identifier } from 'garaje/models/user-document-template';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import type AjaxService from 'garaje/services/ajax';
import type PlainObject from 'garaje/utils/plain-object';
import type { RecordArray } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import { not } from 'macro-decorators';

class RecurringInviteModel extends Model {
  @service() declare ajax: AjaxService;

  @belongsTo('user') declare creator: AsyncBelongsTo<UserModel>;
  @belongsTo('employee') declare employee?: AsyncBelongsTo<EmployeeModel> | EmployeeModel;
  @belongsTo('flow') declare flow: AsyncBelongsTo<FlowModel>;
  @belongsTo('location') declare location?: AsyncBelongsTo<LocationModel> | LocationModel;
  @belongsTo('recurringInvite') declare parentRecurringInvite:
    | AsyncBelongsTo<RecurringInviteModel>
    | RecurringInviteModel;
  @hasMany('visitor-document', { async: false }) declare visitorDocuments:
    | SyncHasMany<VisitorDocumentModel>
    | RecordArray<VisitorDocumentModel>
    | NativeArray<VisitorDocumentModel>;
  @hasMany('location', { inverse: null }) declare childLocations?:
    | AsyncHasMany<LocationModel>
    | LocationModel[]
    | ArrayProxy<LocationModel>
    | null;

  @attr('string') declare email: string;
  @attr('string') declare fullName: string;
  @attr('number', { defaultValue: 0 }) declare additionalGuests: number;
  @attr('string') declare privateNotes: string;
  @attr('string') declare propertyNotes: string;
  @attr('locality', { defaultValue: () => ({ placeId: '' }) }) declare locality: { placeId: string };
  @attr('string') declare recurringRule: string;
  @attr('boolean', { defaultValue: false }) declare skipGuestNotification: boolean;
  @attr('date') declare startTime: Date;
  @attr('array', { defaultValue: () => [] }) declare locationNames: string[];
  @attr('userData', { defaultValue: () => [] }) declare userData: unknown;

  // SQ-4574: Invert boolean in model to set same value in component
  @not('skipGuestNotification') sendGuestEmail!: boolean;

  notifyInvite(data: PlainObject): Promise<unknown> {
    return this.ajax.request(urlBuilder.v3.recurringInvites.sendNotification(this.id), {
      contentType: 'application/json',
      data,
      type: 'POST',
    });
  }

  visitorDocumentForTemplate(userDocumentTemplate: UserDocumentTemplateModel): VisitorDocumentModel | undefined {
    // eslint-disable-next-line ember/no-get
    return this.visitorDocumentForIdentifier(get(userDocumentTemplate, 'identifier'));
  }

  visitorDocumentForIdentifier(identifier: Identifier): VisitorDocumentModel | undefined {
    return this.visitorDocuments.findBy('identifier', identifier);
  }
}

export default RecurringInviteModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'recurring-invite': RecurringInviteModel;
  }
}
