import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Report } from 'garaje/models/approval-status';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

const CAN_VISIT_SECURITY = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_VISIT_SECURITY_SCREENING_AUTHZ_PERMISSIONS = [
  Permission.VISITORS_BLOCKLIST_READ,
  Permission.VISITORS_WATCHLIST_READ,
  Permission.VISITORS_FULL_LEGAL_NAME_SETTING_READ,
];

export type GetCanReviewOrReadBlocklistMatchModel = {
  context: 'location' | 'property';
  report?: Report[];
  didFailBlocklistCheck: boolean;
};

export default class SecurityAbility extends Ability {
  @service declare abilities: AbilitiesService;
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  declare model: GetCanReviewOrReadBlocklistMatchModel | undefined;

  get canReviewOrReadBlocklistMatch(): boolean {
    const { context, didFailBlocklistCheck, report } = this.model!;

    return (
      this.abilities.can('review entry-approval', { context, report }) ||
      (didFailBlocklistCheck && this.canReadBlocklist)
    );
  }

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    const hasPermission = this.authz.hasAnyPermissionAtCurrentLocation(CAN_VISIT_SECURITY_SCREENING_AUTHZ_PERMISSIONS);
    return isPresent(_intersection(CAN_VISIT_SECURITY, roleNames)) || hasPermission;
  }

  get canReadOtherSecuritySettings(): boolean {
    // TODO: ID scanning, visual compliance, building security, and wifi authz permissions
    const { roleNames } = this.currentAdmin;
    const hasCorrectRole = isPresent(_intersection(CAN_VISIT_SECURITY, roleNames));
    return hasCorrectRole;
  }

  get canReadFullLegalNameSetting(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_FULL_LEGAL_NAME_SETTING_READ);
  }

  get canUpdateFullLegalNameSetting(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_FULL_LEGAL_NAME_SETTING_UPDATE);
  }

  get canCreateWatchlist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_WATCHLIST_CREATE);
  }

  get canReadWatchlist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_WATCHLIST_READ);
  }

  get canUpdateWatchlist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_WATCHLIST_UPDATE);
  }

  get canCreateBlocklist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_BLOCKLIST_CREATE);
  }

  get canReadBlocklist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_BLOCKLIST_READ);
  }

  get canUpdateBlocklist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_BLOCKLIST_UPDATE);
  }

  get canDeleteBlocklist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_BLOCKLIST_DELETE);
  }

  get canUploadBlocklist(): boolean {
    return this.authz.hasPermissionAtCurrentLocation(Permission.VISITORS_BLOCKLIST_UPLOAD);
  }
}
