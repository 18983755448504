import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StateService from 'garaje/services/state';

export default class VisitorsStartTrialRoute extends Route {
  @service declare state: StateService;
  @service declare router: RouterService;

  titleToken = 'Visitors';

  redirect(): void {
    const { vrSubscription } = this.state;
    if (isPresent(vrSubscription)) {
      this.router.transitionTo('visitors.entries');
    }
  }
}
