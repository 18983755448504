import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldModel from 'garaje/models/abstract/abstract-sign-in-field';
import type SignInFieldActionModel from 'garaje/models/sign-in-field-action';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import adapter from 'garaje/utils/decorators/adapter';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';

@OnboardingChangesTracker({ taskName: 'loadFlowsTask', attributes: ['name'] })
@adapter('v3-coalesce')
class SignInFieldModel extends AbstractSignInFieldModel {
  declare value?: string | null; // used for initial value?

  @belongsTo('sign-in-field-page', { inverse: 'signInFields' })
  declare signInFieldPage: AsyncBelongsTo<SignInFieldPageModel>;
  @hasMany('sign-in-field') declare actionableSignInFields: AsyncHasMany<SignInFieldModel>;
  @hasMany('sign-in-field-action') declare signInFieldActions: AsyncHasMany<SignInFieldActionModel>;
  @hasMany('sign-in-field-action', { inverse: 'actionableSignInField' })
  declare actionableSignInFieldActions: AsyncHasMany<SignInFieldActionModel>;
}

export default SignInFieldModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in-field': SignInFieldModel;
  }
}
