import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { saveAs } from 'file-saver';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import moment from 'moment-timezone';

type EntryParamsFilter = {
  location: string;
  status: string;
  'start-date': string;
  'end-date': string;
};

type EntryParams = {
  include: string;
  filter: EntryParamsFilter;
  sort: string;
};

export default class ExportOnSiteComponent extends Component<{
  location: StateService['currentLocation'];
}> {
  @service declare metrics: MetricsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare store: Store;

  entryParams(_include = []): EntryParams {
    const include = ['platform-jobs', ..._include];
    const filter = {
      location: this.args.location.id,
      status: 'not-signed-out',
      'start-date': moment(Date.now()).startOf('day').format(),
      'end-date': moment(Date.now()).endOf('day').format(),
    };

    return {
      include: include.join(),
      filter,
      sort: 'signed-in-at',
    };
  }

  exportOnSiteToCsvTask = task({ drop: true }, async () => {
    const signedInEntries = await this.store.query('entry', this.entryParams());
    const headers = ['fullName', 'email', 'signedInAt'] as const;
    const filename = 'onsite-report-' + moment(Date.now()).format() + '.csv';
    const csvContent = [headers.join(',')].concat(
      signedInEntries.map((item) => headers.map((header) => item[header]).join(',')),
    );
    const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv;charset=utf-8' });
    saveAs(csvBlob, filename);
  });
}
