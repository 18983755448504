import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { type Task, task } from 'ember-concurrency';
import type { Desk as GqlDesk, GQLAssignment } from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import type {
  EmployeeItem,
  FeaturesOverview,
  EmployeesOverviewSidePanel,
  ScheduledEmployeesPanel,
  ScheduledAssignment,
} from 'garaje/graphql/generated/map-features-types';
import type AmenityModel from 'garaje/models/amenity';
import type AreaMapModel from 'garaje/models/area-map';
import type DeliveryAreaModel from 'garaje/models/delivery-area';
import type DeskModel from 'garaje/models/desk';
import type DraftModel from 'garaje/models/draft';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type RoomModel from 'garaje/models/room';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type MetricsService from 'garaje/services/metrics';
import type ResourceOverviewService from 'garaje/services/resource-overview';
import type StateService from 'garaje/services/state';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { formatTimestampAbbreviated } from 'garaje/utils/format-timestamp';
import { POLYGON_DRAWING_OPTIONS } from 'garaje/utils/polygon';
import {
  buildCustomEmployeeItem,
  getFlattenedEmployees,
  addFeatureToOverview,
  updateFeatureInOverview,
  deleteFeatureInOverview,
  expandSectionOnFeatureSelect,
} from 'garaje/utils/resource-overview';
import L from 'leaflet';
import { alias, filter, filterBy } from 'macro-decorators';
import { cached } from 'tracked-toolbox';

interface FullScreenMapArgs {
  desks: DeskModel[];
  desksInLocation: DeskModel[];
  draft: DraftModel;
  mapFeatures: MapFeatureModel[];
  employees: EmployeeModel[];
  rooms: RoomModel[];
  errorFeatures: MapFeatureModel[];
  deletableFeatures: MapFeatureModel[];
  amenities: AmenityModel[];
  areaMap: AreaMapModel;
  mapFloor: MapFloorModel;
  deliveryAreas: DeliveryAreaModel[];
  neighborhoods: NeighborhoodModel[];
  resourceInsights: unknown;

  selectedFeature: MapFeatureModel | null;
  setSelectedFeature: (feature: MapFeatureModel | null) => void;
  selectedNodeId: string | null;
  setSelectedNodeId: (nodeId: string | null) => void;
  selectedEmployeeEmail: string | null;
  setSelectedEmployeeEmail: (email: string | null) => void;
  setSelectedAssignmentId: (employeeId: string | null) => void;
  shouldShowEmployeeForm: boolean;
  setShouldShowEmployeeForm: (shouldShowEmployeeForm: boolean) => void;
  loadResoucesDataTask: Task<void, []>;
}

interface DirtyRelationshipsDeskModel extends DeskModel {
  hasDirtyRelationships: boolean;
}

type Resource = {
  type: string;
};

export default class FullScreenMap extends Component<FullScreenMapArgs> {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare resourceOverview: ResourceOverviewService;
  @service declare metrics: MetricsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagService;
  @service declare router: RouterService;
  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare impressions: ImpressionsService;

  @alias('state.currentLocation') declare currentLocation: LocationModel;

  @filter('args.desks', (desk: DirtyRelationshipsDeskModel) =>
    Boolean(desk.hasDirtyAttributes || desk.hasDirtyRelationships),
  )
  dirtyDesks = [];
  @filterBy('args.mapFeatures', 'hasDirtyAttributes') dirtyFeatures = [];
  @filterBy('args.desks', 'isDeleted', false) useableDesks = [] as DeskModel[];

  @tracked hasShownAutoGenerationTooltip = false;
  @tracked placingResource: Resource | null = null;
  @tracked selectedPanelView = '';
  EXPANDED_MAP_DEFAULTS = [
    'resources/desks',
    'resources/rooms',
    'resources/points-of-interest',
    'seating/assigned',
    'seating/unassigned',
    'upcoming/assignments',
  ];
  @tracked expandedMap = this.EXPANDED_MAP_DEFAULTS;
  @tracked leafletMap?: L.Map;
  @tracked drawingArea?: L.Draw.Polygon;
  @tracked employeesPage = 0;
  @tracked employeesCount = 0;
  @tracked limit = 25;

  @tracked gqlMapFeatures: FeaturesOverview = {
    desks: {
      'assigned-desks': [],
      'hotel-desks': [],
      'disabled-desks': [],
    },
    rooms: {
      'enabled-rooms': [],
      'disabled-rooms': [],
    },
    'points-of-interest': {},
  };
  @tracked gqlEmployees: {
    unassigned: EmployeeItem[] | Record<string, EmployeeItem[]>;
    assigned: EmployeeItem[] | Record<string, EmployeeItem[]>;
  } = {
    unassigned: [],
    assigned: [],
  };
  @tracked gqlAssignments: ScheduledEmployeesPanel = {
    assignments: [],
  };

  @action
  registerEventListeners(): void {
    window.addEventListener('keydown', (e) => this.keyDownHandler(e));
  }

  keyDownHandler(event: KeyboardEvent): void {
    if ((event.key === 'Meta' || event.key === 'Control') && this.placingResource?.type === 'desk') {
      this.hasShownAutoGenerationTooltip = true;
    }
  }

  fetchImpressionsTask = task({ drop: true }, async () => {
    const autoGenImpressions = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.MAP.DESK_AUTO_GENERATION_TOOLTIP_DISMISSED,
    );
    this.hasShownAutoGenerationTooltip = autoGenImpressions!.length > 0;
  });

  @action
  setPlacingResource(resource: Resource): void {
    if (resource?.type === 'desk') {
      void this.impressions.postImpression.perform(IMPRESSION_NAMES.MAP.DESK_AUTO_GENERATION_TOOLTIP_DISMISSED);
    }
    this.placingResource = resource;
  }

  @action
  setExpandedMap(path: string): void {
    const selectedView = path.split('/')[0];
    if (this.expandedMap.includes(path)) {
      // Remove the path from the expandedMap. If item is "foo/bar/bif" a path of "foo" will remove the entire item.
      this.expandedMap = this.expandedMap.filter((item) => !item.includes(path));
      this.metrics.trackEvent('Collapsed section', { panel: selectedView, section: path });
    } else {
      this.expandedMap = [...this.expandedMap, path];
      this.metrics.trackEvent('Expanded section', { panel: selectedView, section: path });
    }
  }

  get viewOnly(): boolean {
    return this.abilities.cannot('edit maps') && this.abilities.cannot('edit neighborhoods map');
  }

  get isDrawing(): boolean {
    return this.placingResource?.type === 'room';
  }

  @cached
  get unplacedDesks(): DeskModel[] {
    const { mapFeatures, deletableFeatures } = this.args;
    return this.useableDesks.filter(
      (desk) =>
        !desk.isNew &&
        !mapFeatures.some(
          (feature: MapFeatureModel) => feature.externalId === desk.id && !deletableFeatures.includes(feature),
        ),
    );
  }

  get warnings(): object {
    return {
      numberOfUnplacedDesks: this.unplacedDesks.length,
    };
  }

  get resourceOptions(): object {
    return {
      room: this.roomOptions,
      deliveryArea: this.deliveryAreaOptions,
      visitorArea: this.visitorAreaOptions,
      desk: this.deskOptions,
      pointOfInterest: this.poiOptions,
    };
  }

  get poiOptions(): object {
    const cannotCreatePOI = this.abilities.cannot('manage-point-of-interest desks');

    return {
      disabled: cannotCreatePOI,
      tooltip: cannotCreatePOI ? 'You need permissions to manage POIs.' : 'POI',
    };
  }

  get visitorAreaOptions(): object {
    let tooltip = 'Visitor area';

    if (!this.state.visitorsSubscription) {
      tooltip = 'Visitors subscription required to add visitor areas to your floor plan.';
    }
    return {
      disabled: !this.state.visitorsSubscription,
      tooltip,
    };
  }

  get deskOptions(): object {
    let tooltip = 'Desks';

    const cannotCreateDesk = this.abilities.cannot('manage-desk desks');

    if (!this.featureConfig.isEnabled('desks')) {
      tooltip = 'Workplace subscription required to add desks to your floor plan.';
    } else if (cannotCreateDesk) {
      tooltip = 'You need permissions to manage desks.';
    }

    return {
      disabled: !this.featureConfig.isEnabled('desks') || cannotCreateDesk,
      tooltip,
    };
  }

  get deliveryAreaOptions(): object {
    let tooltip = 'Delivery area';

    if (!this.state.features?.canAccessDeliveriesApplication) {
      tooltip = 'Deliveries subscription required to add delivery areas to your floor plan.';
    } else if (isEmpty(this.availableDeliveryAreas)) {
      tooltip = 'You have placed all of the delivery areas in your account.';
    }

    return {
      disabled: isEmpty(this.availableDeliveryAreas) || !this.state.features?.canAccessDeliveriesApplication,
      tooltip,
    };
  }

  get roomOptions(): object {
    let tooltip = 'Rooms';

    const roomsEnabled = this.featureConfig.isEnabled('rooms');

    if (!roomsEnabled) {
      tooltip = 'Workplace subscription required to add rooms to your floor plan.';
    } else if (isEmpty(this.availableRooms)) {
      tooltip = 'You have placed all of the rooms in your account.';
    }

    return {
      disabled: !roomsEnabled || isEmpty(this.availableRooms),
      tooltip,
    };
  }

  get availableResources(): object {
    return {
      rooms: this.availableRooms,
      deliveryAreas: this.availableDeliveryAreas,
      unplacedDesks: this.unplacedDesks,
    };
  }

  _placedResourcesIds(type: string): string[] {
    return this.args.mapFeatures
      .filter((feature: MapFeatureModel) => feature.type === type && feature.externalId)
      .map((f) => f.externalId);
  }

  get availableRooms(): RoomModel[] {
    const placedRoomIds = this._placedResourcesIds('room');
    return this.args.rooms.filter(({ id }) => !placedRoomIds.includes(id));
  }

  get availableDeliveryAreas(): DeliveryAreaModel[] {
    const placedDeliveryAreasIds = this._placedResourcesIds('delivery-area');
    return this.args.deliveryAreas.filter(({ id }) => !placedDeliveryAreasIds.includes(id));
  }

  @action
  drawArea(resource: Resource): void {
    if (this.isDrawing) return;
    this.placingResource = resource;
    this.drawingArea = new L.Draw.Polygon(this.leafletMap, POLYGON_DRAWING_OPTIONS);
    this.drawingArea?.enable();
    this.leafletMap?.dragging.disable();
  }

  @action
  placeResource(resource: Resource): void {
    if (this.isDrawing && this.drawingArea) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.drawingArea?.disable();
      this.leafletMap?.dragging.enable();
    }

    this.placingResource = resource;
  }

  @action
  setEmployeeFormVisibility(shouldShowEmployeeForm: boolean): void {
    const { setSelectedEmployeeEmail, setShouldShowEmployeeForm } = this.args;

    if (!shouldShowEmployeeForm) {
      setSelectedEmployeeEmail(null);
    }
    setShouldShowEmployeeForm(shouldShowEmployeeForm);
  }

  @action
  onSelectedFeatureChange(feature: MapFeatureModel): void {
    const { setSelectedFeature, setSelectedNodeId, setSelectedEmployeeEmail } = this.args;
    setSelectedFeature(feature);
    setSelectedNodeId(feature?.id ?? feature?.tempId);
    if (feature) {
      let desk = null;
      if (feature.type === 'desk' && feature.externalId) {
        desk = this.store.peekRecord('desk', feature.externalId);
        if (desk && desk.assignedTo) {
          setSelectedEmployeeEmail(desk.assignedTo);
        } else {
          setSelectedEmployeeEmail(null);
        }
      } else {
        setSelectedEmployeeEmail(null);
      }

      const expandedPath = expandSectionOnFeatureSelect(
        feature,
        desk as DeskModel,
        this.selectedPanelView,
        this.flattenedAssignedEmployees,
      );
      if (!this.expandedMap.includes(expandedPath)) {
        this.setExpandedMap(expandedPath);
      }
    } else {
      setSelectedEmployeeEmail(null);
    }
  }

  @action
  onSelectedAssignmentChange(assignment: ScheduledAssignment): void {
    const { setSelectedAssignmentId, setSelectedFeature } = this.args;
    setSelectedAssignmentId(assignment.id);
    this.setEmployeeFormVisibility(true);

    if (!assignment.deskId) {
      return;
    }

    const feature = this.store.peekAll('map-feature').find((it) => {
      return it.externalId === assignment.deskId && it.type === 'desk';
    });
    if (feature) {
      setSelectedFeature(feature);
    }
  }

  @action
  onSelectedEmployeeChange(employee: EmployeeItem): void {
    const { setSelectedFeature, setSelectedEmployeeEmail } = this.args;
    if (employee) {
      setSelectedEmployeeEmail(employee.email);
      this.setEmployeeFormVisibility(true);
    }
    setSelectedFeature(null);
  }

  get flattenedUnassignedEmployees(): EmployeeItem[] {
    return getFlattenedEmployees(this.gqlEmployees.unassigned);
  }

  get flattenedAssignedEmployees(): EmployeeItem[] {
    return getFlattenedEmployees(this.gqlEmployees.assigned);
  }

  get unassignedEmployeeInsightCount(): number {
    return this.flattenedUnassignedEmployees.filter((it) => {
      return it.attendancePerformance?.insights.meetsAttendancePolicy === true;
    }).length;
  }

  get assignedEmployeeInsightCount(): number {
    return this.flattenedAssignedEmployees.filter((it) => {
      return it.attendancePerformance?.insights.meetsAttendancePolicy == false;
    }).length;
  }

  get insightsCount(): number {
    return this.assignedEmployeeInsightCount + this.unassignedEmployeeInsightCount;
  }

  @action
  deleteAssignment(assignmentDeskId: string, assignmentEmployeeId: string): void {
    // update the Upcoming panel
    const gqlAssignments = this.gqlAssignments.assignments.filter((it) => {
      return it.deskId != assignmentDeskId || it.employeeId != assignmentEmployeeId;
    });
    this.gqlAssignments = {
      assignments: gqlAssignments,
    };
    // remove assignments from the store
    const assignmentsInStore = this.store.peekAll('assignment').filter((it) => {
      return it.belongsTo('desk').id() == assignmentDeskId && it.belongsTo('employee').id() == assignmentEmployeeId;
    });
    assignmentsInStore.forEach((assignment) => {
      this.store.unloadRecord(assignment);
    });

    // update desk
    const deskWithAssignment = this.store.peekRecord('desk', assignmentDeskId);
    if (deskWithAssignment) {
      // @ts-ignore
      deskWithAssignment.assignments = [];
      deskWithAssignment.assignmentDetails = [];
    }

    // update employee
    const employee = this.store.peekRecord('employee', assignmentEmployeeId);
    if (employee) {
      this.updateCurrentPanelWithAssignments(employee, gqlAssignments);
    }
  }

  @action
  updateAssignmentsPanel(employeeId: string, assignments: ScheduledAssignment[]): void {
    const employee = this.store.peekRecord('employee', employeeId);
    if (!employee) return;

    // update the Upcoming Assignments panel
    const existingGqlAssignments = this.gqlAssignments.assignments.filter((it) => {
      return parseInt(it.employeeId) !== parseInt(employeeId);
    });
    const newGqlAssignments = assignments.map((it) => {
      return {
        id: it.id,
        name: employee.name,
        start: it.start,
        employeeId: employeeId,
        deskId: it.deskId,
        display: employee.name,
        isLastExpanded: true,
        meta: [formatTimestampAbbreviated(it.start)],
      };
    });
    existingGqlAssignments.push(...newGqlAssignments);
    this.gqlAssignments = {
      assignments: existingGqlAssignments,
    };
    this.updateCurrentPanelWithAssignments(employee, existingGqlAssignments);
  }

  updateCurrentPanelWithAssignments(employee: EmployeeModel, gqlAssignments: ScheduledAssignment[]): void {
    const gqlEmployees = [...this.flattenedAssignedEmployees, ...this.flattenedUnassignedEmployees];
    const gqlEmployee = gqlEmployees.find((it) => it.id === parseInt(employee.id));
    const updatedEmployee = buildCustomEmployeeItem(
      employee.id,
      employee.name,
      employee.email,
      employee.department,
      gqlAssignments.filter((it) => parseInt(it.employeeId) === parseInt(employee.id)),
      gqlEmployee?.assignedDesks ?? [],
      gqlEmployee?.attendancePerformance ?? null,
    );
    this.updateEmployeeOverviewPanel(updatedEmployee);
  }

  updateEmployeeOverviewPanel(employee: EmployeeItem): void {
    const gqlEmployees = [...this.flattenedAssignedEmployees, ...this.flattenedUnassignedEmployees];
    const employeesToReturn = getFlattenedEmployees(
      gqlEmployees.filter((it) => it.id !== employee.id).concat(employee),
    );

    const newAssignedEmployees = employeesToReturn.filter((employee) => employee.assignedDesks.length > 0);
    const newUnassignedEmployees = employeesToReturn.filter((employee) => employee.assignedDesks.length === 0);

    if (newAssignedEmployees.filter((it) => it.email === employee.email).length >= 1) {
      this.args.setSelectedEmployeeEmail(employee.email);
    }
    this.gqlEmployees = {
      unassigned: this.resourceOverview.applyDepartmentGroupings(newUnassignedEmployees),
      assigned: this.resourceOverview.applyDepartmentGroupings(newAssignedEmployees),
    };
  }

  @action
  updateEmployeeOverview(employeeId: string, assignedDesks: GqlDesk[]): void {
    // look up the employee that we are doing to update
    const employee = this.store.peekRecord('employee', employeeId);
    if (!employee) return;
    const newAssignedDesks = assignedDesks ?? [];
    const gqlEmployees = [...this.flattenedAssignedEmployees, ...this.flattenedUnassignedEmployees];

    // since the attendance performance of the employee is not available in the ember store, we fetch it from
    // the GQL employee data to include in the subsequent employeeToChange variable
    const matchingGQLEmployee = gqlEmployees.find((it) => it.id === parseInt(employeeId));
    let employeeAttendancePerformance = null;

    if (matchingGQLEmployee) {
      employeeAttendancePerformance = matchingGQLEmployee.attendancePerformance;
      if (matchingGQLEmployee.department) {
        // this section moves employees between the assigned and unassigned sections based on their desk assignments
        const employeeSection = newAssignedDesks.length > 0 ? 'assigned' : 'unassigned';
        if (!this.expandedMap.includes(`seating/${employeeSection}/${matchingGQLEmployee.department}`)) {
          this.setExpandedMap(`seating/${employeeSection}/${matchingGQLEmployee.department}`);
        }
      }
    }
    const employeeToChange = buildCustomEmployeeItem(
      employee.id,
      employee.name,
      employee.email,
      employee.department,
      matchingGQLEmployee?.assignments ?? [],
      newAssignedDesks,
      employeeAttendancePerformance,
    );
    this.updateEmployeeOverviewPanel(employeeToChange);
  }

  @action
  updateResourceOverview(op: string, feature: MapFeatureModel): void {
    const modifiedFeature = {
      featureId: feature.id ?? feature.tempId,
      id: feature.externalId,
      name: feature.name,
      display: feature.name,
      isLastExpanded: true,
    };

    if (op === 'create') {
      addFeatureToOverview(this.gqlMapFeatures, feature, modifiedFeature);
    }

    if (op === 'update') {
      let desk: DeskModel | null = null;
      if (feature.externalId) {
        desk = this.store.peekRecord('desk', feature.externalId);
      } else {
        desk = feature.desk as DeskModel;
      }
      updateFeatureInOverview(this.gqlMapFeatures, feature, modifiedFeature, desk as DeskModel);
    }

    if (op === 'delete') {
      deleteFeatureInOverview(this.gqlMapFeatures, feature, modifiedFeature);
    }
  }

  fetchResourceOverviewDataTask = task({ drop: true }, async () => {
    const { areaMap, mapFloor, draft } = this.args;
    const gqlMapFeaturesAndEmployees = await this.resourceOverview.getAllGQLDataTask.perform(
      areaMap,
      mapFloor,
      draft?.id,
    );
    this.gqlMapFeatures = gqlMapFeaturesAndEmployees[0] as FeaturesOverview;
    const employeeData = gqlMapFeaturesAndEmployees[1] as EmployeesOverviewSidePanel;
    const assignmentEmployeeIds = (gqlMapFeaturesAndEmployees[2] as GQLAssignment[])
      .map((assignment) => {
        return assignment.employee?.id?.toString();
      })
      .filter((it) => it !== null && it !== '0' && it !== undefined);
    const loadedEmployeeIds = this.store.peekAll('employee').map((it) => it.id);
    const employeesToLoad = assignmentEmployeeIds.filter((id) => !loadedEmployeeIds.includes(id));
    if (employeesToLoad.length > 0) {
      await this.store.query('employee', {
        filter: {
          id: employeesToLoad.join(','),
          deleted: false,
        },
        include: 'user',
      });
    }

    const assignments = (gqlMapFeaturesAndEmployees[2] as GQLAssignment[])
      .map((assignment) => {
        // TODO: This means there is a pending unassignment - how do we want to display that in the Scheduled Assignments panel?
        // At the moment, we only display employees who are ~ getting ~ a desk and not those who are scheduled to lose one
        if (!assignment.employee) {
          return null;
        }
        // dig the name out of the Employees query response, possibly convert to a map for better performance
        const employeeName = this.store.peekRecord('employee', assignment.employee.id)?.name;
        if (!employeeName) {
          return null;
        }
        return {
          id: assignment.id,
          name: employeeName,
          start: assignment.startTime,
          employeeId: assignment.employee.id,
          deskId: assignment.deskId,
          display: employeeName,
          isLastExpanded: true,
          meta: [formatTimestampAbbreviated(assignment.startTime)],
        };
      })
      .filter((it) => it !== null);

    this.gqlAssignments = {
      assignments: assignments.filter((it) => it !== null),
    } as ScheduledEmployeesPanel;

    this.gqlEmployees = {
      unassigned: employeeData?.unassigned,
      assigned: employeeData?.assigned,
    };
  });
}
