import { A } from '@ember/array';
import type ArrayProxy from '@ember/array/proxy';
import { get, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { SyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AgreeableNdaModel from 'garaje/models/agreeable-nda';
import type ConfigModel from 'garaje/models/config';
import type EntryModel from 'garaje/models/entry';
import type EventReportModel from 'garaje/models/event-report';
import type LocationModel from 'garaje/models/location';
import type PlatformJobModel from 'garaje/models/platform-job';
import type PrinterModel from 'garaje/models/printer';
import type TenantModel from 'garaje/models/tenant';
import type UserModel from 'garaje/models/user';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlowService from 'garaje/services/flow';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import { routeEvent } from 'garaje/utils/decorators/route';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { PromiseArray, RecordArray } from 'garaje/utils/type-utils';
import moment from 'moment-timezone';
import { hash } from 'rsvp';
import type ServerError from 'utils/server-error';

import type VisitorsEntryController from './controller';

interface VisitorsEntryParams {
  entry_id: string;
}

export interface VisitorsEntryModel {
  agreeableNdas: SyncHasMany<AgreeableNdaModel>;
  entry: EntryModel;
  config: ConfigModel;
  connectedTenants: RecordArray<TenantModel>;
  eventReports: Array<EventReportModel | PlatformJobModel>;
  blocklistContacts: ArrayProxy<UserModel>;
  idScanContacts: ArrayProxy<UserModel>;
  printers: PrinterModel[] | RecordArray<PrinterModel>;
  vrSubscription: StateService['vrSubscription'];
}

class VisitorsEntryRoute extends Route {
  declare controller: VisitorsEntryController;

  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare flow: FlowService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: StoreService;

  beforeModel(): void {
    const { vrSubscription } = this.state;
    if (!(isPresent(vrSubscription) && this.abilities.can('visit dashboard'))) {
      if (this.abilities.can('visit dashboard')) {
        this.router.transitionTo('visitors.start-trial');
      } else if (this.abilities.can('visit billing')) {
        this.router.transitionTo('billing');
      } else if (this.abilities.can('visit security-desk')) {
        this.router.transitionTo('security-desk');
      } else {
        this.router.transitionTo('unauthorized');
      }
    }

    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model(params: VisitorsEntryParams): Promise<VisitorsEntryModel> {
    let printers: PromiseArray<PrinterModel, RecordArray<PrinterModel>> | PrinterModel[] = [];
    const { vrSubscription, currentLocation } = this.state;
    const { blocklistContacts, idScanContacts, config } = currentLocation;

    if (this.abilities.can('visit printers') && vrSubscription?.canEnableBadgePrinting) {
      printers = this.store.query('printer', {
        filter: { location: currentLocation.id, enabled: true },
        sort: 'status',
      });
    }

    const connectedTenants = await currentLocation.getPropertyConnections();
    const include = [];

    if (connectedTenants.length) include.push('invite-multi-tenancy-visitor-notification-logs');

    if (this.state.features?.canAccessMultipleHosts) {
      include.push('employee', 'additional-hosts');
    }

    const entry = await currentLocation.findEntry(params.entry_id, include).catch((err) => {
      if ((<ServerError>err).errors && /404/.test((<ServerError>err).errors.toString())) {
        this.router.transitionTo('visitors.entries');
      } else {
        throw err;
      }
    });

    const { entryLocation } = await hash({ entryLocation: entry!.location, entryEmployee: entry!.employee });

    if (entryLocation) {
      this.maybeSwitchCurrentLocation(currentLocation, entryLocation);
    }

    if (entry!.currentFlow) {
      // eslint-disable-next-line ember/no-get
      const hasAnyUserDocumentTemplateConfigurations = get(
        entry,
        'currentFlow.activeUserDocumentTemplateConfigurations.length',
      );

      // eslint-disable-next-line ember/no-get
      const signInFieldPage = await get(entry!.currentFlow, 'signInFieldPage');
      await signInFieldPage.signInFields;

      // eslint-disable-next-line ember/no-get
      if (get(entry!.currentFlow, 'isProtect')) {
        // eslint-disable-next-line ember/no-get
        await this.flow.getFlowWithIncludes(get(entry!.currentFlow, 'id'));
      } else if (hasAnyUserDocumentTemplateConfigurations) {
        // Fetch associated Visitor Documents only if:
        //   1. There are any relevant UserDocumentTemplateConfigurations
        //   2. The Visitor Document feature flag is enabled
        try {
          entry!.visitorDocuments = await this.store.query('visitor-document', {
            include:
              'user-document-template,user-document-attachments,user-document-attachments.user-document-template-attachment',
            filter: { 'entry-id': entry!.id },
          });
        } catch (_) {
          // UserDocumentTemplateConfigurations will load for everyone until
          // [BE] Jira task https://envoycom.atlassian.net/browse/VIS-4390 is completed.
          // For now, don't bomb out if the visitor-document request fails.
        }
      }
    }

    return hash({
      agreeableNdas: entry!.agreeableNdas,
      entry: entry!,
      config,
      connectedTenants,
      eventReports: [],
      blocklistContacts,
      idScanContacts,
      printers,
      vrSubscription,
    });
  }

  setupController(controller: VisitorsEntryController, model: VisitorsEntryModel, transition: Transition): void {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      model: model.entry,
      connectedTenants: model.connectedTenants,
      config: model.config,
      agreeableNdas: model.agreeableNdas,
      agreements: model.agreeableNdas.map((agreeableNda) => agreeableNda.agreement),
      printers: model.printers,
      vrSubscription: model.vrSubscription,
      eventReports: model.eventReports,
      blocklistContacts: model.blocklistContacts,
      idScanContacts: model.idScanContacts,
    });

    controller._buildUserDataChangeset();
    controller._buildStaticFields();
    void controller.loadPreviousEntries.perform();
    void controller.pollEventReports.cancelAll();
    void controller.pollEventReports.perform();
  }

  resetController(controller: VisitorsEntryController): void {
    controller.previousEntries = A([]);
    void controller.pollEventReports.cancelAll();
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);
    // eslint-disable-next-line ember/no-controller-access-in-routes
    void this.controller.pollEventReports.cancelAll();
  }

  titleToken(): string {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    return `${this.controller.model.fullName} · ${moment(this.controller.model.signInTime).format('MM-DD-YYYY')}`;
  }

  maybeSwitchCurrentLocation(currentLocation: LocationModel, entryLocation: LocationModel): void {
    if (currentLocation.id !== entryLocation.id) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const protectedController = this.controllerFor('protected');
      protectedController.send('switchLocation', entryLocation.id);
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): boolean {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const hasUnsavedChanges = this.controller.hasChanges;

    if (hasUnsavedChanges && !window.confirm('You will lose any unsaved changes. Are you sure you want to continue?')) {
      transition.abort();

      return false;
    }

    // eslint-disable-next-line ember/no-controller-access-in-routes
    this.controller.rollbackChanges();
    return true;
  }
}

export default VisitorsEntryRoute;
