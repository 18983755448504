import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import type { CollectionResponse } from 'jsonapi/response';

const DEBOUNCE_MS = 250;
const RESULTS_COUNT = 5;

interface LocalityAutocompleteComponentSignature {
  Args: {
    inputId?: number | string;
    isDisabled?: boolean;
    isReadonly?: boolean;
    name?: string;
    placeId?: number | string;
    type?: string;
    updateName?: (value: string) => void;
    updatePlaceId?: (value: number | string) => void;
  };
}

interface PlaceOption {
  id: number | string;
  name: string;
}

interface PlaceSuggestion {
  id: number | string;
  description: string;
  'matched-substrings': { length: number; offset: number }[];
}

export default class LocalityAutocompleteComponent extends Component<LocalityAutocompleteComponentSignature> {
  @service declare ajaxFetch: AjaxFetchService;

  // Locality data may be incomplete if created through, for example, a Group Invite
  // Allow the persisted value to display
  @tracked hasUserInput = false;

  get selection(): PlaceOption | null {
    const {
      hasUserInput,
      args: { placeId = '', name = '' },
    } = this;

    return (placeId && name) || !hasUserInput ? { id: placeId, name } : null;
  }

  isValid(userInput: string, selection: string): boolean {
    const isBlank = !userInput;
    const isSelection = userInput === selection;

    return isBlank || isSelection;
  }

  @action
  didType(userInput: string): void {
    const { updateName, updatePlaceId } = this.args;

    if (this.selection && this.selection.name !== userInput) {
      this.hasUserInput = true;
      updateName?.('');
      updatePlaceId?.('');
    }

    void this.autocompleteTask.perform(userInput);
  }

  @action
  didSelect(option: PlaceOption): void {
    const { updateName, updatePlaceId } = this.args;

    if (option && option.name && option.id) {
      updateName?.(option.name);
      updatePlaceId?.(option.id);
    }
  }

  autocompleteTask = restartableTask(async (term: string): Promise<PlaceOption[]> => {
    if (!term) return [];

    await timeout(zft(DEBOUNCE_MS));

    const url = urlBuilder.v3.placesAutocomplete(term, (this.args.type ?? '').split(','));

    try {
      const { data } = await this.ajaxFetch.request<CollectionResponse<PlaceSuggestion>>(url);
      const formattedResults = data
        .slice(0, RESULTS_COUNT)
        .map((place) => ({ id: place.id, name: place.attributes.description }));

      return formattedResults;
    } catch (_) {
      return [];
    }
  });
}
